import { Route, Switch } from "react-router-dom";
import AdminAddTeam from "./Add";
import AdminAddTeamMembers from "./AddMembers";

export default function AdminTeamRoutes() {
  return (
    <Switch>
      <Route exact path="/admin/team/add" component={AdminAddTeam} />
      <Route
        exact
        path="/admin/team/add-members"
        component={AdminAddTeamMembers}
      />
    </Switch>
  );
}
