import React, { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";


const Collapsible = ({ open, children, title }) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <div className="card" >
        <div>
          <div className="p-3 flex justify-between cursor-pointer my-1" style={{
            backgroundColor: "#E9F3FD",
            // color: "#2288EE",
            borderRadius: "10px",
          }} onClick={handleFilterOpening}>
            <>
              <h4 className="font-weight-bold" >{title}</h4>
            </>
            <div className="p-2">
              {!isOpen ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </div>

          </div>
        </div>

        <div className="border-bottom">
          <div>{isOpen && <div className="p-3">{children}</div>}</div>
        </div>
      </div>
    </>
  );
};

export default Collapsible;
