import React, { useEffect, useState } from "react";
import { API } from "../../api";
import feedback_star from "../../assests/icons/feedback_star.svg";
import feedback_star_fill from "../../assests/icons/feedback_star_fill.svg";
import LinearButton from "../../components/Button";
import LinearTextArea from "../../components/TextArea";

const Feedback = (props) => {
  const [feedback, setFeedback] = useState("");
  const [stars, setStars] = useState({
    overall: [0, 0, 0, 0, 0],
    experience: [0, 0, 0, 0, 0],
    relevance: [0, 0, 0, 0, 0],
    quality_learn: [0, 0, 0, 0, 0],
    quality_do: [0, 0, 0, 0, 0],
  });
  const [rating, setRating] = useState({
    overall: 0,
    experience: 0,
    relevance: 0,
    quality_learn: 0,
    quality_do: 0,
  });
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFeedback(e.target.value);
    setMessage("");
  };

  const handleSubmit = async () => {
    const { data, status } = await API.post("feedback/new", {
      feedback_text: feedback,
      rating,
    });
    if (status === 200) {
      setMessage("Thanks for your feedback");
      setSubmitted(true);
    } else {
      setMessage("An error occured. Please try again.");
    }
  };

  // improve this function
  useEffect(() => {
    let temp = {};
    for (const parameter of Object.keys(rating)) {
      temp[parameter] = Array(rating[parameter])
        .fill(1)
        .concat(Array(5 - rating[parameter]).fill(0));
    }
    setStars(temp);
  }, [rating]);

  return (
    <div>
      <div className="flex flex-row mx-4 items-center gap-4">
        {/* <h5>Your feedback is much appreciated. Please write to us.</h5> */}
        <div className="flex flex-col items-start gap-4">
          <div className="flex flex-row gap-2 items-center">
            Overall rating:
          </div>
          <div className="flex align-start gap-2 items-center">
            <div>Ease of experience:</div>
            <div className="flex flex-row gap-2 "></div>
          </div>
          <div className="flex flex-row gap-2 items-center">
            Relevance of programs:
          </div>
          <div className="flex flex-row gap-2 items-center">
            Learn activities:
          </div>
          <div className="flex flex-row gap-2 items-center">Do activities:</div>
        </div>
        <div className="flex flex-col gap-2 items-center">
          <div className="flex flex-row gap-2 items-center">
            {stars["overall"]?.map((star, index) => {
              return (
                <div
                  onClick={() => setRating({ ...rating, overall: index + 1 })}
                  key={index}
                >
                  {star === 0 ? (
                    <img src={feedback_star} />
                  ) : (
                    <img src={feedback_star_fill} />
                  )}
                </div>
              );
            })}
          </div>
          <div className="flex flex-row gap-2 items-center">
            {stars["experience"]?.map((star, index) => {
              return (
                <div
                  onClick={() => {
                    setRating({ ...rating, experience: index + 1 });
                  }}
                  key={index}
                >
                  {star === 0 ? (
                    <img src={feedback_star} />
                  ) : (
                    <img src={feedback_star_fill} />
                  )}
                </div>
              );
            })}
          </div>
          <div className="flex flex-row gap-2 items-center">
            {stars["relevance"]?.map((star, index) => {
              return (
                <div
                  onClick={() => {
                    setRating({ ...rating, relevance: index + 1 });
                  }}
                  key={index}
                >
                  {star === 0 ? (
                    <img src={feedback_star} />
                  ) : (
                    <img src={feedback_star_fill} />
                  )}
                </div>
              );
            })}
          </div>{" "}
          <div className="flex flex-row gap-2 items-center">
            {stars["quality_learn"]?.map((star, index) => {
              return (
                <div
                  onClick={() =>
                    setRating({ ...rating, quality_learn: index + 1 })
                  }
                  key={index}
                >
                  {star === 0 ? (
                    <img src={feedback_star} />
                  ) : (
                    <img src={feedback_star_fill} />
                  )}
                </div>
              );
            })}
          </div>{" "}
          <div className="flex flex-row gap-2 items-center">
            {stars["quality_do"]?.map((star, index) => {
              return (
                <div
                  onClick={() =>
                    setRating({ ...rating, quality_do: index + 1 })
                  }
                  key={index}
                >
                  {star === 0 ? (
                    <img src={feedback_star} />
                  ) : (
                    <img src={feedback_star_fill} />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="mx-auto">
          <LinearTextArea
            columns={30}
            rows={5}
            placeholder="Any suggestions you want to add..."
            onChange={handleChange}
            value={feedback}
          />
        </div>
      </div>
      <div>
        {submitted ? (
          <div>
            <LinearButton
              text="Exit"
              onClick={() => props.showAskFeedbackModal(false)}
            />
          </div>
        ) : (
          <div className="flex flex-row justify-evenly gap-16 my-8 items-center">
            <div>
              <LinearButton text="Submit" onClick={handleSubmit} />
            </div>
            <div>
              <LinearButton
                text="Not now"
                onClick={() => props.showAskFeedbackModal(false)}
              />
            </div>
          </div>
        )}
      </div>
      {message}
    </div>
  );
};

export default Feedback;
