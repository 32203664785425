import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { API } from "../../../../api";
import LinearButton from "../../../../components/Button";
import { useHistory } from "react-router";

const AdminActivityList = () => {
  const [activityList, setActivityList] = useState();
  const [goalList, setGoalList] = useState();
  const [selectedGoal, setSelectedGoal] = useState();

  const history = useHistory();

  useEffect(() => {
    async function getGoalList() {
      const { data } = await API.get("goal", "all");
      setGoalList(data);
    }
    getGoalList();
  }, []);

  const _handleChange = (e) => {
    setSelectedGoal(e.target.value);
  };

  const _handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedGoal !== null) {
      const { data } = await API.get(
        "activity/list",
        `?goal_id=${selectedGoal}`
      );
      setActivityList(data);
    }
  };
  return (
    <div>
      <div>
        <select name="goal_id" className="p-4" onChange={_handleChange}>
          {goalList?.map((goal, index) => {
            return (
              <option key={index} value={goal.id}>
                {goal.name}
              </option>
            );
          })}
        </select>
        <LinearButton text="Filter" onClick={_handleSubmit} />
      </div>
      <div>
        {activityList?.map((activity, index) => {
          return (
            <div key={index} className="p-4 shadow-xl m-4 ">
              <div className="text-2xl">{activity.activity?.name}</div>
              <div>
                <span className="text-xl text-blue-700">Code:</span>{" "}
                {activity.activity?.code}
              </div>
              <div>
                <span className="text-xl text-blue-700">Description:</span>{" "}
                {activity.activity?.description}
              </div>
              <div>
                <span className="text-xl text-blue-700">
                  Reattempt after days:
                </span>{" "}
                {activity.activity?.reattempt_after_days}
              </div>
              <div>
                <span className="text-xl text-blue-700">
                  Same day all correct bonus:
                </span>{" "}
                {activity.activity?.same_day_all_correct_bonus}
              </div>
              <div>
                <span className="text-xl text-blue-700">Same day bonus:</span>{" "}
                {activity.activity?.same_day_bonus}
              </div>
              <div>
                <span className="text-xl text-blue-700">
                  All correct bonus:
                </span>{" "}
                {activity.activity?.all_correct_bonus}
              </div>
              <div>
                <span className="text-xl text-blue-700">Type:</span>{" "}
                {activity.activity?.type}
              </div>
              <div>
                <span className="text-xl text-blue-700">Reading Id:</span>{" "}
                {activity.activity?.reading_id || "None"}
              </div>
              <div className="text-xl text-blue-700">
                Questions under this activity{" "}
              </div>
              <div>
                {activity.activity?.questions?.map((question, index) => {
                  return (
                    <div key={index}>
                      <span>{index + 1} </span>
                      {question.question?.stem}
                    </div>
                  );
                })}
              </div>
              <div>
                <span className="text-xl text-blue-700">Status:</span>{" "}
                {activity.active ? "Active" : "Inactive"}
              </div>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  history.push(`/admin/question/add/${activity.activity?.id}`)
                }
                style={{ padding: "20px" }}
              >
                Add questions
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminActivityList;
