import React, { useState, useEffect } from "react";
import { API } from "../../../api";
import { sortJSONArrayAsc, sortJSONArrayDesc } from "../../../lib/helpers";
import { CircularProgress } from "@material-ui/core";
import LinearButton from "../../../components/Button";
import TeamAdmin from "../Admin";
import { useHistory } from "react-router-dom";

const TeamLeaderboard = () => {
  const [teamStats, setTeamStats] = useState();
  const [groups, setGroups] = useState([]);
  const [groupID, setGroupID] = useState();
  const [xpSort, setXPSort] = useState(true); //true for descending, false for ascending
  const [isLoading, setIsLoading] = useState(true);
  const [invitation, setInvitation] = useState("");

  const history = useHistory();

  async function getTeamStats(id) {
    if (id) {
      const { data } = await API.get("group/get-user-data", id);
      setTeamStats(data);
    }
  }
  const handleJoin = () => {
    history.push("/profile", {
      component: "admin",
    });
  };

  const fetchData = async () => {
    async function getJoinedGroup() {
      const { data } = await API.get("group", "get-joined");
      setGroups(data);
      return data[0];
    }

    async function findUserInGroupsTable() {
      const { data } = await API.get("group", "user-invited");
      return data;
    }

    const joinedGroup = await getJoinedGroup();
    if (joinedGroup) {
      setGroupID(joinedGroup?.group_id);
      getTeamStats(joinedGroup?.group_id);
      sortMembers();
    } else {
      const checkUserExistInGroupsTable = await findUserInGroupsTable();
      if (checkUserExistInGroupsTable.length > 0) {
        setInvitation("pending");
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (groupID) {
      getTeamStats(groupID);
    }
  }, [groupID]);

  const sortMembers = () => {
    if (xpSort) {
      setTeamStats(sortJSONArrayDesc(teamStats, "points"));
    } else {
      setTeamStats(sortJSONArrayAsc(teamStats, "points"));
    }
  };

  const daysBetween = (oldDate, recentDate) => {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(oldDate - recentDate);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <div style={{ marginTop: "-20vh" }}>
            <CircularProgress size={80} />
          </div>
        </div>
      ) : groups.length > 0 ? (
        <div>
          <div className="flex flex-row justify-between items-center ">
            <select
              onChange={(e) => {
                setGroupID(e.target.value);
              }}
              className="input-box body-small-1"
            >
              {groups.map((group, index) => {
                return (
                  <option
                    value={group?.group_id}
                    key={index}
                    // selected={group.id === basicData.country ? true : false}
                  >
                    {group?.groups[0].name}
                  </option>
                );
              })}
            </select>
          </div>
          <div
            className="px-4 py-2 mb-4"
            style={{
              backgroundColor: "#E9F3FD",
            }}
          >
            <div className="flex flex-row mx-4 my-2 items-center">
              <h6 className="w-1/4">Name</h6>
              <h6
                className="w-1/6 text-center cursor-pointer"
                onClick={() => {
                  setXPSort(!xpSort);
                  sortMembers();
                }}
              >
                XP Points
              </h6>
              <h6 className="w-1/6 text-center">Current Streak</h6>
              <h6 className="w-1/6 text-center">Longest Streak</h6>
              <h6 className="w-1/6 text-center">Days of learning</h6>
              <h6 className="w-1/6 text-center">Subtopics Completed</h6>
              <h6 className="w-1/6 text-center">Perfect Activities</h6>
              <h6 className="w-1/6 text-center">Drills Done</h6>
              <h6 className="w-1/6 text-center">Challenges Done</h6>
              <h6 className="w-1/6 text-center">Last active</h6>
            </div>
          </div>
          {teamStats?.map((stats, index) => {
            return (
              <div
                key={index}
                className="flex flex-row justify-between px-4 py-2 mx-4 items-center border-b-2"
              >
                <img
                  alt="profile pic"
                  style={{
                    width: 64,
                    height: 64,
                    minWidth: 64,
                    minHeight: 64,
                    marginRight: 10,
                    borderRadius: "50%",
                    behavior: "url(PIE.htc)",
                  }}
                  src={
                    stats.user?.photo_url
                      ? `${process.env.REACT_APP_S3_URL}/${stats.user?.photo_url}`
                      : `${process.env.REACT_APP_S3_URL}/default_photo.png`
                  }
                />
                <p className="body-small-1 w-1/6">{stats.user?.first_name}</p>

                <div
                  className="body-small-1 text-center p-4 w-1/6 rounded-xl font-bold"
                  style={{
                    color: "var(--blue-de-france)",
                    backgroundColor: "var(--blue-de-france-10)",
                  }}
                >
                  <p className=" font-bold">{stats.points}</p>
                </div>
                <div className="body-small-1 text-center p-4  w-1/6">
                  {stats.streak}
                </div>
                <div className="body-small-1 text-center p-4  w-1/6">
                  {stats.highest_streak}
                </div>
                <div className="body-small-1 text-center p-4  w-1/6">
                  {stats.no_of_days_of_learning}
                </div>
                <div className="body-small-1 text-center p-4 w-1/6">
                  {stats.no_of_subtopics_completed}
                </div>
                <div className="body-small-1 text-center p-4 w-1/6">
                  {stats.no_of_perfect_activities}
                </div>
                <div className="body-small-1 text-center p-4 w-1/6">
                  {stats.no_of_drills_done}
                </div>
                <div className="body-small-1 text-center p-4 w-1/6">
                  {stats.no_of_challenges_done}
                </div>
                <div className="body-small-1 text-center p-4 w-1/6">
                  {new Date(stats.last_active).getDate() -
                    new Date().getDate() ===
                  0
                    ? "Today"
                    : stats.last_active !== null
                    ? daysBetween(new Date(stats.last_active), new Date()) +
                      " days ago"
                    : "Not logged in"}
                </div>
              </div>
            );
          })}
        </div>
      ) : invitation === "pending" ? (
        <div className="flex items-center space-x-4">
          <p>You have pending invitations. Click here to manage them!</p>
          <LinearButton onClick={handleJoin} text={"Manage"} />
        </div>
      ) : (
        <div>
          You are not yet a member of any team. If you are on an enterprise
          subscription plan, contact your organization administrator to be added
          to a group.
        </div>
      )}
    </div>
  );
};

export default TeamLeaderboard;
