import React, { useEffect, useState } from "react";
import { API } from "../../../../api";
import {
  TextField,
  Button,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const AdminAddQuestion = (props) => {
  const { activity_id } = props.match.params;
  const [questionData, setQuestionData] = useState({
    stem: "",
    snippet: "",
    repeatable: false,
    subtopic_id: "",
    type: "mcq",
    activity_id: activity_id,
    right_response_points: 1,
    wrong_response_points: 0,
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    correct_option: "",
    answer: "",
  });
  const [subtopicList, setSubtopicList] = useState();

  const [inProgress, toggleProgress] = useState(false);
  const [alertVisible, toggleAlertVisibility] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    async function getSubtopicList() {
      const { data } = await API.get("subtopic", "all");
      setSubtopicList([" ", ...data]);
    }
    getSubtopicList();
  }, []);

  const _handleChange = (e) => {
    setQuestionData({ ...questionData, [e.target.name]: e.target.value });
    console.log(questionData);
  };

  const _handleSubmit = async (e) => {
    e.preventDefault();
    try {
      toggleProgress(true);
      const { data, status } = await API.post(
        "activity/add-question",
        questionData
      );
      if (status === 200) {
        setAlertMessage("Question has been added");
        setAlertSeverity("info");
        toggleAlertVisibility(true);
        toggleProgress(false);
      } else {
        setAlertMessage(data.error ? data.error.message : "Error Occured");
        setAlertSeverity(data.error ? "error" : "info");
        toggleAlertVisibility(true);
        toggleProgress(false);
      }
    } catch (err) {
      setAlertMessage("An error occured. Please try again later.");
      setAlertSeverity("error");
      toggleAlertVisibility(true);
      toggleProgress(false);
    }
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="shadow-xl p-36">
        <div className="text-center text-xl mb-4">Add Question to Activity</div>
        <div className="flex flex-row items-center">
          <TextField
            label="Activity ID"
            variant="outlined"
            id="outlined-basic"
            name="activity_id"
            type="text"
            value={questionData.activity_id}
            disabled={activity_id ? true : false}
            onChange={_handleChange}
          />
          <div>
            <h6>Select subtopic</h6>
            <select
              className="p-4 mb-4"
              onChange={_handleChange}
              name="subtopic_id"
            >
              {subtopicList?.map((subtopic, index) => {
                return (
                  <option key={index} value={subtopic.id}>
                    {subtopic.name}
                  </option>
                );
              })}
            </select>
          </div>
          <select name="type" onChange={_handleChange}>
            <option value="mcq">MCQ</option>
            <option value="fill_blanks">Fill Blanks</option>
            <option value="order">Order</option>
          </select>
        </div>
        <div className="py-4">
          <TextField
            label="Question"
            variant="outlined"
            id="outlined-basic"
            name="stem"
            onChange={_handleChange}
            className="w-full"
          />
        </div>
        <div classname="py-4">
          <TextField
            label="Snippet"
            variant="outlined"
            id="outlined-basic"
            name="snippet"
            onChange={_handleChange}
            className="w-full"
          />
        </div>
        <div className="flex flex-col my-4 gap-4">
          <TextField
            label="Option 1"
            variant="outlined"
            id="outlined-basic"
            name="option_1"
            onChange={_handleChange}
          />
          <TextField
            label="Option 2"
            variant="outlined"
            id="outlined-basic"
            name="option_2"
            onChange={_handleChange}
          />
          <TextField
            label="Option 3"
            variant="outlined"
            id="outlined-basic"
            name="option_3"
            onChange={_handleChange}
          />
          <TextField
            label="Option 4"
            variant="outlined"
            id="outlined-basic"
            name="option_4"
            onChange={_handleChange}
          />
        </div>
        <div className="py-4 flex gap-4">
          <TextField
            label="Correct Option No(MCQ)"
            variant="outlined"
            id="outlined-basic"
            name="correct_option"
            onChange={_handleChange}
          />
          <TextField
            label="Answer(Fill blanks)"
            variant="outlined"
            id="outlined-basic"
            name="answer"
            onChange={_handleChange}
          />
        </div>
        <Button
          color="primary"
          variant="contained"
          onClick={_handleSubmit}
          style={{ padding: "20px" }}
          disabled={inProgress}
        >
          {inProgress ? <CircularProgress /> : "Add Question"}
        </Button>
        {alertVisible && (
          <Alert style={{ marginTop: "1.5rem" }} severity={alertSeverity}>
            {alertMessage}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default AdminAddQuestion;
