import { Route, Switch } from "react-router-dom";
import AdminAddOrganization from "./Add";
import AdminListOrganization from "./List";

export default function AdminOrganizationRoutes() {
  return (
    <Switch>
      <Route
        exact
        path="/admin/organization/add"
        component={AdminAddOrganization}
      />
      <Route
        exact
        path="/admin/organization/list"
        component={AdminListOrganization}
      />
    </Switch>
  );
}
