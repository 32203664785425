import React, { useEffect, useRef, useState } from "react";
import { API } from "../../../api";
import Check from "@material-ui/icons/Check";
import LinearButton from "../../../components/Button";
import LinearTextBox from "../../../components/TextBox";
import NewModal from "../../../components/NewModal";
import useSWR, { mutate, trigger } from "swr";
import useScreenSize from "../../../hooks/useScreenSize";

const Todos = () => {
  // const [todoList, setTodoList] = useState(
  //   useSWR({ url: "user-data/todos" }) || []
  // );
  const url = "user-data/todos";
  const [showAddTodo, setShowAddTodo] = useState(false);
  const [newTodo, setNewTodo] = useState("");
  const [response, setResponse] = useState("");
  const { isMobile } = useScreenSize();

  let { data: todoList } = useSWR(url);

  const inputRef = useRef();

  // async function getTodos() {
  //   const { data } = await API.get("user-data", "todos");
  //   setTodoList(data);
  // }

  // useEffect(() => {
  //   getTodos();
  // }, []);

  const handleTodoTextChange = (e) => {
    setNewTodo(e.target.value);
    setResponse("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { status } = await API.post("user-data/add-todo", {
      todo_title: newTodo,
    });
    if (status === 200) {
      // getTodos();
      mutate(url);
      setNewTodo("");
      inputRef.current.value = "";
      setResponse("Added");
    } else {
      setResponse("An error occured");
    }
  };

  const _handleChange = async (id) => {
    await API.post(`user-data/complete-todo/${id}`);
    mutate(url);
    // getTodos();
  };

  return (
    <div className="">
      <div className="flex flex-row justify-between items-center m-4">
        <h5>My todos</h5>
        <LinearButton text="New Todo" onClick={() => setShowAddTodo(true)} />
      </div>
      <div
        className=""
        style={{
          overflowY: "scroll",
          scrollBehavior: "smooth",
          maxHeight: "70vh",
        }}
      >
        <h5 className="px-4 py-2">Pending</h5>
        {todoList?.map((todo, index) => {
          return (
            <>
              {todo.completed === false && (
                <div
                  key={index}
                  className="flex flex-row justify-between items-center border-b-2"
                >
                  <div className="body-small-1 px-4 py-2">
                    {todo.todo_title}
                  </div>
                  <div className="px-4 py-2 flex gap-8">
                    {/* <Check
                      className="cursor-pointer"
                      onClick={() => {
                        _handleChange(todo.id);
                      }}
                    /> */}
                    <input
                      type="checkbox"
                      onClick={() => {
                        _handleChange(todo.id);
                      }}
                      // ref={checkRef}
                      style={{ width: "1.5rem", height: "1.5rem" }}
                    ></input>
                    {/* <Cross className="cursor-pointer" /> */}
                  </div>
                </div>
              )}
            </>
          );
        })}
        <h5 className="px-4 py-2">Completed</h5>
        {todoList?.map((todo, index) => {
          return (
            <>
              {todo.completed === true && (
                <div
                  key={index}
                  className="flex flex-row border-b-2 items-center"
                >
                  <div className="body-small-1 px-4 py-2">
                    {todo.todo_title}
                  </div>
                  <div className="px-4 py-2"></div>
                </div>
              )}
            </>
          );
        })}
      </div>
      {showAddTodo && (
        <NewModal
          body={
            <div className="flex flex-col gap-8 items-center justify-center">
              <div className="text-center">
                <h5>Add new todo</h5>
              </div>
              <LinearTextBox
                style={isMobile ? { width: "70vw" } : {}}
                label=""
                placeholder="Do linear activities"
                name="todo"
                onChange={handleTodoTextChange}
                inputRef={inputRef}
              />
              <LinearButton text="Add" width="15rem" onClick={handleSubmit} />
              {response}
            </div>
          }
          onClose={() => {
            setShowAddTodo(false);
            setResponse("");
          }}
          hasCloseButton={true}
        />
      )}
    </div>
  );
};

export default Todos;
