import React, { useState, useEffect } from "react";
import { API } from "../../../../api";

const AdminAllChallenge = () => {
  const [challengeList, setChallengeList] = useState();
  useEffect(() => {
    async function getChallengeList() {
      const { data } = await API.get("challenge", "all");
      setChallengeList(data);
    }
    getChallengeList();
  }, []);
  return (
    <div>
      {challengeList?.map((challenge, index) => {
        return (
          <div key={index} className="p-4 shadow-xl m-4 ">
            <div className="text-2xl">{challenge.name}</div>
            <div>
              <span className="text-xl text-blue-700">Code:</span>{" "}
              {challenge.code}
            </div>
            <div>
              <span className="text-xl text-blue-700">Description:</span>{" "}
              {challenge.description}
            </div>
            <div>
              <span className="text-xl text-blue-700">Calender Required:</span>{" "}
              {challenge.calender_required}
            </div>
            <div>
              <span className="text-xl text-blue-700">
                Time Required in minutes
              </span>{" "}
              {challenge.time_required_in_minutes}
            </div>
            <div>
              <span className="text-xl text-blue-700">Reward Points:</span>{" "}
              {challenge.reward_points}
            </div>
            <div>
              <span className="text-xl text-blue-700">Type:</span>{" "}
              {challenge.type}
            </div>

            <div>
              <span className="text-xl text-blue-700">Status:</span>{" "}
              {challenge.active ? "Active" : "Inactive"}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AdminAllChallenge;
