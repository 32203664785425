import React, { useState, useEffect, useRef } from "react";
import useSWR from "swr";
import { API } from "../../../../api";
import LinearButton from "../../../../components/Button";
import LinearTextBox from "../../../../components/TextBox";
import NewModal from "../../../../components/NewModal";
import Table from "../../../../components/Table";
import edit_svg from "../../../../assests/icons/ic/edit_1.svg";
import user_svg from "../../../../assests/icons/ic/logout.svg";
import delete_svg from "../../../../assests/icons/ic/delete.svg";
import Excel from "react-export-excel";

export default function AdminListUser({ orgName, orgId, grpName, grpId }) {
  const [userList, setUserList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupId, setGroupId] = useState(grpId);
  const [groupName, setGroupName] = useState(grpName);
  const [organizationId, setOrganizationId] = useState(orgId);
  const [organizationName, setOrganizationName] = useState(orgName);

  const [message, setMessage] = useState("");
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);

  const [showAddNewTeamMemberModal, setShowAddNewTeamMemberModal] =
    useState(false);
  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  const [showMoveUserModal, setShowMoveUserModal] = useState(false);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);

  const [currentUser, setCurrentUser] = useState({});
  const [updateGroupId, setUpdateGroupId] = useState("");
  // const [editUser, setEditUser] = useState({});
  const [editUserId, setEditUserId] = useState();
  const [editUserFName, setEditUserFName] = useState("");
  const [editUserLName, setEditUserLName] = useState("");

  const [currentOrgId, setCurrentOrgId] = useState(orgId);
  const [selectedOrgId, setSelectedOrgId] = useState(orgId);

  const [currentGrpId, setCurrentGrpId] = useState(grpId);

  const [selectedGrpId, setSelectedGrpId] = useState(grpId);

  const [dataList, setDataList] = useState([]);

  const [messageColor, setMessageColor] = useState("green");

  const ExcelFile = Excel.ExcelFile;
  const ExcelSheet = Excel.ExcelFile.ExcelSheet;
  const ExcelColumn = Excel.ExcelFile.ExcelColumn;

  const emailRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();

  const { data: organizationList } = useSWR("organization/all");
  const { data: allGroupList } = useSWR("group/all");

  const getGroups = async () => {
    const { data } = await API.get(
      "group/all",
      `?organization_id=${organizationId}`
    );
    setGroupList(data);
  };

  useEffect(() => {
    getGroups();
  }, [organizationId]);

  const getUsers = async () => {
    const { data } = await API.get(`group/getUsersForAdmin/`, `${groupId}`);
    setUserList(data.users);
  };

  useEffect(() => {
    getUsers();
  }, [groupId]);

  const getEdit = (users) => {
    let userId = users.user_id;
    let fName = users.user.first_name;
    let lName = users.user.last_name;
    let groupId = users.group_id;
    let groupName = users.groups[0].name;
    let userName = `${users.user.first_name} ${users.user.last_name}`;

    return (
      <div>
        <button
          onClick={(e) => {
            setMessage("");
            setShowUpdateUserModal(true);
            setCurrentUser({ userId, userName, groupId, groupName });
            // setEditUser({userId, fName, lName});
            setEditUserId(userId);
            setEditUserFName(fName);
            setEditUserLName(lName);
          }}
        >
          <img src={edit_svg} alt="edit image" className="h-14" />
        </button>
      </div>
    );
  };

  const getDelete = () => {
    return (
      <div>
        <button
          onClick={(e) => {
            setMessage("");
            setShowRemoveUserModal(true);
          }}
        >
          <img src={delete_svg} alt="delete image" className="h-7" />
        </button>
      </div>
    );
  };

  const movingUser = (users) => {
    let userId = users.user_id;
    let groupId = users.group_id;
    let groupName = users.groups[0].name;
    let userName = `${users.user.first_name} ${users.user.last_name}`;
    return (
      <div>
        <button
          onClick={(e) => {
            setMessage("");
            setCurrentUser({ userId, userName, groupId, groupName });
            setShowMoveUserModal(true);
          }}
        >
          <img src={user_svg} alt="move user image" className="h-7" />
        </button>
      </div>
    );
  };

  useEffect(() => {
    setDataList([]);
    const tableData = [];
    let temp = [];
    userList &&
      userList?.map((users, index) => {
        const userName = `${users.user.first_name} ${users.user.last_name}`;
        const grpName = `${users.groups[0].name}`;
        const role = users.role === "admin" ? "Admin" : "Member";
        const status = users.status === "joined" ? "Joined" : "Invited";
        const edit = getEdit(users);
        const mov = movingUser(users);
        const dele = getDelete();

        const element = [userName, grpName, role, status, edit, mov, dele];
        tableData.push(element);
        temp.push({
          email: users.user.email,
          first_name: users.user.first_name,
          last_name: users.user.last_name,
          invite_link: `${process.env.REACT_APP_CLIENT_URL}/group/join-link/${users.user.email}/${users.group_id}`,
        });
      });
    setTableHeader([
      "User",
      "Group",
      "Role",
      "Status",
      "Edit",
      "Move",
      "Delete",
    ]);
    setTableBody(tableData);
    setDataList(temp);
  }, [userList]);

  const addTeamMember = async () => {
    setMessageColor("green");
    const addUserList = [
      {
        email: emailRef.current.value,
        first_name: firstNameRef.current.value,
        last_name: lastNameRef.current.value,
        invite_link: `${process.env.REACT_APP_CLIENT_URL}/group/join-link/${emailRef.current.value}/${groupId}`,
      },
    ];

    if (
      addUserList[0].email !== "" &&
      addUserList[0].first_name !== "" &&
      addUserList[0].last_name !== ""
    ) {
      try {
        const { data, status } = await API.post("group/add-user-only", {
          group_id: groupId,
          user_list: addUserList,
        });
        if (status === 200 || status === 201 || status === 202) {
          setMessage("Member added successfully");
        } else {
          setMessageColor("red");
          setMessage(data.error ? data.error.message : "Error Occured");
        }
      } catch (error) {
        setMessageColor("red");
        setMessage("An error occured. Please try again later.");
      }
    } else {
      setMessageColor("red");
      setMessage("Please fill the details");
    }

    emailRef.current.value = "";
    firstNameRef.current.value = "";
    lastNameRef.current.value = "";
    getUsers();
  };

  const moveUser = async () => {
    setMessageColor("green");
    const { status } = await API.patch(`group/move-user/`, {
      user_id: currentUser.userId,
      current_group_id: currentUser.groupId,
      new_group_id: updateGroupId,
    });
    if (status === 500 || status === 404 || status === 403) {
      setMessageColor("red");
      setMessage("An error occured");
    } else {
      setMessage("User moved successfully");
    }
    getUsers();
  };

  const updateUser = async () => {
    setMessageColor("green");
    const { status } = await API.patch(`admin/update-user/`, {
      user_id: editUserId,
      first_name: editUserFName,
      last_name: editUserLName,
    });
    if (status === 500 || status === 404 || status === 403) {
      setMessageColor("red");
      setMessage("An error occured");
    } else {
      setMessage("User details updated successfully");
    }
    getUsers();
  };

  const removeUser = () => {
    setMessage("This option is currently not available now");
  };

  const getOrgName = (organizationId) => {
    for (let org of organizationList) {
      if (org.id == organizationId) {
        setOrganizationName(org.name);
        setOrganizationId(org.id);
      }
    }
  };

  const getGroupName = (grpId) => {
    for (let grp of allGroupList) {
      if (grp.id == grpId) {
        setGroupName(grp.name);
        setGroupId(grp.id);
      }
    }
  };

  const setFirstGrp = async (orgId) => {
    const { data } = await API.get("group/all", `?organization_id=${orgId}`);
    setGroupId(data[0].id);
    setCurrentGrpId(data[0].id);
  };

  return (
    <div className="h-screen">
      <h5>Users</h5>
      <div className="flex flex-row justify-center gap-4 items-center mb-4">
        <label className="body-small-1">Organization</label>
        <select
          onChange={(e) => {
            setOrganizationId(e.target.value);
            getOrgName(e.target.value);
            setMessage("");
            setCurrentOrgId(e.target.value);
            setSelectedOrgId(e.target.value);
            setFirstGrp(e.target.value);
          }}
          name="name"
          className="input-box body-small-1"
          value={currentOrgId}
        >
          {organizationList?.map((org) => {
            return (
              <option value={org.id} key={org.id}>
                {org.name}
              </option>
            );
          })}
        </select>

        <label className="body-small-1">Group</label>
        <select
          onChange={(e) => {
            setGroupId(e.target.value);
            getGroupName(e.target.value);
            setMessage("");
            setCurrentGrpId(e.target.value);
            setSelectedGrpId(e.target.value);
          }}
          name="name"
          className="input-box body-small-1"
          value={currentGrpId}
        >
          {groupList?.map((grp) => {
            return (
              <option value={grp.id} key={grp.id}>
                {grp.name}
              </option>
            );
          })}
        </select>
        {/* <LinearTextBox name="name" defaultValue={groupName} label="Team" /> */}
      </div>

      <div className={`flex flex-row justify-between items-center m-${4}`}>
        <button
          className="primary-button button-small text-white p-4"
          style={{ backgroundColor: "#003366", borderRadius: "10px" }}
          onClick={() => {
            setMessage("");
            setShowAddNewTeamMemberModal(true);
          }}
        >
          Add Team Member
        </button>
        <ExcelFile
          element={<LinearButton text="Download Data" size="extra-large" />}
          filename={groupName} //  selectedGroupName
        >
          <ExcelSheet data={dataList} name="Members">
            <ExcelColumn label="First Name" value="first_name" />
            <ExcelColumn label="Last Name" value="last_name" />
            <ExcelColumn label="Email" value="email" />
            <ExcelColumn label="Invite Link" value="invite_link" />
          </ExcelSheet>
        </ExcelFile>
      </div>

      <Table header={tableHeader} body={tableBody} />

      {showAddNewTeamMemberModal && (
        <NewModal
          heading={<div className="mx-6">Add new Team Member</div>}
          body={
            <div className="my-2 flex flex-col sm:flex-row justify-center items-center gap-2 sm:gap-4 flex-wrap">
              <LinearTextBox
                label="First Name"
                inputRef={firstNameRef}
                placeholder="Enter first name"
              />
              <LinearTextBox
                label="Last Name"
                inputRef={lastNameRef}
                placeholder="Enter last name"
              />
              <LinearTextBox
                label="Email"
                inputRef={emailRef}
                placeholder="Enter team member email address"
              />
            </div>
          }
          footer={
            <div className="flex flex-col justify-center gap-4 items-center mx-16">
              <LinearButton text="Add" onClick={addTeamMember} />
              <h6 style={{ color: `${messageColor}` }}>{message}</h6>
            </div>
          }
          onClose={() => {
            setShowAddNewTeamMemberModal(false);
          }}
        />
      )}

      {showMoveUserModal && (
        <NewModal
          heading={<div className="mx-10">Move User</div>}
          body={
            <div>
              <div className="py-4 px-8 rounded-xl shadow-lg flex flex-col gap-4 bg-white">
                <div>
                  <LinearTextBox
                    name="name"
                    label="User name"
                    placeholder="Enter User Name Here"
                    defaultValue={currentUser.userName}
                  />
                </div>
                <div className="flex flex-col w-full sm:w-2/5">
                  <LinearTextBox
                    name="name"
                    label="Current Group"
                    defaultValue={currentUser.groupName}
                  />
                </div>
                <div className="flex flex-col w-full sm:w-2/5">
                  <label className="body-small-1">New Group</label>
                  <select
                    onChange={(e) => {
                      setUpdateGroupId(e.target.value);
                      setMessage("");
                    }}
                    name="country"
                    className="input-box body-small-1"
                  >
                    {groupList &&
                      [" ", ...groupList]?.map((grp, index) => {
                        return (
                          <option value={grp.id} key={index}>
                            {grp.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          }
          footer={
            <div className="flex flex-col justify-center gap-4 items-center mx-16">
              <LinearButton text="Move" onClick={moveUser} />
              <h6 style={{ color: `${messageColor}` }}>{message}</h6>
            </div>
          }
          onClose={() => {
            setShowMoveUserModal(false);
          }}
        />
      )}

      {showUpdateUserModal && (
        <NewModal
          heading={<div className="mx-10">Update User Details</div>}
          body={
            <div>
              <div className="py-4 px-8 rounded-xl shadow-lg flex flex-col gap-4 bg-white">
                <div>
                  <LinearTextBox
                    name="name"
                    onChange={(e) => {
                      // setEditUser({fName : e.target.value});
                      setEditUserFName(e.target.value);
                      setMessage("");
                    }}
                    label="First Name"
                    placeholder="Enter User Name Here"
                    defaultValue={editUserFName}
                  />
                </div>
                <div className="flex flex-col w-full sm:w-2/5">
                  <LinearTextBox
                    name="name"
                    onChange={(e) => {
                      // setEditUser({lName : e.target.value});
                      setEditUserLName(e.target.value);
                      setMessage("");
                    }}
                    label="Last Name"
                    defaultValue={editUserLName}
                  />
                </div>
              </div>
            </div>
          }
          footer={
            <div className="flex flex-col justify-center gap-4 items-center mx-16">
              <LinearButton text="Update" onClick={updateUser} />
              <h6 style={{ color: `${messageColor}` }}>{message}</h6>
            </div>
          }
          onClose={() => setShowUpdateUserModal(false)}
        />
      )}

      {showRemoveUserModal && (
        <NewModal
          heading={<div className="mx-10">Are you sure?</div>}
          body={
            <div className="mx-16">
              {/* Are you sure you want to remove this team? */}
              <h6>Deleting user is currently not available!</h6>
            </div>
          }
          footer={
            <div className="flex flex-col justify-center gap-4 items-center mx-16">
              {/* <LinearButton text="Yes, remove" onClick={removeUser} /> */}
              <h6 style={{ color: `${messageColor}` }}>{message}</h6>
            </div>
          }
          onClose={() => setShowRemoveUserModal(false)}
        />
      )}
    </div>
  );
}
