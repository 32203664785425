import { useState } from "react";

export default function useScreenSize() {
  const [isMobile, setIsMobile] = useState(window.screen.width < 430);

  function checkMobile() {
    if (window.screen.width < 430) setIsMobile(true);
    else setIsMobile(false);
  }
  window.onresize = checkMobile;
  return { isMobile };
}
