import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import background_hill from "../../../assests/icons/background_hill.png";
import CancelIcon from "@material-ui/icons/Clear";
import "keen-slider/keen-slider.min.css";
import axios from "axios";
import FileDownload from "js-file-download";

import { API } from "../../../api/index";

import LinearButton from "../../../components/Button";
import useScreenSize from "../../../hooks/useScreenSize";
import NewModal from "../../../components/NewModal";
import ProgressBar from "../../../components/Activity/ProgressBar";
import { CircularProgress } from "@material-ui/core";

// Components
import MCQ from "../MCQ/index";
import FillBlanks from "../FillBlanks/index";
import FlashCard from "../FlashCard/index";
import Reading from "../Reading/index";
import Scale from "../Scale/index";
import useChallengeState from "../challengeData/index";
import { useLocation } from "react-router";


const NewChallengeContainer = (props) => {

  const [challenge, setChallenge] = useState();
  const [questionList, setQuestionList] = useState([]);
  const [challengeDescription, setChallengeDescription] = useState("");
  const [challengeTemplateUrl, setChallengeTemplateUrl] = useState("");
  const [challengeName, setChallengeName] = useState("");
  const [question, setQuestion] = useState();
  const [currentSequence, setCurrentSequence] = useState(0);
  const [sequence, setSequence] = useState(0);
  const [maxSequence, setMaxSequence] = useState(1);
  const [challengeResponse, setChallengeResponse] = useState();
  const [bgcolor, setBgColor] = useState("#FFFFFF");
  const [message, setMessage] = useState("");
  const [snippetShow, setSnippetShow] = useState(false);
  const [response, setResponse] = useState();
  const [isLoading, toggleLoading] = useState(true);
  const [buttonLoading, toggleButtonLoading] = useState(false);
  const [showQuit, setShowQuit] = useState(false);
  const [lastResponded, setLastResponded] = useState();
  const challengeBodyEndRef = useRef();
  const challengeBodyStartRef = useRef();
  const history = useHistory();
  const { challengeState, addNewResponse } = useChallengeState();
  const [answer, setAnswer] = useState("");
  const [showFlashCardSnippet, toggleflashCardSnippet] = useState(false);
  const [enterChallenge, setEnterChallenge] = useState(false);
  const [showChallengeDescription, setShowChallengeDescription] = useState(true);
  const { isMobile } = useScreenSize();
  const [mcqHasResponse, setMcqHasResponse] = useState(false);

  const location = useLocation();

  const { challenge_id } = props.match.params;
  const { completedDate, goal_id, completed } = location.state;
  const review_mode = Boolean(completed && completedDate);

  useEffect(() => {

    async function fetchData() {
      // Fetching data
      let userHasResponse = false;
      const { data } = await API.get(`challenge/goal_id/${goal_id}/id`, challenge_id);
      const challengeSteps = data.challenge_steps;
      setQuestionList(challengeSteps);
      setChallengeDescription(data.description);
      setChallengeTemplateUrl(data.template_url);
      setChallengeName(data.name);
      setChallenge(challengeSteps);
      setMaxSequence(challengeSteps.length);
      toggleLoading(false);
      setSequence(1);
      setCurrentSequence(1);

      for (let i = 0; i < challengeSteps.length; i++) {
        // This loop is for checking if user has response
        userHasResponse = data?.challenge_steps[i].user_challenge_steps?.mcq_response !== null
          || data?.challenge_steps[i].user_challenge_steps?.fill_in_the_blanks_response !== null
          || data?.challenge_steps[i].user_challenge_steps?.assesment_response !== null
          ? true : false;
      }

      if (userHasResponse) {

        for (let i = 0; i < challengeSteps.length; i++) {
          // If we get to know user has responses we will map them into challengeState
          // if user has not responded we will add an empty object in challengeState array
          // If user has responded we will add their response

          if (challengeSteps[i].step_type === "mcq") {
            if (challengeSteps[i].user_challenge_steps !== null) {
              let mcq_opt = challengeSteps[i].challenge_steps_components;
              let correct_Answer;
              let isUser_Correct = false;
              for (let j = 0; j < mcq_opt.length; j++) {
                // This loop is for getting correctAnswer and isUserCorrect
                if (mcq_opt[j].mcq_options.is_correct === true) {
                  correct_Answer = mcq_opt[j].mcq_options;
                  if (mcq_opt[j].mcq_options.id === challengeSteps[i].user_challenge_steps?.mcq_response) {
                    isUser_Correct = true;
                  }
                }
              }
              setMcqHasResponse(true);
              challengeState[i] = {
                id: challengeSteps[i].user_challenge_steps?.id,
                challenge_step_id: challengeSteps[i].user_challenge_steps?.id,
                userResponse: challengeSteps[i].user_challenge_steps?.mcq_response,
                correctAnswer: correct_Answer,
                isUserCorrect: isUser_Correct,
                type: challengeSteps[i].step_type,
              };
            }

          } else if (challengeSteps[i].step_type === "textbox") {
            if (challengeSteps[i].user_challenge_steps !== null) {
              challengeState[i] = {
                id: challengeSteps[i].user_challenge_steps?.id,
                challenge_step_id: challengeSteps[i].user_challenge_steps?.id,
                userResponse: challengeSteps[i].user_challenge_steps?.fill_in_the_blanks_response,
                correctAnswer: challengeSteps[i].step_display,
                isUserCorrect: true,
                type: challengeSteps[i].step_type,
              };
            }
          } else if (challengeSteps[i].step_type === "scale_or_rating") {
            if (challengeSteps[i].user_challenge_steps !== null) {
              challengeState[i] = {
                id: challengeSteps[i].user_challenge_steps?.id,
                challenge_step_id: challengeSteps[i].user_challenge_steps?.id,
                userResponse: challengeSteps[i].user_challenge_steps?.assesment_response,
                correctAnswer: challengeSteps[i].step_display,
                isUserCorrect: true,
                type: challengeSteps[i].step_type,
              };
            }

          } else {
            if (challengeSteps[i].user_challenge_steps !== null) {
              challengeState[i] = {
                id: challengeSteps[i].id,
                challenge_step_id: challengeSteps[i].id,
                correctAnswer: challengeSteps[i].step_display,
                isUserCorrect: true,
                userResponse: { answer: "", type: `${challengeSteps[i].step_type}` },
                type: challengeSteps[i].step_type,
              };
            }
          }
        }
      }
      // setQuestionLoading(false);
    }

    fetchData();

  }, []);

  useEffect(() => {
    if (message)
      challengeBodyEndRef.current?.scrollIntoView({ behavior: "smooth" });
    else {
      challengeBodyStartRef.current?.scrollIntoView({ behaviour: "smooth" });
    }
  }, [message]);

  const getResponse = (response) => {
    // All our responses from differnt containers (Mcq, textbox, scale) come to this place to call back function
    // We receive response and set it to setChallengeResponse
    setChallengeResponse(response);
  };

  const _nextQuestion = async (id) => {
    // Moving user to next question
    if (sequence < maxSequence && currentSequence === sequence)
      setSequence(sequence + 1);

    setCurrentSequence((currentSequence) => currentSequence + 1);
  };

  const submitChallenge = () => {
    // We will redirect user to user dashboard once they click Finish button
    history.push(`/challenge/complete/new/${challenge_id}`, {
      user_challenge_id: challenge_id,
      goal_id
    });
    // };
    // history.push("/user/dashboard");
  }

  const moveForward = async (e) => {
    // This function is for moving forward to next question
    if (e.target.textContent.trim() === "Next") {
      setBgColor("#FFFFFF");
      _nextQuestion();
      if (currentSequence === sequence) {
        setMessage();
        setResponse();
        setSnippetShow(false);
        toggleflashCardSnippet(false);
      }
    } else if (e.target.textContent.trim() === "Finish") {
      if (review_mode) {
        history.push(`/user/dashboard`);  
        return;
      }
  
      // Now if we get mcq, textbox or scale as last question
      // We have to use same post api to post user response in a differnt manner
      // And once we get response as "data" back we just have to extract userResponse and store it in challengeState

      if (questionList[currentSequence - 1]?.step_type === "mcq") {
        toggleButtonLoading(true);

        let step_id = questionList[currentSequence - 1].id;
        let response = challengeResponse.answer;
        const { data } = await API.post(
          `user-data/user-challenge-step/`, {
          challenge_step_id: step_id,
          mcq_response: response,
          goal_id
        }
        );
        setResponse(data);
        setSnippetShow(true);
        if (data?.isCorrect) {
          setBgColor("#0ACC81");
          setMessage("CORRECT ANSWER");
        } else {
          setBgColor("#CC3414");
          setMessage("INCORRECT ANSWER");
        }
        challengeState[currentSequence - 1] = {
          id: questionList[currentSequence - 1].id,
          challenge_step_id: data?.data.id,
          correctAnswer: data?.correctAnswer.mcq_options,
          isUserCorrect: data?.isCorrect,
          userResponse: challengeResponse.answer,
          type: questionList[currentSequence - 1].step_type,
        };
        setLastResponded(questionList[currentSequence - 1].id);
        toggleButtonLoading(false);

      } else if (questionList[currentSequence - 1]?.step_type === "textbox") {
        toggleButtonLoading(true);
        // We should use Post api if user has not responded
        // If user has responded we have to use Patch api

        if (challengeState[currentSequence - 1]?.userResponse !== undefined) {

          let step_id = challengeState[currentSequence - 1]?.challenge_step_id;
          let response = challengeResponse.answer;
          console.log({ step_id, cs: challengeState[currentSequence - 1] });
          const { data } = await API.patch(
            `user-data/user-challenge-step/`, {
            user_challenge_step_id: step_id,
            fill_in_the_blanks_response: response,
            goal_id
          });

          challengeState[currentSequence - 1].userResponse = data?.data?.fill_in_the_blanks_response;

        } else {
          let step_id = questionList[currentSequence - 1].id;
          let response = challengeResponse.answer;

          const { data } = await API.post(
            `user-data/user-challenge-step/`, {
            challenge_step_id: step_id,
            fill_in_the_blanks_response: response,
            goal_id
          });
          challengeState[currentSequence - 1] = {
            id: questionList[currentSequence - 1].id,
            challenge_step_id: data?.data.id,
            correctAnswer: questionList[currentSequence - 1].step_display,
            isUserCorrect: true,
            userResponse: data?.data.fill_in_the_blanks_response,
            type: questionList[currentSequence - 1].step_type,
          };
        }

        setLastResponded(questionList[currentSequence - 1].id);
        toggleButtonLoading(false);

      } else if (questionList[currentSequence - 1]?.step_type === "scale_or_rating") {
        toggleButtonLoading(true);
        // We should use Post api if user has not responded
        // If user has responded we have to use Patch api

        if (challengeState[currentSequence - 1]?.userResponse !== undefined) {

          let step_id = challengeState[currentSequence - 1]?.challenge_step_id;
          let response = Number(challengeResponse.answer);
          const { data } = await API.patch(
            `user-data/user-challenge-step/`, {
            user_challenge_step_id: step_id,
            assesment_response: response,
            goal_id
          });

          challengeState[currentSequence - 1].userResponse = data?.data?.assesment_response;

        } else {
          let step_id = questionList[currentSequence - 1].id;
          let response = Number(challengeResponse.answer);

          const { data } = await API.post(
            `user-data/user-challenge-step/`, {
            challenge_step_id: step_id,
            assesment_response: response,
            goal_id
          });
          challengeState[currentSequence - 1] = {
            id: questionList[currentSequence - 1].id,
            challenge_step_id: data?.data.id,
            correctAnswer: questionList[currentSequence - 1].step_display,
            isUserCorrect: true,
            userResponse: data?.data.assesment_response,
            type: questionList[currentSequence - 1].step_type,
          };
        }
        setLastResponded(questionList[currentSequence - 1].id);
        toggleButtonLoading(false);

      } else if (questionList[currentSequence - 1]?.step_type === "visual_flash_card"
        || questionList[currentSequence - 1]?.step_type === "reading") {
        if (challengeState[currentSequence - 1]?.userResponse !== undefined) {
          challengeState[currentSequence - 1].userResponse = { answer: "", type: `${questionList[currentSequence - 1]?.step_type}` };
        } else {
          challengeState[currentSequence - 1] = {
            id: questionList[currentSequence - 1].id,
            challenge_step_id: questionList[currentSequence - 1].id,
            correctAnswer: questionList[currentSequence - 1].step_display,
            isUserCorrect: true,
            userResponse: { answer: "", type: `${questionList[currentSequence - 1]?.step_type}` },
            type: questionList[currentSequence - 1].step_type,
          };
        }
        setLastResponded(questionList[currentSequence - 1].id);
        toggleButtonLoading(false);
      }

      setMaxSequence(maxSequence - 1);
      submitChallenge();
      e.target.textContent = "Finish";

    }
  };

  const handleSubmit = async (e) => {
    // Here we have to use post api to post user response
    // And once we get response as "data" back we just have to extract userResponse and store it in challengeState
    if (review_mode) {
      moveForward(e);
      return;
    }
    toggleLoading(true);
    if (e.target.textContent.trim() === "Check") {
      toggleButtonLoading(true);

      let step_id = questionList[currentSequence - 1].id;
      let response = challengeResponse.answer;
      const { data } = await API.post(
        `user-data/user-challenge-step/`, {
        challenge_step_id: step_id,
        mcq_response: response,
        goal_id
      }
      );
      setResponse(data);
      setSnippetShow(true);
      if (data?.isCorrect) {
        setBgColor("#0ACC81");
        setMessage("CORRECT ANSWER");
      } else {
        setBgColor("#CC3414");
        setMessage("INCORRECT ANSWER");
      }
      challengeState[currentSequence - 1] = {
        id: questionList[currentSequence - 1].id,
        challenge_step_id: data?.data.id,
        correctAnswer: data?.correctAnswer.mcq_options,
        isUserCorrect: data?.isCorrect,
        userResponse: challengeResponse.answer,
        type: questionList[currentSequence - 1].step_type,
      };

      setLastResponded(questionList[currentSequence - 1].id);
      setAnswer("");
      toggleButtonLoading(false);

    } else if (e.target.textContent.trim() !== "Finish") {

      if (questionList[currentSequence - 1]?.step_type === "textbox") {
        toggleButtonLoading(true);
        // We should use Post api if user has not responded
        // If user has responded we have to use Patch api

        if (challengeState[currentSequence - 1]?.userResponse !== undefined) {

          let step_id = challengeState[currentSequence - 1]?.challenge_step_id;
          let response = challengeResponse.answer;
          const { data } = await API.patch(
            `user-data/user-challenge-step/`, {
            user_challenge_step_id: step_id,
            fill_in_the_blanks_response: response,
            goal_id
          });

          challengeState[currentSequence - 1].userResponse = data?.data?.fill_in_the_blanks_response;

        } else {
          let step_id = questionList[currentSequence - 1].id;
          let response = challengeResponse.answer;

          const { data } = await API.post(
            `user-data/user-challenge-step/`, {
            challenge_step_id: step_id,
            fill_in_the_blanks_response: response,
            goal_id
          });
          challengeState[currentSequence - 1] = {
            id: questionList[currentSequence - 1].id,
            challenge_step_id: data?.data.id,
            correctAnswer: questionList[currentSequence - 1].step_display,
            isUserCorrect: true,
            userResponse: data?.data.fill_in_the_blanks_response,
            type: questionList[currentSequence - 1].step_type,
          };
        }

        setLastResponded(questionList[currentSequence - 1].id);
        setAnswer("");
        toggleButtonLoading(false);

      } else if (questionList[currentSequence - 1]?.step_type === "scale_or_rating") {
        toggleButtonLoading(true);
        // We should use Post api if user has not responded
        // If user has responded we have to use Patch api

        if (challengeState[currentSequence - 1]?.userResponse !== undefined) {

          let step_id = challengeState[currentSequence - 1]?.challenge_step_id;
          let response = Number(challengeResponse.answer);
          const { data } = await API.patch(
            `user-data/user-challenge-step/`, {
            user_challenge_step_id: step_id,
            assesment_response: response,
            goal_id
          });

          challengeState[currentSequence - 1].userResponse = data?.data?.assesment_response;

        } else {
          let step_id = questionList[currentSequence - 1].id;
          let response = Number(challengeResponse.answer);

          const { data } = await API.post(
            `user-data/user-challenge-step/`, {
            challenge_step_id: step_id,
            assesment_response: response,
            goal_id
          });
          challengeState[currentSequence - 1] = {
            id: questionList[currentSequence - 1].id,
            challenge_step_id: data?.data.id,
            correctAnswer: questionList[currentSequence - 1].step_display,
            isUserCorrect: true,
            userResponse: data?.data.assesment_response,
            type: questionList[currentSequence - 1].step_type,
          };
        }

        setLastResponded(questionList[currentSequence - 1].id);
        setAnswer("");
        toggleButtonLoading(false);

      } else if (questionList[currentSequence - 1]?.step_type === "visual_flash_card" ||
        questionList[currentSequence - 1]?.step_type === "reading") {

        if (challengeState[currentSequence - 1]?.userResponse !== undefined) {
          challengeState[currentSequence - 1].userResponse = { answer: "", type: `${questionList[currentSequence - 1]?.step_type}` };
        } else {
          challengeState[currentSequence - 1] = {
            id: questionList[currentSequence - 1].id,
            challenge_step_id: questionList[currentSequence - 1].id,
            correctAnswer: questionList[currentSequence - 1].step_display,
            isUserCorrect: true,
            userResponse: { answer: "", type: `${questionList[currentSequence - 1]?.step_type}` },
            type: questionList[currentSequence - 1].step_type,
          };
        }
        setLastResponded(questionList[currentSequence - 1]?.id);
        setAnswer();
      }
      moveForward(e);
    } else {
      moveForward(e);
    }
  };

  const displayQuestion = (type) => {
    // This function is for displaying question with their proper container
    // If user has responded and stored in userResponse of challengeState we will send them as selected button
    if (type === "mcq") {
      return (
        <MCQ
          buttonLoading={buttonLoading}
          setAnswer={review_mode ? () => { } : setAnswer}
          answer={answer}
          options={question.options}
          id={question.id}
          sendResponse={review_mode ? () => { } : getResponse}
          isUserCorrect={
            response && sequence === currentSequence
              ? response?.isCorrect
              : challengeState[currentSequence - 1]?.isUserCorrect
          }
          correctAnswer={
            response && sequence === currentSequence
              ? response?.correctAnswer.mcq_options
              : challengeState[currentSequence - 1]?.correctAnswer
          }
          selected={challengeState[currentSequence - 1]?.userResponse}
        />
      );
    } else if (type === "textbox") {
      return (
        <FillBlanks
          user_anwer={question.fill_in_the_blanks_response}
          reviewMode={review_mode}
          setAnswer={review_mode ? () => { } : setAnswer}
          answer={answer}
          sendResponse={review_mode ? () => { } : getResponse}
          id={question.id}
          selected={challengeState[currentSequence - 1]?.userResponse}
        />
      );
    } else if (type === "scale_or_rating") {
      return (
        <Scale
          reviewMode={review_mode}
          setAnswer={review_mode ? () => { } : setAnswer}
          answer={answer}
          options={question.options}
          id={question.id}
          sendResponse={review_mode ? () => { } : getResponse}
          selected={challengeState[currentSequence - 1]?.userResponse}
        />);
    } else if (type === "visual_flash_card") {
      return (
        <FlashCard
          image={question?.image}
          id={question?.id}
        />);
    } else if (type === "reading") {
      return (
        <Reading
          image={question?.image}
          id={question?.id}
        />);
    }
  };

  useEffect(() => {
    // This is setting up currentquestion on screen so currentsequene points to current question on screen
    toggleflashCardSnippet(false);
    setQuestion({
      id: questionList[currentSequence - 1]?.id,
      stem: questionList[currentSequence - 1]?.step_display,
      type: questionList[currentSequence - 1]?.step_type,
      options: questionList[currentSequence - 1]?.challenge_steps_components,
      image: questionList[currentSequence - 1]?.image_url,
      fill_in_the_blanks_response: questionList[currentSequence - 1]?.user_challenge_steps?.fill_in_the_blanks_response,
    });
  }, [currentSequence]);

  const getTemplate = () => {
    // This is for downloading template for the challenge
    axios
      .get(`https://content.linear.live/${challengeTemplateUrl}`, {
        responseType: "blob",
      })
      .then((response) => {
        FileDownload(response.data, `${challengeName}.doc`);
      });
  };

  const isDisabled = !(!buttonLoading && (sequence === challenge?.length || question?.type === "reading" || question?.type === "visual_flash_card" ? true : (challengeState[currentSequence - 1] ? challengeState[currentSequence - 1].userResponse : (answer || response))));

  return (
    <div className="w-11/12 sm:w-2/3 flex flex-col gap-4 mx-auto">
      {/* This is for starting screen */}
      {showChallengeDescription && challengeDescription && (
        <div
          className="flex flex-row justify-center items-center pt-20"
        >
          <div style={{ whiteSpace: "pre-wrap" }} >
            <h5 className="text-center ">{challengeDescription}</h5>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              {challengeTemplateUrl && (
                <div className="flex flex-col justify-center items-center">
                  <h6 className="text-center ">
                    If you want to work offline,
                    <button
                      style={{ color: "blue", textDecorationLine: "underline" }}
                      onClick={getTemplate}
                    > download the template</button>
                  </h6>
                  <div className="flex mb-8 mt-2 justify-center">
                  </div>
                </div>
              )}
            </div>

            <div className="p-4 flex flex-row justify-between mx-2 md:mx-32"
              style={{
                position: "fixed",
                right: 0,
                bottom: 0,
                justifyContent: "flex-end",
              }}>
              <button
                onClick={() => {
                  setEnterChallenge(true);
                  setShowChallengeDescription(false);
                }}
                className="text-white px-8 py-4 w-36 rounded-2xl text-xl shadow-lg"
                style={{
                  backgroundColor: buttonLoading ? "grey" : "#003366",
                }}
                disabled={buttonLoading}
              >
                {review_mode? "Review" :"Start"}
              </button>
            </div>
          </div>
        </div>
      )}
      {enterChallenge && (
        <div>
          <div className="flex flex-row justify-center items-center">
            <ProgressBar
              bgcolor={"#ef6c00"}
              completed={parseInt(((sequence - 1) / maxSequence) * 100)}
            />
            <CancelIcon
              className="cursor-pointer"
              onClick={() => {
                setShowQuit(true);
              }}
            />
          </div>
          <div
            className="scroll"
            style={{
              zIndex: 100,
              overflowY: "scroll",
              scrollBehavior: "smooth",
              maxHeight: "65vh",
            }}
          >
            {/* Here we display question and call displayQuestion function */}
            <div ref={challengeBodyStartRef}></div>
            <div style={{ whiteSpace: "pre-wrap" }}>
              {isMobile || question?.stem?.split(" ").length > 20 ? (
                <h6 className="mb-4 text-justify">{question?.stem}</h6>
              ) : (
                <h5 className="text-center">{question?.stem}</h5>
              )}

              <div style={{ marginBottom: "15px" }}>
                {displayQuestion(question.type)}
              </div>
            </div>

            <div className="mb-16 sm:mt-0" ref={challengeBodyEndRef}></div>
          </div>
          <div
            style={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              left: 0,
              display: "flex",
              flexDirection: "column",
              zIndex: response ? 1 : -1,
            }}
          >
            <img
              alt="background_hill" src={background_hill}
              style={{ height: "150px", objectFit: "fit" }}
            />
            <div className="p-4 flex flex-row justify-between mx-2 md:mx-32">
              {/* Here we set the Next, check or finish button */}
              {currentSequence > 1 ? (
                <button
                  onClick={() => {
                    setCurrentSequence((currentSequence) => currentSequence - 1);
                  }}
                  className="text-white px-8 py-4 w-36 rounded-2xl text-xl shadow-lg"
                  style={{
                    backgroundColor: buttonLoading ? "grey" : "#003366",
                  }}
                  disabled={buttonLoading}
                >
                  Back
                </button>
              ) : (
                <div></div>
              )}
              <button
                onClick={handleSubmit}
                disabled={isDisabled}
                className="text-white px-8 py-4 w-36 rounded-2xl text-xl shadow-lg"
                style={{
                  backgroundColor: !isDisabled ? "#003366" : "grey",
                }}
              >
                {buttonLoading
                  ? <CircularProgress size="1.25rem" />
                  : (question?.id === lastResponded
                    || response
                    || questionList[sequence - 1]?.step_type === "textbox"
                    || questionList[sequence - 1]?.step_type === "scale_or_rating"
                    || questionList[sequence - 1]?.step_type === "visual_flash_card"
                    || questionList[sequence - 1]?.step_type === "reading"
                    || (questionList[sequence - 1]?.step_type === "mcq" && mcqHasResponse)
                    || currentSequence < sequence)
                    ? currentSequence === challenge?.length
                      ? "Finish"
                      : "Next"
                    : "Check"}
              </button>
            </div>
          </div>

          {/* This is for quitting challenge in middle */}
          {showQuit && (
            <NewModal
              body={
                <div>
                  <div className="flex flex-row justify-center items-center">
                    <h5>Are you sure to quit?</h5>
                  </div>
                  <div className="text-center">
                    You will lose all the progress of this challenge
                  </div>
                </div>
              }
              footer={
                <div className="flex flex-row justify-center gap-16 items-center">
                  <LinearButton
                    text="Yes"
                    onClick={() => {
                      history.push("/user/dashboard");
                    }}
                  />
                  <LinearButton
                    text="No"
                    onClick={() => {
                      setShowQuit(false);
                    }}
                  />
                </div>
              }
              hasCloseButton={false}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default NewChallengeContainer;