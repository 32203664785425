import React, { useState, useEffect } from "react";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import tip_bulb from "../../../assests/icons/tip_bulb.svg";
import { API } from "../../../api";
import Toast from "../../Toast";

const Tip = () => {
  const [tip, setTip] = useState();
  const [tipSaved, setTipSaved] = useState(false);
  const [showTip, setShowTip] = useState(false);

  useEffect(() => {
    async function checkShowTip() {
      const lastTipTime = localStorage.getItem("last_tip_time");
      if (
        !lastTipTime ||
        new Date(lastTipTime).getDate() - new Date().getDate() <= -1
      ) {
        setShowTip(true);
        localStorage.setItem("last_tip_time", new Date().toISOString());
      }
    }

    checkShowTip();
  }, []);

  useEffect(() => {
    async function getRandomTip() {
      const { data } = await API.get("tip", "random");
      setTip(data.randomTip);
      setTipSaved(data.isSaved);
    }
    if (showTip) {
      getRandomTip();
    }
  }, [showTip]);

  const saveTip = async (tip_id) => {
    const { status } = await API.post(`user-data/save-tip/${tip_id}`);
    if (status === 200) {
      setTipSaved(true);
    }
  };

  const unSaveTip = async (tip_id) => {
    const { status } = await API.post(`user-data/unsave-tip/${tip_id}`);
    if (status === 200) {
      setTipSaved(false);
    }
  };

  return (
    <>
      {tip && showTip && (
        <Toast
          title={"Tip of the day"}
          text={tip?.tip_text}
          actionButtonText={tipSaved ? "Saved" : "Save Tip"}
          actionButtonFunction={tipSaved ? () => {} : () => saveTip(tip.id)}
        />
      )}
    </>
    // <div>
    //   {tip && (
    //     <div
    //       className="h-16 px-4 items-center flex flex-row justify-between body-medium-1"
    //       style={{
    //         color: "#2288ee",
    //         backgroundColor: "#E9F3FD",
    //         borderBottom: "solid 3px",
    //         borderBottomColor: "#2288ee",
    //       }}
    //     >
    //       <div className="flex flex-row gap-4 items-center">
    //         <img src={tip_bulb} />
    //         {tip?.tip_text}
    //       </div>
    //       <div
    //         onClick={() => {
    //           if (tipSaved) unSaveTip(tip.id);
    //           else saveTip(tip.id);
    //         }}
    //       >
    //         {tipSaved && tip ? <BookmarkIcon /> : <BookmarkBorderIcon />}
    //       </div>
    //     </div>
    //   )}
    // </div>
  );
};

export default Tip;
