import React, { useEffect, useState } from "react";
import useScreenSize from "../../hooks/useScreenSize";

export default function Table({ header, body }) {
  const { isMobile } = useScreenSize();
  const [tableHeader, setTableHeader] = useState(header);
  const [tableBody, setTableBody] = useState(body);

  useEffect(() => {
    setTableHeader(header);
    setTableBody(body);
  }, [header, body]);

  if (isMobile) return null;

  return (
    <table className="w-full rounded-lg shadow">
      <thead className="bg-gray-50 border-b-2 border-gray-200">
        <tr>
          {tableHeader?.map((header, index) => {
            return (
              <th
                key={index}
                className="p-3 text-sm font-semibold tracking-wider text-left"
              >
                {header}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-100 whitespace-nowrap">
        {tableBody?.map((data, index) => {
          return (
            <tr key={index}>
              {data?.map((cell, index) => {
                return (
                  <td key={index} className="p-3 text-sm text-gray-700">
                    {cell}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
