import React, { useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { API } from "../../../../api";
import Alert from "@material-ui/lab/Alert";

const ACTIVITY_TYPES = [
  { text: "", value: "" },
  { text: "Learn", value: "learn" },
  { text: "Revise", value: "revise" },
];

const AddActivity = () => {
  const [subtopicList, setSubtopicList] = useState();
  const [activityData, setActivityData] = useState({
    code: "",
    name: "",
    description: "",
    type: "learn",
    reattempt_after_days: "",
    same_day_bonus: "",
    same_day_all_correct_bonus: "",
    all_correct_bonus: "",
  });

  useEffect(() => {
    async function getSubtopic() {
      const { data } = await API.get("subtopic", "all");
      setSubtopicList(data);
    }
    getSubtopic();
  }, []);

  const [inProgress, toggleProgress] = useState(false);
  const [alertVisible, toggleAlertVisibility] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const _handleChange = (e) => {
    setActivityData({ ...activityData, [e.target.name]: e.target.value });
    toggleAlertVisibility(false);
  };

  const _handleSubmit = async (e) => {
    e.preventDefault();
    try {
      toggleProgress(true);
      const { data, status } = await API.post("activity/add", activityData);
      toggleAlertVisibility(true);
      toggleProgress(false);
      if (status === 200) {
        setAlertMessage("Activity has been successfully added");
        setAlertSeverity("info");
      } else {
        setAlertMessage(data.error ? data.error.message : "Error Occured");
        setAlertSeverity(data.error ? "error" : "info");
      }
    } catch (err) {
      setAlertMessage("An error occured. Please try again later.");
      setAlertSeverity("error");
      toggleAlertVisibility(true);
      toggleProgress(false);
    }
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="shadow-xl p-36">
        <div
          style={{ marginTop: "10%", display: "flex", flexDirection: "column" }}
        >
          <h5>Add a new activity</h5>
          <TextField
            id="outlined-basic"
            label="Code"
            variant="outlined"
            name="code"
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            name="name"
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="outlined-basic"
            label="Description"
            variant="outlined"
            name="description"
            multiline={true}
            rows={5}
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px", lineHeight: 1.2 } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="outlined-basic"
            label="Same day all correct bonus"
            variant="outlined"
            name="same_day_all_correct_bonus"
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="outlined-basic"
            label="Same day bonus"
            variant="outlined"
            name="same_day_bonus"
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="outlined-basic"
            label="All correct bonus"
            variant="outlined"
            name="all_correct_bonus"
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="outlined-basic"
            label="Reattempt after days"
            variant="outlined"
            name="reattempt_after_days"
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <div>
            <InputLabel
              style={{
                fontFamily: "ClanOT-NarrowNews",
                fontSize: "20px",
                marginBottom: "20px",
              }}
            >
              Select Activity Type
            </InputLabel>
            <select onChange={_handleChange} className="p-4" name="type">
              {ACTIVITY_TYPES?.map((type, index) => {
                return (
                  <option value={type.value} key={index}>
                    {type.text}
                  </option>
                );
              })}
            </select>
          </div>
          <InputLabel
            style={{
              fontFamily: "ClanOT-NarrowNews",
              fontSize: "20px",
              marginBottom: "20px",
            }}
          >
            Select Subtopic
          </InputLabel>
          <select className="p-4 mb-4">
            {subtopicList?.map((subtopic, index) => {
              return <option key={index}>{subtopic.name}</option>;
            })}
          </select>
          <Button
            color="primary"
            variant="contained"
            onClick={_handleSubmit}
            style={{ padding: "20px" }}
          >
            {inProgress ? <CircularProgress /> : "Add Activity"}
          </Button>
          {alertVisible && (
            <Alert style={{ marginTop: "1.5rem" }} severity={alertSeverity}>
              {alertMessage}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddActivity;
