import React, { useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import ArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";

export default function Paginator({
  pageContents,
  totalLength,
  setUpperRange,
  setLowerRange,
}) {
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(pageContents);
  const handlePagination = (pageNumber) => {
    setSelectedPageNumber(pageNumber);
    setLowerRange(pageSize * (pageNumber - 1));
    setUpperRange(pageSize * pageNumber);
  };
  return (
    <div className="flex flex-row items-center gap-2" onClick={() => {}}>
      <div
        className="px-3 py-2 font-semibold text-white"
        style={{
          color: selectedPageNumber > 1 ? "var(--blue-de-france)" : "#ccc",
        }}
        onClick={() => {
          if (selectedPageNumber > 1) handlePagination(selectedPageNumber - 1);
        }}
      >
        <ArrowLeftIcon style={{ fontSize: 40 }} />
      </div>
      {selectedPageNumber > 1 && (
        <div
          className="px-4 py-2 rounded-full font-semibold text-xl text-white"
          style={{ backgroundColor: "var(--blue-de-france)" }}
          onClick={() => handlePagination(selectedPageNumber - 1)}
        >
          {selectedPageNumber - 1}
        </div>
      )}
      <div
        className="px-4 py-2 bg-blue-500 rounded-full font-semibold text-xl text-white"
        style={{ backgroundColor: "var(--prussian-blue)" }}
        onClick={() => handlePagination(selectedPageNumber)}
      >
        {selectedPageNumber}
      </div>
      {pageSize * selectedPageNumber < totalLength && (
        <div
          style={{ backgroundColor: "var(--blue-de-france)" }}
          className="px-4 py-2 bg-blue-300 rounded-full font-semibold text-xl text-white"
          onClick={() => handlePagination(selectedPageNumber + 1)}
        >
          {selectedPageNumber + 1}
        </div>
      )}
      <div
        className="px-3 py-2 font-semibold text-white"
        style={{
          color:
            pageSize * selectedPageNumber < totalLength
              ? "var(--blue-de-france)"
              : "#ccc",
        }}
        onClick={() => {
          if (pageSize * selectedPageNumber < totalLength)
            handlePagination(selectedPageNumber + 1);
        }}
      >
        <ArrowRightIcon style={{ fontSize: 40 }} />
      </div>
    </div>
  );
}
