import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import { API } from "../../../api";

export default function ProgramToggle(props) {
  const [enrolledPrograms, setEnrolledPrograms] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, status } = await API.get("goal", "enrolled-goals");

        if (status === 200) {
          if (data.length === 0) return;
          const temp = [{ programName: "All Programs", programID: "all" }];
          for (const datum of data) {
            const program = datum.goal?.program;
            if (!datum.quitted) {
              temp.push({
                programName: program.name,
                programID: program.id,
              });
            }
          }
          setEnrolledPrograms(temp);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (enrolledPrograms.length === 0) return;
    props.setSelectedProgramID(enrolledPrograms[index]?.programID);
  }, [index]);

  return (
    <div className="flex flex-row justify-center">
      {/* <select
        onChange={(e) => props.setSelectedProgramID(e.target.value)}
        className="input-box body-small-1"
      >
        <option value="all" selected>
          All Programs
        </option>
        {enrolledPrograms?.map((option, index) => {
          return (
            <option value={option.program_id} key={index}>
              {option.program?.name}
            </option>
          );
        })}
      </select> */}
      <div className="flex flex-row justify-center items-center gap-4">
        <div
          onClick={(e) => {
            if (index === 0) return;
            setIndex((index) => index - 1);
          }}
          className="cursor-pointer"
        >
          <ArrowBackIcon style={{ color: index > 0 ? "black" : "gray" }} />
        </div>
        <div className="flex justify-center text-xl title-today">
          {enrolledPrograms[index]?.programName}
        </div>
        <div
          onClick={() => {
            if (enrolledPrograms.length - 1 === index) return;
            setIndex((index) => index + 1);
          }}
          className="cursor-pointer"
        >
          <ArrowForwardIcon
            style={{
              color: enrolledPrograms.length - 1 !== index ? "black" : "gray",
            }}
          />
        </div>
      </div>
    </div>
  );
}
