import React, { useEffect, useState } from "react";
import { API } from "../../../api";

const AdminList = () => {
  const [adminList, setAdminList] = useState();

  useEffect(() => {
    async function getAdminList() {
      const { data } = await API.get("admin", "list");
      setAdminList(data);
    }
    getAdminList();
  }, []);

  return (
    <div>
      {adminList?.map((admin, index) => {
        return (
          <div key={index} className="flex flex-row gap-8">
            <div>
              {admin.first_name} {admin.last_name}
            </div>
            <div>{admin.email}</div>
          </div>
        );
      })}
    </div>
  );
};

export default AdminList;
