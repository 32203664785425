import React, { useEffect, useState } from "react";
import TeamAdmin from "../../../Team/Admin";
import TeamLeaderboard from "../../../Team/Leaderboard";
import SavedTips from "../../../UserJournal/Tips";
import Achievements from "../../Achievment";
import UserProfile from "../../PersonaDetails";
import Settings from "../../Settings";
import { useHistory } from "react-router-dom";
import HomeIcon from "@material-ui/icons/HomeOutlined";
// import "./index.css";

import profile from "../../../../assests/icons/profile/profile.svg";
import achievements from "../../../../assests/icons/profile/achievements.svg";
import team from "../../../../assests/icons/profile/team.svg";
import journal from "../../../../assests/icons/profile/journal.svg";
import admin from "../../../../assests/icons/profile/admin.svg";
import settings from "../../../../assests/icons/profile/settings.svg";

import selected_profile from "../../../../assests/icons/profile/selected_profile.svg";
import selected_achievements from "../../../../assests/icons/profile/selected_achievements.svg";
import selected_team from "../../../../assests/icons/profile/selected_team.svg";
import selected_journal from "../../../../assests/icons/profile/selected_journal.svg";
import selected_admin from "../../../../assests/icons/profile/selected_admin.svg";
import selected_settings from "../../../../assests/icons/profile/selected_settings.svg";
import Todos from "../../../UserJournal/Todos";
import UserChallenge from "../../../UserJournal/Challenge";
import Notes from "../../../UserJournal/Notes";
import NotificationIcon from "@mui/icons-material/NotificationsOutlined";
import Notifications from "../../../../components/DashboardComponents/Notifications";
import TeamIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ProfileIcon from "@mui/icons-material/PersonOutlineOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";

const MENU_OPTIONS = [
  {
    index: 0,
    text: "Profile",
    value: "profile",
    img: profile,
    selected_img: selected_profile,
    icon: <ProfileIcon />,
  },
  {
    text: "Settings",
    value: "settings",
    img: settings,
    selected_img: selected_settings,
    icon: <SettingsIcon />,
  },

  {
    index: 2,
    text: "Home",
    value: "home",
    // img: <D />,
    // selected_img: selected_profile,
    icon: <HomeIcon />,
  },
  {
    text: "Notifications",
    value: "notifications",
    // img: team,
    // selected_img: selected_team,
    icon: <NotificationIcon />,
  },
  {
    text: "Team",
    value: "admin",
    img: admin,
    selected_img: selected_admin,
    icon: <TeamIcon />,
  },
];

const MENU_INDEX = [
  "profile",
  "achievements",
  "journal",
  "team",
  "settings",
  "admin",
];

export default function ProfileDashboardMobile(props) {
  const [component, setComponent] = useState("profile");
  const [selectedButton, setSelectedButton] = useState(0);
  const history = useHistory();

  const displayComponent = () => {
    if (component === "profile") {
      return <UserProfile />;
    } else if (component === "notifications") {
      return <Notifications />;
    } else if (component === "admin") {
      return <TeamAdmin />;
    } else if (component === "home") {
      history.push("/user/dashboard");
      return null;
    } else if (component === "settings") {
      return <Settings />;
    }
  };

  const handleNavBarStyle = (index) => {
    if (index === selectedButton) {
      return {
        backgroundColor: "#E9F3FD",
        color: "#2288EE",
        borderRadius: "10px",
      };
    }
    if (index === 2) {
      return {
        height: 55,
        width: 130,
        marginLeft: "4px",
        marginRight: "4px",
        paddingTop: 5,
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        backgroundColor: "var(--prussian-blue)",
        borderRadius: "50%",
      };
    }
  };

  return (
    <div>
      <div>{displayComponent()}</div>
      <div className="w-full mb-24">
        <section className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow">
          <div className="flex justify-between p-4">
            {MENU_OPTIONS.map((option, index) => {
              return (
                <div
                  key={index}
                  className="w-full justify-center inline-block text-center pt-2 pb-1"
                  onClick={() => {
                    setComponent(option.value);
                    setSelectedButton(index);
                  }}
                  style={handleNavBarStyle(index)}
                >
                  <div className="flex flex-col items-center justify-center">
                    {option.icon}
                    {/* <img
                      src={
                        selectedButton === index && index !== 2 && index !== 3
                          ? option.selected_img
                          : option.img
                      }
                    />
                    {index === 2 && <HomeIcon />}
                    {index === 3 && <NotificationIcon fontSize="small" />} */}
                    <span className="block text-xs">{option.text}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
}
