import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorPage from "../404RedirectPage";
import ActivityComplete from "./ActivityComplete";
import AddActivity from "./AddActivity";
import AddQuestions from "./AddQuestions";
import GetActivity from "./GetActivity";
import StrapiGetActivity from "./StrapiGetActivity";

import StrapiReviewActivity from "./StrapiGetActivity/CompletedActivity";
import NewGetActivity from "./StrapiGetActivity/NewGetActivity";

//Get Activity replaced by strapi get activity until complete testing
const ActivityRouter = () => {
  return (
    <Switch>
      {/* <Route path="/activity/add" component={AddActivity} /> */}
      {/* <Route path="/activity/add-question" component={AddQuestions} /> */}
      {/* <Route path="/activity/id/:activity_id" component={StrapiGetActivity} /> */}
      <Route path="/activity/id/:activity_id" component={NewGetActivity} />
      <Route
        path="/activity/review/:activity_id"
        component={StrapiReviewActivity}
      />
      <Route path="/activity/complete" component={ActivityComplete} />
      {/* <Route path="/activity/strapi/:activity_id" component={null} /> */}
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default ActivityRouter;
