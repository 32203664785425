import React, { useState } from "react";
import NewModal from "../../../components/NewModal";
import LinearButton from "../../../components/Button";
import LinearTextBox from "../../../components/TextBox";
import "./index.css";

export default function AddOrganizationModal({
  country,
  msg,
  onClose = () => {},
  addOrg,
  orgCountry,
  orgName,
  orgOwnerEmail,
  msgColor,
}) {
  const [message, setMessage] = useState(msg);
  const [organizationName, setOrganizationName] = useState(orgName);
  const [organizationCountry, setOrganizationCountry] = useState(orgCountry);
  const [organizationOwnerEmail, setOrganizationOwnerEmail] =
    useState(orgOwnerEmail);
  const heading = <div className="mx-16">Add new Organization</div>;

  const body = (
    <div>
      <div className="py-4 px-8 rounded-xl shadow-lg flex flex-col gap-4 bg-white">
        <LinearTextBox
          name="name"
          onChange={(e) => {
            setOrganizationName(e.target.value);
            setMessage("");
          }}
          label="Organization name"
          placeholder="Enter Organization Name Here"
        />
        <LinearTextBox
          name="email"
          onChange={(e) => {
            setOrganizationOwnerEmail(e.target.value);
            setMessage("");
          }}
          label="Organization Owner Email"
          placeholder="Enter Organization Owner Email Here"
        />

        <div className="flex flex-col w-full sm:w-2/5">
          <label className="body-small-1">Country</label>
          <select
            onChange={(e) => {
              setOrganizationCountry(e.target.value);
              setMessage("");
            }}
            name="country"
            className="input-box body-small-1"
          >
            {country.map((option, index) => {
              return (
                <option value={option.value} key={index}>
                  {option.text}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );

  const footer = (
    <div className="flex flex-col justify-center gap-4 items-center mx-16">
      <LinearButton
        text="Add Org"
        onClick={() => {
          addOrg(organizationName, organizationCountry, organizationOwnerEmail);
        }}
      />
      <h6 style={{ color: `${msgColor}` }}>{msg}</h6>
    </div>
  );

  return (
    <NewModal heading={heading} body={body} footer={footer} onClose={onClose} />
  );
}
