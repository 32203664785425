import React, { useEffect, useState } from "react";
import feedback_star_fill from "../../../assests/icons/feedback_star_fill.svg";
import drill from "../../../assests/icons/drill.svg";
import daily_challenge from "../../../assests/icons/daily_challenge.svg";
import day_challenge from "../../../assests/icons/day_challenge.svg";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import "./index.css";
import useSWR from "swr";
import Paginator from "../../../components/Paginator";

const PAGE_SIZE = 5;
const UserChallenge = () => {
  const { data: challengeList } = useSWR("user-data/completed-challenges");
  // const [challengeList, setChallengeList] = useState();
  const [challengeAccordion, setChallengeAccordion] = useState([]);
  const [lowerRange, setLowerRange] = useState(0);
  const [upperRange, setUpperRange] = useState(PAGE_SIZE);

  useEffect(() => {
    // async function getchallenges() {
    // const { data } = await API.get("user-data", "completed-challenges");
    // setChallengeList(data);
    setChallengeAccordion(Array(challengeList?.length).fill(false));
    // }
    // getchallenges();
  }, [challengeList]);
  return (
    <div
      className="flex flex-col items-center"
      style={{
        overflowY: "scroll",
        scrollBehavior: "smooth",
        maxHeight: "70vh",
      }}
    >
      <div>
        <Paginator
          setUpperRange={setUpperRange}
          setLowerRange={setLowerRange}
          pageContents={PAGE_SIZE}
          totalLength={challengeList?.length}
        />
      </div>
      <div className="self-stretch">
        {challengeList
          ?.slice(lowerRange, upperRange)
          ?.map((challenge, index) => {
            return (
              <div key={index} className="mx-4 border-b-2 py-4">
                <div
                  className="flex flex-row justify-between items-center flex-wrap mb-4"
                  onClick={() => {
                    const temp = [...challengeAccordion];
                    temp[index] = !temp[index];
                    setChallengeAccordion([...temp]);
                  }}
                >
                  <div className="flex flex-row items-center justify-center gap-4">
                    <img
                      alt = "challenge_type"
                      src={
                        challenge.challenge?.type === "daily"
                          ? day_challenge
                          : challenge.challenge?.type === "challenge"
                          ? daily_challenge
                          : drill
                      }
                      style={{ width: "1.25rem" }}
                    />
                    <h5>{challenge?.challenge?.name}</h5>
                    <div className="flex flex-row justify-end">
                      {challengeAccordion[index] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    {/* {challengeAccordion[index] ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )} */}
                    <div className="ml-10 body-medium-1 mt-5 sm:mt-0 sm:body-extra-small-1">
                      {new Date(challenge.completed_date).getDate()} /{" "}
                      {new Date(challenge.completed_date).getMonth() + 1} /{" "}
                      {new Date(challenge.completed_date).getFullYear()}
                    </div>
                  </div>
                </div>
                <div
                  className="flex flex-row gap-8 flex-wrap challenge-container"
                  style={{
                    display: challengeAccordion[index] ? "flex" : "none",
                  }}
                >
                  <div className="flex flex-row items-center gap-2">
                    {challenge.user_response}
                    <img alt="feedback_star" src={feedback_star_fill} style={{ width: "1rem" }} />
                  </div>
                  <div
                    className="overflow-hidden body-medium-1"
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {challenge.user_feedback}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UserChallenge;
