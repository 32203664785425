import React, { useRef, useState } from "react";
import { API } from "../../../api";
import LinearButton from "../../../components/Button";
import NewModal from "../../../components/NewModal";
import LinearTextBox from "../../../components/TextBox";
import CheckIcon from "@mui/icons-material/CheckCircleRounded";
import { useHistory } from "react-router-dom";

export default function CreateTeam() {
  const [screenState, setScreenState] = useState(0);
  const emailRef = useRef();
  const [teamName, setTeamName] = useState("");
  const [teamNameErrorMsg, setTeamNameErrorMsg] = useState("");
  const [teamDescription, setTeamDescription] = useState("");
  const [teamDescriptionErrorMsg, setTeamDescriptionErrorMsg] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [message, setMessage] = useState("");
  const history = useHistory();

  const _handleScreenStateChange = () => {
    if (teamName === "" || !teamName) {
      setTeamNameErrorMsg("Team name cannot be empty");
      return;
    }
    if (teamDescription === "" || !teamDescription) {
      setTeamDescriptionErrorMsg("Team name cannot be empty");
      return;
    }
    setScreenState(1);
  };

  const _handleAddEmail = () => {
    setMessage("");
    setEmailList([...emailList, emailRef.current.value]);
    emailRef.current.value = "";
  };

  const _handleSubmit = async () => {
    const { status } = await API.post("group/create", {
      name: teamName,
      description: teamDescription,
      user_email_list: emailList,
    });
    if (status === 200) {
      setShowSuccessModal(true);
    } else setMessage("An error occured");
  };

  const _handleKeyDown = (event) => {
    setMessage("");
    if (event.key === "Enter") _handleAddEmail();
  };

  if (screenState === 0)
    return (
      <div
        className="h-screen flex flex-row justify-center items-center"
        style={{ backgroundColor: "var(--blue-de-france-10)" }}
      >
        <div className="bg-white p-4 px-8 rounded-xl shadow-lg">
          <h4 className="text-center my-4">Let's get started</h4>
          <div>
            <LinearTextBox
              errorMessage={teamNameErrorMsg}
              label="Team Name"
              placeholder="Enter team name"
              onChange={(e) => {
                setTeamNameErrorMsg("");
                setTeamName(e.target.value);
              }}
            />
          </div>
          <div className="mt-4">
            <LinearTextBox
              errorMessage={teamDescriptionErrorMsg}
              label="Team Description"
              placeholder="Enter team description"
              onChange={(e) => {
                setTeamDescriptionErrorMsg("");
                setTeamDescription(e.target.value);
              }}
            />
          </div>
          <div className="mt-4 flex flex-row justify-center">
            <LinearButton text="Next" onClick={_handleScreenStateChange} />
          </div>
        </div>
      </div>
    );
  else if (screenState === 1)
    return (
      <div className="h-screen">
        <h4 className="text-center my-4">
          Enter email address of team members
        </h4>
        <div className="my-4 flex flex-col sm:flex-row justify-center items-center gap-2 sm:gap-4 flex-wrap">
          <LinearTextBox
            label=""
            inputRef={emailRef}
            onKeyDown={_handleKeyDown}
            placeholder="Enter team member email address"
          />
          <LinearButton text="Add email" onClick={_handleAddEmail} />
        </div>
        <div
          className="px-4 py-2 mb-4"
          style={{
            backgroundColor: "#E9F3FD",
          }}
        >
          <div className="flex flex-row mx-4 my-2 items-center">
            <h6 className="w-1/5">Team Members</h6>
          </div>
        </div>
        {emailList?.map((email, index) => {
          return (
            <div
              key={index}
              className="flex flex-row justify-between px-4 py-2 mx-4 items-center border-b-2"
            >
              <div className="body-small-1 text-center">{email}</div>
              <div>
                <LinearButton
                  text="Remove"
                  onClick={() => {
                    const temp = [...emailList];
                    temp.splice(index, 1);
                    setEmailList(temp);
                  }}
                />
              </div>
            </div>
          );
        })}
        <div className="flex flex-row justify-center mt-4">
          <LinearButton
            size="extra-large"
            text="Create Team"
            onClick={_handleSubmit}
          />
        </div>
        <div className="flex flex-row justify-center mt-4">{message}</div>
        {showSuccessModal && (
          <NewModal
            heading="Team created successfully"
            hasCloseButton={false}
            body={
              <div className="flex flex-col justify-center items-center gap-4">
                <CheckIcon style={{ color: "var(--success)", fontSize: 70 }} />
                Go to Team section in the profile page to view your team!
                <LinearButton
                  text="Go to Team"
                  onClick={() => {
                    history.push("/profile", { component: "admin" });
                  }}
                />
              </div>
            }
          />
        )}
      </div>
    );
}
