import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { API } from "../../../../api";
import "./index.css";
import { useHistory } from "react-router-dom";

// Components
import MCQ from "./MCQ";
import FillBlanks from "./FillBlanks";
import ProgressBar from "../../../../components/Activity/ProgressBar";
import Order from "./Order";
import FlashCard from "../../../ChallengeNew/FlashCard";


const AdminPreviewActivity = (props) => {
  const [activity, setActivity] = useState();
  const [questionList, setQuestionList] = useState([]);
  const [sequence, setSequence] = useState(0);
  const [maxSequence, setMaxSequence] = useState(1);
  const [bgcolor, setBgColor] = useState("#FFFFFF");
  const [message, setMessage] = useState("");
  const [snippetShow, setSnippetShow] = useState(false);
  const [response, setResponse] = useState();

  const history = useHistory();
  const [questionData, setQuestionData] = useState({
    id: "",
    stem: "",
    options: [],
    comment: "",
    type: "",
    question_image_url: ""
  });

  const { activity_id } = props.match.params;

  function updateData() {
    setQuestionData({
      stem: questionList[sequence - 1]?.question?.stem,
      comment: questionList[sequence - 1]?.question?.comment,
      options: questionList[sequence - 1]?.question?.components,
      type: questionList[sequence - 1]?.question?.type,
      id: questionList[sequence - 1]?.question?.id,
      question_image_url: questionList[sequence - 1]?.question?.question_image_url
    });
  }

  useEffect(() => {
    async function fetchData() {
      const { data } = await API.get(
        "strapi/get-questions",
        `null/${activity_id}`
      );
      setQuestionList(data);
      setActivity(data);
      setMaxSequence(data.length);
      setBgColor("#0ACC81");
      setSnippetShow(true);
      setMessage("CORRECT ANSWER");
      setSequence(1);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setBgColor("#0ACC81");
    setSnippetShow(true);
    setMessage("CORRECT ANSWER");
    updateData();
  }, [sequence]);

  const _nextQuestion = async (id) => {
    if (sequence < maxSequence) {
      setSequence(sequence + 1);
    }
  };

  const handleSubmit = async (e) => {
    if (e.target.innerHTML === "Next" || e.target.innerHTML === "Check") {
      setResponse();
      _nextQuestion();
    } else if (e.target.innerHTML === "Finish") {
      setMaxSequence(maxSequence - 1);
      e.target.innerHTML = "Finish";
      history.push("/admin/dashboard");
    }
  };

  const displayQuestion = (type) => {
    if (type === "mcq")
      return (
        <MCQ
          options={questionData.options}
          id={questionData.id}
          isUserCorrect={response?.isUserCorrect}
          correctAnswer={response?.correctAnswer}
        />
      );
    else if (type === "fill_blanks") {
      return <FillBlanks />;
    } else if (type === "order") {
      return <Order id={questionData.id} options={questionData.options} />;
    } else if (type === "flashcard") {
      return (
				<>
					<FlashCard
						image={questionData.question_image_url}
						id={questionData.id}
					/>
				</>
			);

    }
    // } else if (type === "match") {
    //   const optionList = activity.questions[sequence - 1].options;
    //   const optionKeys = optionList.filter((option) => {
    //     return option.is_key;
    //   });
    //   const optionValues = optionList.filter((option) => {
    //     return !option.is_key;
    //   });
    //   return (
    //     <Match
    //       id={activity.questions[sequence - 1].id}
    //       optionKeys={optionKeys}
    //       optionValues={optionValues}
    //       sendResponse={getResponse}
    //     />
    //   );
    // }
  };

  // const _handleShow = () => {
  //   setReadingShow(!readingShow);
  // };

  if (!activity || !questionList) return null;
  //if (!goal_id) return null;

  // if (readingShow) {
  //   return <ActivityReading reading={reading} handleShow={_handleShow} />;
  // }

  return (
    <>
      <Container maxWidth="md">
        <div style={{ marginTop: "50px" }}>
          <ProgressBar
            bgcolor={"#ef6c00"}
            completed={parseInt(((sequence - 1) / maxSequence) * 100)}
          />
          {questionList[sequence - 1]?.question?.type === "flashcard" ? (
            <h5>{questionData.stem}</h5>
          ) : (
            <h5 className="text-center">{questionData.stem}</h5>
          )}

          <div style={{ marginBottom: "25px" }}>
            {displayQuestion(questionData.type)}
          </div>
        </div>
        {questionData.type === "flashcard" && (
          <div> {questionData.comment}</div>
        )}
        <div
          className="body-medium-2 text-center z-10"
          style={{
            display:
              snippetShow && questionData.type !== "flashcard"
                ? "block"
                : "none",
            background: "#FDF9F0",
            overflow: "auto",
            height: "auto",
          }}
        >
          <div
            className="quarter-circle-top-right z-10"
            style={{ backgroundColor: bgcolor }}
          ></div>
          <div className="subtitle text-center my-4">{message}</div>
          <div className="text-center px-4">{questionData.comment}</div>
          <div
            className="quarter-circle-bottom-left"
            style={{ backgroundColor: bgcolor }}
          ></div>
        </div>
        <div className="flex flex-row justify-between gap-16 my-8 z-10">
          <div className="text-2xl px-8 py-4 text-white">{message}</div>
          <div>
            <button
              onClick={handleSubmit}
              className="text-white button-large px-8 py-4 rounded-2xl text-2xl shadow-lg right-10 lg:absolute"
              style={{
                backgroundColor: " #003366",
              }}
            >
              {/* {response ||
              questionList[sequence - 1]?.question?.type === "flashcard"
                ? sequence === activity.length
                  ? "Finish"
                  : "Next"
                : "Check"} */}
              {true
                ? sequence === activity.length
                  ? "Finish"
                  : "Next"
                : "Check"}
            </button>
          </div>
        </div>
        {/* {snippetShow && (
          <NewModal
            heading={
              <div className="text-2xl px-8 py-4 flex flex-row justify-center">
                <div>{message}</div>
              </div>
            }
            body={<div className="p-4 mx-8">{questionData.comment}</div>}
            onClose={() => setSnippetShow(false)}
          />
        )} */}
      </Container>
    </>
  );
};

export default AdminPreviewActivity;
