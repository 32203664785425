import React, { useEffect, useState } from "react";
import TeamAdmin from "../../Team/Admin";
import TeamLeaderboard from "../../Team/Leaderboard";
import SavedTips from "../../UserJournal/Tips";
import Achievements from "../Achievment";
import UserProfile from "../PersonaDetails";
import Settings from "../Settings";
import { useHistory } from "react-router-dom";
import "./index.css";

import profile from "../../../assests/icons/profile/profile.svg";
import achievements from "../../../assests/icons/profile/achievements.svg";
import team from "../../../assests/icons/profile/team.svg";
import journal from "../../../assests/icons/profile/journal.svg";
import report from "../../../assests/icons/profile/report.svg";
import admin from "../../../assests/icons/profile/admin.svg";
import settings from "../../../assests/icons/profile/settings.svg";

import selected_profile from "../../../assests/icons/profile/selected_profile.svg";
import selected_achievements from "../../../assests/icons/profile/selected_achievements.svg";
import selected_team from "../../../assests/icons/profile/selected_team.svg";
import selected_journal from "../../../assests/icons/profile/selected_journal.svg";
import selected_admin from "../../../assests/icons/profile/selected_admin.svg";
import selected_settings from "../../../assests/icons/profile/selected_settings.svg";
import FlashCardsJournalComponent from "../../UserJournal/FlashCard";
import UserChallenge from "../../UserJournal/Challenge";
import Notes from "../../UserJournal/Notes";
import ProfileDashboardMobile from "./Mobile";

const ProfileDashboard = (props) => {
  const [component, setComponent] = useState("profile");
  const [journalComponent, setJournalComponent] = useState("tips");
  const [selectedButton, setSelectedButton] = useState(0);
  const [menuOptions, setMenuOptions] = useState([
    {
      index: 0,
      text: "My Profile",
      value: "profile",
      img: profile,
      selected_img: selected_profile,
    },
    {
      index: 1,
      text: "Achievements",
      value: "achievements",
      img: achievements,
      selected_img: selected_achievements,
    },
    {
      index: 2,
      text: "Journal",
      value: "journal",
      img: journal,
      selected_img: selected_journal,
    },
    {
      text: "Leaderboard",
      value: "team",
      img: team,
      selected_img: selected_team,
    },
    {
      text: "Settings",
      value: "settings",
      img: settings,
      selected_img: selected_settings,
    },
    {
      text: "Team",
      value: "admin",
      img: admin,
      selected_img: selected_admin,
    },
  ]);
  const [MENU_INDEX, setMENU_INDEX] = useState([
    "profile",
    "achievements",
    "journal",
    "team",
    "settings",
    "admin",
  ]);

  const displayJournalComponent = () => {
    if (journalComponent === "tips") return <SavedTips />;
    else if (journalComponent === "todo") return <FlashCardsJournalComponent />;
    else if (journalComponent === "challenge") return <UserChallenge />;
    else if (journalComponent === "note") return <Notes />;
  };

  const displayComponent = () => {
    if (component === "profile") {
      return <UserProfile />;
    } else if (component === "team") {
      return <TeamLeaderboard />;
    } else if (component === "admin") {
      return <TeamAdmin />;
    } else if (component === "achievements") {
      return <Achievements />;
    } else if (component === "journal") {
      return (
        <div>
          <div className="flex flex-row gap-16  mx-4 my-4">
            <div
              className="cursor-pointer"
              onClick={() => setJournalComponent("tips")}
              style={
                journalComponent === "tips"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              Tips
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setJournalComponent("todo")}
              style={
                journalComponent === "todo"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              Flashcards
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setJournalComponent("challenge")}
              style={
                journalComponent === "challenge"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              Challenge
            </div>
            <div
              className="cursor-pointer"
              onClick={() => setJournalComponent("note")}
              style={
                journalComponent === "note"
                  ? {
                      color: "var(--blue-de-france)",
                      borderBottom: "1px solid var(--blue-de-france)",
                    }
                  : null
              }
            >
              My notes
            </div>
          </div>
          {displayJournalComponent()}
        </div>
      );
    } else if (component === "settings") {
      return <Settings />;
    }
  };

  useEffect(() => {
    const propsComponent = props.location?.state?.component;
    const propsJournal = props.location?.state?.journalComponent;
    if (propsComponent) {
      setComponent(propsComponent);
      setSelectedButton(MENU_INDEX.indexOf(propsComponent));
    }
    if (propsJournal) setJournalComponent(propsJournal);
  }, [props]);

  if (window.screen.width < 420) {
    return (
      <div>
        <ProfileDashboardMobile />
      </div>
    );
  }

  return (
    <div
      className="min-h-content min-h-screen"
      style={{ backgroundColor: "#F9F9F9" }}
    >
      {/* <div className="mx-4 pt-4">
        <h4>My Account</h4>
      </div> */}
      <div className="flex flex-row">
        <div className="p-4 m-4 bg-white h-full container md:w-1/3 lg:w-1/5">
          <div>
            {menuOptions.map((option, index) => {
              return (
                <div
                  key={index}
                  className="body-small-1 p-4 mb-4 cursor-pointer"
                  onClick={() => {
                    setComponent(option.value);
                    setSelectedButton(index);
                  }}
                  style={
                    index === selectedButton
                      ? {
                          backgroundColor: "#E9F3FD",
                          color: "#2288EE",
                          borderRadius: "10px",
                        }
                      : null
                  }
                >
                  <div className="flex flex-row items-center gap-4">
                    <img
                      src={
                        selectedButton === index
                          ? option.selected_img
                          : option.img
                      }
                    />
                    {option.text}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="p-4 my-4 mr-4 bg-white h-full container overflow-auto">
          {component === "journal" && <div className="mx-4"></div>}
          {displayComponent()}
        </div>
      </div>
    </div>
  );
};

export default ProfileDashboard;
