import React from "react";
import { Switch, Route } from "react-router-dom";
import CompleteChallenge from "./CompleteChallenge";
import GetChallenge from "./GetChallenge";
import UpcomingChallenges from "./UpcomingChallenge";
import NewChallengeContainer from "../ChallengeNew/ChallengeContainer";
import CompleteNewChallenge from "../ChallengeNew/CompleteChallenge";

const ChallengeRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/challenge/id/:challenge_id"
        component={GetChallenge}
      />
      <Route
        exact
        path="/challenge/id/new/:challenge_id"
        component={NewChallengeContainer}
      />
      <Route
        exact
        path="/challenge/complete/:challenge_id"
        component={CompleteChallenge}
      />
      <Route
        exact
        path="/challenge/complete/new/:challenge_id"
        component={CompleteNewChallenge}
      />
      <Route exact path="/challenge/upcoming" component={UpcomingChallenges} />
    </Switch>
  );
};

export default ChallengeRoutes;
