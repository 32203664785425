import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { API } from "../../../api";
import Table from "../../../components/Table";
import { CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ActivityRowBox from "../../../components/DashboardComponents/ActivityRowBox";

const ProgramSchedule = () => {
  const history = useHistory();
  const location = useLocation();

  const goal_id = location.state?.goal_id;
  const staticDay = location.state?.static_day;
  const program_name = location.state?.program_name;

  const { referrer } = location.state || {};
  console.log(program_name);

  const [userSchedule, setUserSchedule] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);

  const goToActivity = ({ activity_id, name }) => {
    history.push(`/activity/id/${activity_id}`, {
      goal_id: goal_id,
      activity_name: name,
    });
  };

  const getActivity = ({ activity_id, completed, day_id, name, enabled }) => {
    return (
      <div>
        <ActivityRowBox
          id={activity_id}
          completed={completed}
          enabled={enabled}
          message={name}
          key={activity_id}
          onClick={() =>
            (enabled || completed) && goToActivity({ activity_id, name })
          }
        />
      </div>
    );
  };

  const goToChallenge = ({ challenge_id, completedDate, completed }) => {
    history.push(`/challenge/id/${challenge_id}`, {
      goal_id: goal_id,
      completedDate,
      completed,
    });
  };

  const getChallenge = ({ challenge_id, completed, day_id, name, enabled }) => {
    return (
      <div>
        <ActivityRowBox
          id={challenge_id}
          completed={completed}
          enabled={enabled}
          message={name}
          key={challenge_id}
          onClick={() =>
            (enabled || completed) &&
            goToChallenge({ challenge_id, date: new Date(), completed })
          }
        />
      </div>
    );
  };

  useEffect(() => {
    if (userSchedule.length === 0) return;

    const tableData = userSchedule.map((schedule) => {
      const dayNumber = schedule[0]?.ssid; // Using ssid for the day number
      var enabled = false;
      if (dayNumber <= staticDay) {
        enabled = true;
      }
      const activity = schedule
        .filter((item) => item.actid)
        .map((item) =>
          getActivity({
            activity_id: item.actid,
            completed: item.comp,
            day_id: item.dayid,
            name: item.name,
            enabled,
          })
        );

      const challenge = schedule
        .filter((item) => item.chid)
        .map((item) =>
          getChallenge({
            challenge_id: item.chid,
            completed: item.comp,
            day_id: item.dayid,
            name: item.name,
            enabled,
          })
        );

      return [dayNumber, activity, challenge];
    });

    setTableHeader(["Day", "Learn", "Challenge"]);
    setTableBody(tableData);
  }, [userSchedule]);

  const getUserSchedule = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await API.get(
        `schedule/entire-user-goal-schedule`,
        goal_id
      );
      // Convert the data object to an array of its values
      const scheduleArray = Object.values(data);
      setUserSchedule(scheduleArray);
    } catch (error) {
      console.error("Failed to fetch user schedule", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserSchedule();
  }, []);

  return (
    <div>
      <div className="flex justify-between h-14 mt-0">
        <div className="text-center my-4 text-gray-500 mt-4 ml-2">
          <button
            onClick={() => {
              history.push(referrer);
            }}
            className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer bg-white text-blue-500 hover:bg-blue-50 transition-all duration-200 rounded"
          >
            <svg
              className="w-6 h-6"
              style={{ color: "#2288EE", fill: "#2288EE" }}
              viewBox="0 0 20 20"
            >
              <ArrowBackIcon />
            </svg>
            <p className="text-gray-800">Back</p>
          </button>
        </div>
        <div className="flex text-center my-4 text-gray-500 mt-8">
          <div className="flex space-x-1 bg-transparent items-center p-2 bg-white">
            <p className="text-gray-800 text-xl">{program_name}</p>
          </div>

          {/* <button
            onClick={() => setShowAddBulkUserModal(true)}
            className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer bg-white text-blue-500 hover:bg-blue-50 transition-all duration-200 rounded"
          >
            <svg
              className="w-6 h-6"
              style={{ color: "#2288EE", fill: "#2288EE" }}
              viewBox="0 0 20 20"
            >
              <AddMultipleUser />
            </svg>
            <p className="text-gray-800">Add multiple users</p>
          </button> */}
        </div>
        <div className="text-center my-4 text-gray-500 mt-0">
          {/* <button
              onClick={handleExport}
              className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer hover:bg-gray-100 hover:text-white transition-all duration-200"
            >
              <svg
                className="w-6 h-6"
                style={{ color: "#2288EE", fill: "#2288EE" }}
                viewBox="0 0 20 20"
              >
                <ExportIcon />
              </svg>
              <p className="text-gray-800">Export</p>
            </button> */}
        </div>
      </div>

      <div className="mx-2 rounded-xl p-2 mt-2">
        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <CircularProgress size={80} />
          </div>
        ) : (
          <Table header={tableHeader} body={tableBody} />
        )}
      </div>
    </div>
  );
};

export default ProgramSchedule;
