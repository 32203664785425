import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import { MONTH_LIST } from "../../../lib/constants";

export default function DayNavigator({
  setDayAdjust,
  dayAdjust,
  setDate,
  date,
}) {
  return (
    <div className="flex flex-row justify-center items-center gap-4">
      <div
        onClick={() => {
          setDayAdjust(dayAdjust - 1);
          setDate(new Date(date.setDate(date.getDate() - 1)));
        }}
        className="cursor-pointer"
      >
        <ArrowBackIcon />
      </div>
      <div className="flex justify-center text-xl title-today">
        {date.getDate() === new Date().getDate()
          ? "Today"
          : date.getDate() + " " + MONTH_LIST[date.getMonth()].slice(0, 3)}
      </div>
      <div
        onClick={() => {
          setDayAdjust(dayAdjust + 1);
          setDate(new Date(date.setDate(date.getDate() + 1)));
        }}
        className="cursor-pointer"
      >
        <ArrowForwardIcon />
      </div>
    </div>
  );
}
