import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import UserGroup from "./UserGroup/UserGroup";
import UserProgram from "./UserProgram/UserProgram";

import reportIcon from "../../../../assests/icons/profile/report.svg";
import GroupIcon from "../../../../assests/icons/administrator/group_icon.svg";

import SelectedGroupIcon from "../../../../assests/icons/administrator/selected_group_icon.svg";
import selectedReportIcon from "../../../../assests/icons/profile/selected_report.svg";

const UserDetailsDashBoard = (props) => {
  const history = useHistory();
  const location = useLocation();
  const userDetails = location.state.rowData;
  const userEmail = userDetails.email;

  const [component, setComponent] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [MENU_OPTIONS, setMENU_OPTIONS] = useState([
    {
      index: 0,
      text: "Programs Enrolled",
      value: "user-program",
      img: reportIcon,
      selected_img: selectedReportIcon,
    },
    {
      index: 1,
      text: "Groups Joined",
      value: "user-group",
      img: GroupIcon,
      selected_img: SelectedGroupIcon,
    },
  ]);
  const [MENU_INDEX, setMENU_INDEX] = useState(["user-program", "user-group"]);

  const displayComponent = () => {
    if (component === "user-program") {
      return <UserProgram email={userEmail} />;
    } else if (component === "user-group") {
      return <UserGroup email={userEmail} />;
    }
  };

  useEffect(() => {
    const propsComponent = props.location?.state?.component;
    if (propsComponent) {
      setComponent(propsComponent);
      setSelectedButton(MENU_INDEX.indexOf(propsComponent));
    } else {
      setComponent(MENU_OPTIONS[0].value);
      setSelectedButton(MENU_INDEX[0]);
    }
  }, [props]);


  return (
    <div
      className="min-h-content min-h-screen"
      style={{ backgroundColor: "#F9F9F9" }}
    >
      <div className="flex flex-row">
        <div className="p-4 m-4 bg-white h-full container w-1/3 lg:w-1/5">
          <div>
            {MENU_OPTIONS.map((option, index) => {
              return (
                <div
                  key={index}
                  className="body-small-1 p-4 mb-4 cursor-pointer"
                  onClick={() => {
                    setComponent(option.value);
                    setSelectedButton(index);
                  }}
                  style={
                    index === selectedButton
                      ? {
                          backgroundColor: "#E9F3FD",
                          color: "#2288EE",
                          borderRadius: "10px",
                        }
                      : null
                  }
                >
                  <div className="flex flex-row items-center gap-4">
                    <img
                      alt="menu_option_img"
                      src={
                        selectedButton === index
                          ? option.selected_img
                          : option.img
                      }
                    />
                    {option.text}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="lg:w-4/5 md:w-2/3 p-4 m-4 bg-white h-full container overflow-auto">
          {displayComponent()}
        </div>
      </div>
    </div>
  );
};

export default UserDetailsDashBoard;
