import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorPage from "../../404RedirectPage";
import AdminAddChallenge from "./Add";
import AdminChallengeList from "./List";
import AdminAllChallenge from "./ListAll";

const AdminChallengeRoutes = () => {
  return (
    <Switch>
      <Route exact path="/admin/challenge/add" component={AdminAddChallenge} />
      <Route
        exact
        path="/admin/challenge/list"
        component={AdminChallengeList}
      />
      <Route exact path="/admin/challenge/all" component={AdminAllChallenge} />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default AdminChallengeRoutes;
