import React from "react";

export default function Comment({ message, comment, snippetShow, bgcolor }) {
  return (
    <div
      className="body-medium-2 z-10 "
      style={{
        display: snippetShow ? "block" : "none",
        background: "#FDF9F0",
        overflow: "auto",
        height: "auto",
      }}
    >
      <div
        className="quarter-circle-top-right z-10"
        style={{
          backgroundColor: bgcolor,
        }}
      ></div>
      <div style={{ whiteSpace: "pre-wrap" }} className="md:pr-24 md:pl-16 ">
        <div className="subtitle text-center my-4 md:pl-10 pl-2">{message}</div>
        <div className="text-center px-4 text-justify">{comment}</div>
      </div>
      <div
        className="quarter-circle-bottom-left"
        style={{ backgroundColor: bgcolor }}
      ></div>
    </div>
  );
}
