import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { API } from "../../../../../api";
import Table from "../../../../../components/Table";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const UserGroup = ({ email }) => {
  const history = useHistory();

  const [groupsJoined, setGroupsJoined] = useState([]);

  const [groupsTableHeader, setGroupsTableHeader] = useState([]);
  const [groupsTableBody, setGroupsTableBody] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const getGroupsJoined = async (userEmail) => {
    setIsLoading(true);
    try {
      const { data } = await API.get("user/all-groups", userEmail);
      setGroupsJoined(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getGroupsJoined(email);
  }, []);

  useEffect(() => {
    const tableData = [];
    groupsJoined &&
      groupsJoined?.map((group, index) => {
        const groupName = group?.groups[0]?.name;
        const startDate = group?.joining_date;
        const joiningDate = startDate?.split("T")[0];
        const status = group?.status;
        const groupAdminName =
          group?.groups[0]?.group_admin?.first_name +
          " " +
          group?.groups[0]?.group_admin?.last_name;
        const element = [groupName, joiningDate, status, groupAdminName];
        tableData.push(element);
      });
    setGroupsTableHeader(["Group Name", "Joined On", "Status", "Group Admin"]);
    setGroupsTableBody(tableData);
  }, [groupsJoined]);

  return (
    <div>
      <nav>
        <div className="text-center my-4 text-gray-500 mt-0">
          <button
            onClick={() => {
              history.push("/administrator", {
                component: "user",
              });
            }}
            className="flex space-x-1 bg-transparent items-center p-2 cursor-pointer bg-white text-blue-500 hover:bg-blue-50 transition-all duration-200 rounded"
          >
            <svg
              className="w-6 h-6"
              style={{ color: "#2288EE", fill: "#2288EE" }}
              viewBox="0 0 20 20"
            >
              <ArrowBackIcon />
            </svg>
            <p className="text-gray-800">Back</p>
          </button>
        </div>
      </nav>
      <main>
        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <div style={{ marginTop: "-20vh" }}>
              <CircularProgress size={70} />
            </div>
          </div>
        ) : groupsJoined.length > 0 ? (
          <div className="mt-2">
            <Table header={groupsTableHeader} body={groupsTableBody} />
          </div>
        ) : (
          <div class="flex justify-center items-start bg-white ">
            <div class="text-center p-8 bg-white-200 rounded-t-lg shadow-md w-full md:w-1/2">
              <p class="text-3xl font-bold text-gray-500 mb-4">
                No Groups Joined
              </p>
              <p class="text-gray-700">
                It looks like user is not part of any program
              </p>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default UserGroup;
