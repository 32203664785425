import { useState } from "react";
import { ReactComponent as DefaultProgramImage } from "../../../assests/images/default_program_productivity_image.svg";
import ProgramModal from "../ProgramModal";
import "./index.css";

const ProgramContainer = ({
  isEnrolled,
  id,
  name = "",
  description = "",
  imageUrl = "",
  type=""
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const programImageStyle = {
    height: "18.0625rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1.25rem 1.25rem 0rem 0rem",
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "100% 100%",
  };

  const onProgramClick = () => {
    setModalVisibility(true);
  };

  const onProgramModalClose = () => {
    setModalVisibility(false);
  };

  let image;
  if (imageUrl) {
    image = <img src={imageUrl} alt="program pic" />;
  } else {
    image = <DefaultProgramImage title="Default Program Image" />;
  }
  return (
    <div>
      {isModalVisible && (
        <ProgramModal
          isEnrolled={isEnrolled}
          name={name}
          description={description}
          image={image}
          id={id}
          onClose={onProgramModalClose}
          type={type}
        />
      )}
      <div className="program-div" onClick={onProgramClick}>
        <div className="program-image" style={programImageStyle}></div>
        <div className="program-name">
          <h5>{name}</h5>
        </div>
      </div>
    </div>
  );
};

export default ProgramContainer;
