import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../../api";

const UpcomingChallenges = () => {
  const [upcomingTasks, setUpcomingTasks] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function getUpcomingTasks() {
      const { data } = await API.get("user-data", "get-scheduled-challenges");
      setUpcomingTasks(data);
    }
    getUpcomingTasks();
  }, []);
  return (
    <div className="p-4">
      <div>
        <h5>All Upcoming Challenges</h5>
      </div>
      <div>
        {upcomingTasks?.map((task, index) => {
          return (
            <div
              className="px-4 py-2 my-2 cursor-pointer"
              style={{
                backgroundColor:
                  index % 3 === 0
                    ? "var(--purple-light)"
                    : index % 2 === 0
                    ? "var(--red-light)"
                    : "var(--green-light)",
                borderRadius: "10px",
                color:
                  index % 3 === 0
                    ? "var(--purple-dark)"
                    : index % 2 === 0
                    ? "var(--red-dark)"
                    : "var(--green-dark)",
              }}
              onClick={() =>
                history.push(`/challenge/complete/${task.challenge.id}`, {
                  user_challenge_id: task.id,
                })
              }
            >
              <div className="body-small-2">{task.challenge?.name}</div>
              <div className="truncate body-extra-small-1">
                {task.challenge?.description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UpcomingChallenges;
