import React, { useEffect, useState } from "react";
import {
  CircularProgress,
} from "@material-ui/core";
import { API } from "../../../api";
import { useHistory } from "react-router-dom";
import LHS from "../../../components/LHS";
import LinearButton from "../../../components/Button";
import LinearTextBox from "../../../components/TextBox";

import "./index.css";
import { validateEmail, validatePassword } from "../../../lib/helpers";

const UserSignUp = (props) => {
  const history = useHistory();
  const [alertMessage, setAlertMessage] = useState("");
  const [inProgress, toggleProgress] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passErrorMsg, setPassErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameErrorMsg, setfirstNameErrorMsg] = useState("");
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState("");
  const [returnURL, setReturnURL] = useState(props.location.state?.return_url);
  const [returnEmail, setReturnEmail] = useState(props.location.state?.email);
  useEffect(()=> {
    if(returnEmail) setEmail(returnEmail);
  }, []);

  const handleEmail = (e) => {
    if (e.target.value === "") {
      setEmailErrorMsg("Email Id is required");
    } else {
      setEmailErrorMsg("");
    }
    setEmail(e.target.value.toLowerCase());
  };

  const handlePassword = (e) => {
    let password = e.target.value;
    if (password === "") {
      setPassErrorMsg("Password is required");
      return;
    }
    if (!validatePassword(password)) {
      setPassErrorMsg(
        "Password must be at least 8 characters with uppercase, numeric, and special characters."
      );
      return;
    }
    setPassErrorMsg("");

    setPassword(e.target.value);
  };

  const handleFirstName = (e) => {
    if (e.target.value === "") {
      setfirstNameErrorMsg("First Name is required");
    } else {
      setfirstNameErrorMsg("");
    }
    setFirstName(e.target.value);
  };

  const handleLastName = (e) => {
    if (e.target.value === "") {
      setLastNameErrorMsg("Last Name is required");
    } else {
      setLastNameErrorMsg("");
    }
    setLastName(e.target.value);
  };

  const _handleSubmit = async () => {
    if (returnEmail) setEmail(returnEmail);
    if (password === "") {
      return setPassErrorMsg("Password is required");
    }
    if (email === "" && !returnEmail) {
      return setEmailErrorMsg("Email Id is required");
    }
    if (!validateEmail(email)) {
      return setEmailErrorMsg("Invalid Email");
    }
    if (firstName === "") {
      return setfirstNameErrorMsg("First Name is required");
    }
    if (lastName === "") {
      return setLastNameErrorMsg("Last Name is required");
    }

    try {
      toggleProgress(true);
      const { data, status } = await API.post("user/register", {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        /*TODO : Change dummy values later. Sending dummy values for now untill backend migration is complete */
      });

      if (status !== 200) {
        if (data.error) {
          setAlertMessage(data.error.message);
          toggleProgress(false);
          return;
        }

        if (status === 500) {
          setAlertMessage("An Error Occured. Please try again later");
          toggleProgress(false);
          return;
        }
      }

      if (data.id) {
        history.push("/user/confirm", { return_url: returnURL });
      }
    } catch (err) {
      setAlertMessage("An error occured. Please try again later.");
      console.log({ err });
      toggleProgress(false);
    }
  };

  return (
    <div>
      {/* <Header /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100vh",
          margin: "auto",
        }}
      >
        <LHS />
        <div
          style={{
            display: "flex",
            // flexDirection: "column",
            justifyContent: "center",
            // marginLeft: "100px",
            width: "100%",
          }}
        >
          {/* <div style={{ margin: "10.0625rem 11.625rem" }}> */}
          <div className="m-8 sm:my-16">
            <h2>Create Your Account</h2>
            <div style={{ marginTop: "1.875rem" }}>
              <LinearTextBox
                id="firstname"
                label="First Name"
                placeholder="Enter First Name"
                onChange={handleFirstName}
                errorMessage={firstNameErrorMsg}
              />
            </div>
            <div style={{ marginTop: "1.875rem" }}>
              <LinearTextBox
                id="lastname"
                label="Last Name"
                placeholder="Enter Last Name"
                onChange={handleLastName}
                errorMessage={lastNameErrorMsg}
              />
            </div>
            <div style={{ marginTop: "1.875rem" }}>
              <LinearTextBox
                id="email"
                label="Email"
                placeholder="Enter your email"
                onChange={handleEmail}
                value={email}
                defaultValue={returnEmail}
                disabled={returnEmail ? true : false}
                errorMessage={emailErrorMsg}
              />
            </div>
            <div style={{ marginTop: "1.875rem" }}>
              <LinearTextBox
                id="password"
                label="Password"
                type="password"
                placeholder="Enter a strong password"
                onChange={handlePassword}
                errorMessage={passErrorMsg}
              />
            </div>
            <p className="text-box-errorText body-extra-medium-1 ">
              {alertMessage}
            </p>
            <div style={{ marginTop: "1.875rem" }}>
              <LinearButton
                type="primary"
                width="21.875rem"
                height="3rem"
                onClick={_handleSubmit}
                disabled={inProgress}
                text={inProgress ? <CircularProgress /> : "Register"}
              ></LinearButton>
            </div>

            <div
              style={{
                marginTop: "1rem",
              }}
            >
              {/* <a style={{ color: "#3f51b5" }} href="/user/forgot-password">Forgot Password?</a> */}
              <p className="body-small-1">
                {" "}
                Already Have an Account?{" "}
                <div
                  style={{ color: "var(--main-button-color)" }}
                  className="cursor-pointer"
                  onClick={() =>
                    history.push("/user/login", { return_url: returnURL })
                  }
                >
                  Login
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSignUp;
