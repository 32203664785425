import React from "react";

export default function TeamCard({
  memberImage,
  memberName,
  memberEmail,
  memberRole,
  memberStatus,
  actions,
}) {
  return (
    <div className="grid grid-cols-1 gap-4 mx-4 my-4">
      <div className="bg-white rounded-lg p-4 shadow-xl">
        <div className="flex flex-1 items-center justify-between relative">
          <div className="body-medium font-semibold">{memberName}</div>
          <div className="absolute right-2 mt-12">{memberImage}</div>
        </div>
        <div className="body-medium text-xs tracking-wider">{memberEmail}</div>
        <div className="flex flex-row items-center gap-4 my-4">
          <div className="body-medium tracking-wider text-sm font-semibold rounded-lg bg-opacity-70 bg-blue-300 text-blue-700 p-1">
            {memberStatus}
          </div>

          {memberRole === "Admin" && (
            <div className="body-medium  bg-green-300 bg-opacity-50 rounded-lg px-2 py-1 text-green-700 font-semibold tracking-wider text-sm">
              {memberRole}
            </div>
          )}
        </div>
        <div>{actions}</div>
      </div>
    </div>
  );
}
