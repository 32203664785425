import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import ReviewQuizContainer from "./ReviewQuiz";

const StrapiReviewActivity = (props) => {
  const location = useLocation();
  const { activity_id } = props.match.params;
  const goal_id = location.state?.goal_id;

  const fetchUrl = `strapi/user-responses?activity_id=${activity_id}&goal_id=${goal_id}&attempt=${1}`

  return (
    <ReviewQuizContainer
      fetchURL={fetchUrl}
    />
  )

};

export default StrapiReviewActivity;