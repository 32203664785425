import React from "react";
import LinearTextBox from "../../../components/TextBox";

const Card = () => {
  const handleChange = () => {};
  const handleSubmit = () => {};
  return (
    <div>
      <div>
        <LinearTextBox
          name="card_number"
          label="Enter Card Number"
          placeholder="1111 2222 3333 4444"
          onChange={handleChange}
        />
        <LinearTextBox
          name="expiry_month"
          label="Enter Expiry Month"
          placeholder="11"
          onChange={handleChange}
        />
        <LinearTextBox
          name="expiry_year"
          label="Enter Expiry Year"
          placeholder="25"
          onChange={handleChange}
        />
        <LinearTextBox
          name="cvv"
          label="Enter CVV"
          placeholder="123"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Card;
