import React from "react";
import CheckIcon from "@mui/icons-material/CheckCircleRounded";
import LinearButton from "../../components/Button";
import "./index.css";

const PricingPage = () => {
  return (
    <div>
      <div className="text-center my-8">
        <div>
          <h3>Pricing</h3>
        </div>
        <div className="body-large">Select a plan to get started</div>
      </div>
      <div className="flex flex-col sm:flex-row justify-center items-center gap-8">
        <div
          className="w-1/4 rounded-xl p-4"
          style={{ backgroundColor: "var(--blue-de-france-10)" }}
        >
          <h5 style={{ color: "var(--prussian-blue)" }}>Trial</h5>
          <h3 style={{ color: "var(--prussian-blue)" }}>Free</h3>
          <div className="body-large flex flex-col gap-2 my-4">
            <div className="flex flex-row items-center gap-2">
              <CheckIcon style={{ color: "var(--prussian-blue)" }} />
              One program limit
            </div>
            <div className="flex flex-row items-center gap-2">
              <CheckIcon style={{ color: "var(--prussian-blue)" }} />
              One level limit
            </div>
            <div className="flex flex-row items-center gap-2">
              <CheckIcon style={{ color: "var(--prussian-blue)" }} />
              90 day limit
            </div>
          </div>
          <button className="price-button-secondary w-full">Get Started</button>
        </div>
        <div
          className="w-1/4 rounded-xl p-4"
          style={{
            backgroundColor: "var(--prussian-blue)",
            color: "var(--white)",
          }}
        >
          <h5 style={{ color: "var(--white)" }}>Monthly</h5>
          <div className="flex flex-row gap-4 items-center">
            <h3>$5</h3>
            <div className="body-small-1">per user / per month</div>
          </div>
          <div className="body-large flex flex-col gap-2 my-4">
            <div className="flex flex-row items-center gap-2">
              <CheckIcon style={{ color: "var(--blue-de-france-10)" }} />
              Unlimited programs
            </div>
            <div className="flex flex-row items-center gap-2">
              <CheckIcon style={{ color: "var(--blue-de-france-10)" }} />
              No level limit
            </div>
            <div className="flex flex-row items-center gap-2">
              <CheckIcon style={{ color: "var(--blue-de-france-10)" }} />
              Billed Monthly
            </div>
          </div>
          <button className="price-button w-full">Get Started</button>
        </div>
        <div
          className="w-1/4 rounded-xl p-4"
          style={{ backgroundColor: "var(--blue-de-france-10)" }}
        >
          <h5 style={{ color: "var(--prussian-blue)" }}>Yearly</h5>
          <div
            className="flex flex-row gap-4 items-center"
            style={{ color: "var(--prussian-blue)" }}
          >
            <h3>$5</h3>
            <div className="body-small-1">per user / per month</div>
          </div>
          <div className="body-large flex flex-col gap-2 my-4">
            <div className="flex flex-row items-center gap-2">
              <CheckIcon style={{ color: "var(--prussian-blue)" }} />
              Unlimited programs
            </div>
            <div className="flex flex-row items-center gap-2">
              <CheckIcon style={{ color: "var(--prussian-blue)" }} />
              No level limit
            </div>
            <div className="flex flex-row items-center gap-2">
              <CheckIcon style={{ color: "var(--prussian-blue)" }} />
              Billed annually
            </div>
          </div>
          <button className="price-button-secondary w-full">Get Started</button>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
