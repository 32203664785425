import React, { useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { API } from "../../../../api";
import Alert from "@material-ui/lab/Alert";

const AdminAddTrack = () => {
  const [trackData, setTrackData] = useState({
    code: "",
    name: "",
    description: "",
  });

  const [inProgress, toggleProgress] = useState(false);
  const [alertVisible, toggleAlertVisibility] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const _handleChange = (e) => {
    setTrackData({ ...trackData, [e.target.name]: e.target.value });
    toggleAlertVisibility(false);
  };

  const _handleSubmit = async () => {
    try {
      toggleProgress(true);
      const { data, status } = await API.post("track/add", trackData);
      if (status === 200) {
        setAlertMessage("Track has been added");
        setAlertSeverity("info");
        toggleAlertVisibility(true);
        toggleProgress(false);
      } else {
        setAlertMessage(data.error ? data.error.message : "Error Occured");
        setAlertSeverity(data.error ? "error" : "info");
        toggleAlertVisibility(true);
        toggleProgress(false);
      }
    } catch (err) {
      setAlertMessage("An error occured. Please try again later.");
      setAlertSeverity("error");
      toggleAlertVisibility(true);
      toggleProgress(false);
    }
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="shadow-xl p-36">
        <div className="text-center text-3xl">Admin Dashboard</div>
        <div
          style={{ marginTop: "10%", display: "flex", flexDirection: "column" }}
        >
          <h5>Add a new Track</h5>
          <TextField
            id="outlined-basic"
            label="Code"
            variant="outlined"
            name="code"
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            name="name"
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px" } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <TextField
            id="outlined-basic"
            label="Description"
            variant="outlined"
            name="description"
            multiline={true}
            rows={5}
            onChange={_handleChange}
            inputProps={{ style: { fontSize: "25px", lineHeight: 1.2 } }}
            InputLabelProps={{ style: { fontSize: "20px" } }}
            style={{ marginBottom: "20px" }}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={_handleSubmit}
            style={{ padding: "20px" }}
            disabled={inProgress}
          >
            {inProgress ? <CircularProgress /> : "Add Track"}
          </Button>
          {alertVisible && (
            <Alert style={{ marginTop: "1.5rem" }} severity={alertSeverity}>
              {alertMessage}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminAddTrack;
