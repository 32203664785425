import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import report from "../../../assests/icons/profile/report.svg";
import OrgProfileIcon from "../../../assests/icons/administrator/org_icon.svg";
import GroupIcon from "../../../assests/icons/administrator/group_icon.svg";
import UserPlusIcon from "../../../assests/icons/administrator/user_plus_icon.svg";
import UserSearchIcon from "../../../assests/icons/administrator/user_search_icon.svg";

import selected_report from "../../../assests/icons/profile/selected_report.svg";
import SelectedOrgProfileIcon from "../../../assests/icons/administrator/selected_org_icon.svg";
import SelectedGroupIcon from "../../../assests/icons/administrator/selected_group_icon.svg";
import SelectedUserPlusIcon from "../../../assests/icons/administrator/selected_user_plus_icon.svg";
import SelectedUserSearchIcon from "../../../assests/icons/administrator/selected_user_search_icon.svg";

import Reports from "../../Admin/Reports";
import OrgDetails from "../OrgDetails/OrgDetails";
import Group from "../Group/Group";
import User from "../User";
import { useStoreState } from "easy-peasy";

const AdministratorDashboard = (props) => {
  const userData = useStoreState((state) => state.user.data);
  const {role_id, org_id} = userData;

  const history = useHistory();
  const [component, setComponent] = useState(null);
  const [selectedButton, setSelectedButton] = useState(null);
  const [MENU_OPTIONS, setMENU_OPTIONS] = useState([
    {
      index: 0,
      text: "Reports",
      value: "reports",
      img: report,
      selected_img: selected_report,
    },
    {
      index: 1,
      text: "Organization",
      value: "organization",
      img: OrgProfileIcon,
      selected_img: SelectedOrgProfileIcon,
    },
    {
      index: 2,
      text: "Group",
      value: "group",
      img: GroupIcon,
      selected_img: SelectedGroupIcon,
    },
    {
      index: 3,
      text: "User",
      value: "user",
      img: UserPlusIcon,
      selected_img: SelectedUserPlusIcon,
    },
    {
      index: 4,
      text: "Search",
      value: "search",
      img: UserSearchIcon,
      selected_img: SelectedUserSearchIcon,
    },
  ]);
  const [MENU_INDEX, setMENU_INDEX] = useState([
    "reports",
    "organization",
    "group",
    "user",
    "search",
  ]);

  const displayComponent = () => {
    if (component === "reports") {
      return <Reports role_id={role_id} org_id={org_id}/>;
    } else if (component === "organization") {
      return <OrgDetails />;
    } else if (component === "group") {
      return <Group />;
    } else if (component === "user") {
      return <User />;
    }
    /*else if (component === "search") {
      return <UserSearch />;
    }*/
  };

  useEffect(() => {
    if (![3, 4, 5, 6].includes(Number(role_id))) {
      const filteredOptions = MENU_OPTIONS.filter(
        (option) => option.value !== "organization" && option.value !== "user"
      );
      const filteredIndex = MENU_INDEX.filter(
        (value) => value !== "organization" && value !== "user"
      );

      setMENU_OPTIONS(filteredOptions);
      setMENU_INDEX(filteredIndex);
    }
  }, [MENU_OPTIONS, MENU_INDEX]);

  useEffect(() => {
    const propsComponent = props.location?.state?.component;
    if (propsComponent) {
      setComponent(propsComponent);
      setSelectedButton(MENU_INDEX.indexOf(propsComponent));
    } else {
      setComponent(MENU_OPTIONS[0].value);
      setSelectedButton(MENU_INDEX[0]);
    }
  }, [props]);

  return (
    <div
      className="min-h-content min-h-screen"
      style={{ backgroundColor: "#F9F9F9" }}
    >
      <div className="flex flex-row">
        <div className="p-4 m-4 bg-white h-full container w-1/3 lg:w-1/5">
          <div>
            {MENU_OPTIONS.map((option, index) => {
              return (
                <div
                  key={index}
                  className="body-small-1 p-4 mb-4 cursor-pointer"
                  onClick={() => {
                    setComponent(option.value);
                    setSelectedButton(index);
                  }}
                  style={
                    index === selectedButton
                      ? {
                        backgroundColor: "#E9F3FD",
                        color: "#2288EE",
                        borderRadius: "10px",
                      }
                      : null
                  }
                >
                  <div className="flex flex-row items-center gap-4">
                    <img
                      alt="menu_option_img"
                      src={
                        selectedButton === index
                          ? option.selected_img
                          : option.img
                      }
                    />
                    {option.text}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="lg:w-4/5 md:w-2/3 p-4 m-4 bg-white h-full container overflow-auto">
          {displayComponent()}
        </div>
      </div>
    </div>
  );
};

export default AdministratorDashboard;
