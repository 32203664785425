import NewModal from "../../../components/NewModal";
import {ReactComponent as RoundCheck} from "../../../assests/icons/ic/check - round.svg";
import LinearButton from "../../../components/Button";
import { useHistory } from "react-router-dom";

const ForgotPasswordModal = () => {
    const history = useHistory()
    const onLogin = () => {
        history.push("/user/login");
    }
    const body = (
        <div className="program-modal-body">
            <div>
                <RoundCheck title="success"></RoundCheck>
            </div>
            <div className="body-medium-1 program-modal-desc">
                Please login to your email account again.
            </div>
        </div>
    )
    const footer = (
        <div className="program-modal-footer">
            <LinearButton
                text={"Login Now"}
                onClick={onLogin}
            />
        </div>
    );

    return <NewModal 
        heading="Password Changed Successfully" 
        hasCloseButton={false}
        body={body}
        footer={footer}
    />
}

export default ForgotPasswordModal;