import React from "react";
import useSWR from "swr";

export default function Notifications() {
  const { data: notificationList } = useSWR("notification/get-inapp");

  return (
    <div className="m-4">
      <h5>Notifications</h5>
      <div>
        {notificationList?.map((notification, index) => {
          return (
            <div key={index} className="border-b-2 my-4">
              {notification.message}
            </div>
          );
        })}
      </div>
    </div>
  );
}
