import React, { useEffect, useRef, useState } from "react";
import { API } from "../../../api";
import LinearButton from "../../../components/Button";
import NewModal from "../../../components/NewModal";
import LinearTextBox from "../../../components/TextBox";
import { useStoreState } from "easy-peasy";
import Table from "../../../components/Table";
import TeamCard from "../TeamCard";
import useScreenSize from "../../../hooks/useScreenSize";
import { validateEmail } from "../../../lib/helpers";
import { CircularProgress } from "@material-ui/core";
import InvitationTable from "./InvitationTable";

const TeamAdmin = () => {
  const [admin, setAdmin] = useState();
  const [groupID, setGroupID] = useState();
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [team, setTeam] = useState([]);
  const [groups, setGroups] = useState([]);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [showAddNewMemberModal, setShowAddNewMemberModal] = useState(false);
  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
  const [showResendInviteModal, setShowResendInviteModal] = useState(false);
  const [showManageInviteModal, setShowManageInviteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [invitation, setInvitation] = useState("");
  const inputRef = useRef();
  const { isMobile } = useScreenSize();

  const user = useStoreState((s) => s.user.data);
  const current_user_email = localStorage.getItem("email");

  const handleChange = (e) => {
    setUserEmail(e.target.value.toLowerCase());
  };

  const handleClick = async (Id) => {
    setIsLoading(true);
    const { data, status } = await API.post(`group/accept-invite/${Id}`);
    if (status === 200) {
      fetchData();
    }
    console.log("Accept invite clicked for group id:", Id);
    setShowManageInviteModal(false);
  };

  const getTeamMembers = async (id) => {
    if (id) {
      const { data } = await API.get("group/user-status", id);
      setAdmin(data.admin);
      setTeam(data.users);
    }
  };

  const sendInvite = async () => {
    setMessage("");
    if (!validateEmail(userEmail)) {
      setMessage("Invalid Email.");
      return;
    }
    const { data, status } = await API.post("group/add-user", {
      group_id: groupID,
      user_email_list: [userEmail],
    });
    inputRef.current.value = "";
    setUserEmail("");
    if (status === 200) {
      setMessage("Invite sent successfully");
      getTeamMembers(groupID);
    } else {
      setMessage(data.error?.message);
    }
  };

  const resendInvite = async () => {
    const { status } = await API.post("group/resend-invite", {
      email: userEmail,
      group_id: groupID,
    });
    if (status === 200) {
      setMessage("Invitation has been sent");
      setUserEmail("");
    } else {
      setMessage("An error occured");
      setUserEmail("");
    }
  };

  const removeUser = async () => {
    const { data } = await API.post("group/remove-user", {
      email: userEmail,
      group_id: groupID,
    });
    setMessage(data.message);
    setUserEmail("");
    getTeamMembers(groupID);
  };

  const getImage = (member) => {
    return (
      <img
        alt="member-user"
        style={{
          width: 64,
          height: 64,
          minWidth: 64,
          minHeight: 64,
          marginRight: 10,
          borderRadius: "50%",
          behavior: "url(PIE.htc)",
        }}
        src={
          member.user?.photo_url
            ? `${process.env.REACT_APP_S3_URL}/${member.user?.photo_url}`
            : `${process.env.REACT_APP_S3_URL}/default_photo.png`
        }
      />
    );
  };

  const getActions = (member, admin) => {
    return (
      current_user_email === admin?.group_admin?.email && (
        <div>
          {member.user?.email !== admin?.group_admin?.email && (
            <LinearButton
              className="cursor-pointer"
              onClick={(e) => {
                setUserEmail(member.user?.email);
                if (e.target.textContent === "Resend Invite") {
                  setShowResendInviteModal(true);
                } else if (e.target.textContent === "Remove") {
                  setShowRemoveMemberModal(true);
                }
              }}
              text={member?.status === "invited" ? "Resend Invite" : "Remove"}
            />
          )}
        </div>
      )
    );
  };

  async function findUserInGroupsTable() {
    const { data } = await API.get("group", "user-invited");
    if (data) {
      setPendingInvites(data);
    }
    return data;
  }

  const fetchData = async () => {
    async function getJoinedGroup() {
      const { data } = await API.get("group", "get-joined");
      setGroups(data);
      return data[0];
    }
    const joinedGroup = await getJoinedGroup();
    if (joinedGroup) {
      setGroupID(joinedGroup?.group_id);
      getTeamMembers(joinedGroup?.group_id);
    }
    const checkUserExistInGroupsTable = await findUserInGroupsTable();
    if (checkUserExistInGroupsTable.length > 0) {
      setInvitation("pending");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getTeamMembers(groupID);
  }, [groupID]);

  useEffect(() => {
    const tableData = [];
    for (const member of team) {
      const memberImage = getImage(member);
      const memberName = member?.user?.first_name
        ? member.user.first_name + " " + member?.user.last_name
        : member.user.email.split("@")[0];
      const memberEmail = member?.user?.email;
      const memberStatus = member?.status?.toUpperCase();
      const memberRole =
        member.user?.email === admin?.group_admin?.email ? "Admin" : "Member";
      const actions = getActions(member, admin);
      const element = [
        <div className="flex flex-row gap-4 items-center">
          {memberImage} {memberName}
        </div>,
        memberEmail,
        memberStatus,
        memberRole,
        actions,
      ];
      tableData.push(element);
    }
    setTableHeader(["Name", "Email", "Status", "Role", "Actions"]);
    setTableBody(tableData);
  }, [team]);

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <div style={{ marginTop: "-20vh" }}>
            <CircularProgress size={80} />
          </div>
        </div>
      ) : groups.length > 0 ? (
        <div
          className={
            isMobile
              ? "overflow-x-auto bg-gray-200 min-h-screen"
              : "overflow-x-auto"
          }
        >
          <div className="flex flex-row mr-4 justify-between items-center">
            <select
              className="input-box body-small-1"
              onChange={(e) => setGroupID(e.target.value)}
            >
              {groups.map((group, index) => (
                <option value={group?.group_id} key={index}>
                  {group?.groups[0].name}
                </option>
              ))}
            </select>
            <button
              className="primary-button button-small text-white p-4"
              style={{ backgroundColor: "#003366", borderRadius: "10px" }}
              onClick={() => setShowManageInviteModal(true)}
            >
              Manage Invites
            </button>
          </div>
          {current_user_email === admin?.group_admin?.email && (
            <div
              className={`flex flex-row justify-between items-center m-${
                isMobile ? 4 : 4
              }`}
            >
              <h5>Admin</h5>
              <button
                className="primary-button button-small text-white p-4"
                style={{ backgroundColor: "#003366", borderRadius: "10px" }}
                onClick={() => setShowAddNewMemberModal(true)}
              >
                Invite new team member
              </button>
            </div>
          )}
          {isMobile ? (
            team?.map((member, index) => (
              <TeamCard
                className=""
                memberImage={
                  <img
                    alt="member-user"
                    style={{
                      width: 64,
                      height: 64,
                      minWidth: 64,
                      minHeight: 64,
                      marginRight: 10,
                      borderRadius: "50%",
                      behavior: "url(PIE.htc)",
                    }}
                    src={
                      member.user?.photo_url
                        ? `${process.env.REACT_APP_S3_URL}/${member.user?.photo_url}`
                        : `${process.env.REACT_APP_S3_URL}/default_photo.png`
                    }
                  />
                }
                memberName={
                  member.user?.first_name + " " + member.user?.last_name
                }
                memberEmail={member.user?.email}
                memberRole={
                  member.user?.email === admin?.group_admin?.email
                    ? "Admin"
                    : "Member"
                }
                memberStatus={member.status?.toUpperCase()}
              />
            ))
          ) : (
            <Table header={tableHeader} body={tableBody} />
          )}
          {showAddNewMemberModal && (
            <NewModal
              heading={<div className="mx-16">Invite new member</div>}
              body={
                <div className="mx-16">
                  <LinearTextBox
                    label="Enter email address"
                    inputRef={inputRef}
                    onChange={handleChange}
                    placeholder="abc@linear.live"
                    value={userEmail}
                  />
                </div>
              }
              footer={
                <div className="flex flex-col justify-center gap-4 items-center mx-16">
                  <LinearButton text="Send Invite" onClick={sendInvite} />
                  {message}
                </div>
              }
              onClose={() => {
                setMessage("");
                setShowAddNewMemberModal(false);
              }}
            />
          )}
          {showRemoveMemberModal && (
            <NewModal
              heading={<div className="mx-10">Are you sure?</div>}
              body={
                <div className="mx-16">
                  Are you sure you want to remove this user?
                </div>
              }
              footer={
                <div className="flex flex-col justify-center gap-4 items-center mx-16">
                  <LinearButton text="Yes, remove" onClick={removeUser} />
                  {message}
                </div>
              }
              onClose={() => setShowRemoveMemberModal(false)}
            />
          )}
          {showResendInviteModal && (
            <NewModal
              heading={<div className="mx-10">Resend Invite to this user?</div>}
              footer={
                <div className="flex flex-col justify-center gap-4 items-center mx-16">
                  <LinearButton text="Send invite" onClick={resendInvite} />
                  {message}
                </div>
              }
              onClose={() => setShowResendInviteModal(false)}
            />
          )}
        </div>
      ) : invitation === "pending" ? (
        <div className="flex items-center space-x-4">
          <p>Click here to manage invites!</p>
          <button
            className="primary-button button-small text-white p-4"
            style={{ backgroundColor: "#003366", borderRadius: "10px" }}
            onClick={() => setShowManageInviteModal(true)}
          >
            Manage Invites
          </button>
        </div>
      ) : invitation === "" ? (
        <div>
          You are not yet a member of any team. If you are on an enterprise
          subscription plan, contact your organization administrator to be added
          to a group.
        </div>
      ) : null}
      {showManageInviteModal && (
        <NewModal
          heading={<div className="block text-center">Manage Your Invites</div>}
          body={
            <div className="mx-16">
              {pendingInvites.length > 0 ? (
                <InvitationTable
                  handleClick={handleClick}
                  pendingInvites={pendingInvites}
                />
              ) : (
                <span className="block text-center">
                  You have no pending invites🦉!
                </span>
              )}
            </div>
          }
          onClose={() => {
            setShowManageInviteModal(false);
          }}
        />
      )}
    </div>
  );
};

export default TeamAdmin;
