import { createStore, action, computed, persist } from "easy-peasy";

const userModel = {
  data: undefined,
  update: action((state, payload) => {
    state.data = payload;
  }),
  delete: action((state) => {
    delete state.data;
  }),
};

const adminModel = {
  data: undefined,
  update: action((state, payload) => {
    state.data = payload;
  }),
};

const storeModel = {
  user: userModel,
  admin: adminModel,
};

const store = createStore(persist(storeModel));
export default store;
