import { API } from "../../api";

const storeUserDataInLS = (data, saveUser) => {
  localStorage.setItem("newPhotoURL", data.newPhotoURL);
  localStorage.setItem("email", data.email);
  localStorage.setItem("id", data.id);
  localStorage.setItem("org_id", data.organization_id);
  localStorage.setItem("expiry", data.expiryDate);
  localStorage.setItem("first_name", data.first_name);
  localStorage.setItem("last_name", data.last_name);
  localStorage.setItem("photo_url", data.photo_url);
  saveUser(data);
}

/**
 * 
 * @param {*} saveUser -  A useStoreActions function to update the user data;
 * @returns boolean - indicating whether the API Request was successful
 */

const getAuth = async (saveUser) => {
  try {
    const response = await API.get("user", "check-auth");
    const { data } = response;
    storeUserDataInLS(data, saveUser)
    return response.status === 200
  } catch (error) {
    console.error('Error checking authentication', error);
    return false;
  }
};

const getAdminAuth = async (saveUser) => {
  try {
    const response = await API.get("user", "admin-auth");
    const { data } = response;
    storeUserDataInLS(data, saveUser);
    return response.status === 200
  } catch (error) {
    console.error('Error checking authentication', error);
    return false;
  }
};

export { getAuth, getAdminAuth };
