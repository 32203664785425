import React from "react";
import "./index.css";
const LinearTextBox = ({
  placeholder = "Enter Text",
  defaultValue = "",
  label = "Label",
  errorMessage = "",
  onChange = () => {},
  onKeyDown = () => {},
  disabled = false,
  name = "",
  type = "text",
  inputRef = React.createRef(),
  style = {},
  value,
}) => {
  const className =
    "text-box-primary" +
    (errorMessage !== "" ? " text-box-error" : " text-box-default");
  return (
    <>
      <p className="body-small-1">{label}</p>
      <input
        style={style}
        type={type}
        defaultValue={defaultValue}
        className={className}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        name={name}
        disabled={disabled}
        ref={inputRef}
        value={value}
      ></input>
      <p className="text-box-errorText body-extra-small-1">{errorMessage}</p>
    </>
  );
};

export default LinearTextBox;
