import React, { useState, useEffect } from "react";

const MCQ = (props) => {
  const { options, id, isUserCorrect, sendResponse, correctAnswer, selected, answer, setAnswer = () => { }, buttonLoading = false } = props;
  const [selectedButton, setSelectedButton] = useState();

  const selectMcqResponse = (option_id, key) => {
    // Whenever we click any option this function is triggered
    setAnswer(option_id);
    setSelectedButton(key);
  };

  useEffect(() => {
    handleMcqResponse();
  }, [answer]);

  useEffect(() => {
    // Whenever question id changes we have to check if user has responded for the particular question through selected prop
    setSelectedButton(null);
    setAnswer(null);
    if (selected) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].mcq_options.id === selected) {
          setSelectedButton(i);
          break;
        }
      }
    }
  }, [id]);

  const handleMcqResponse = async () => {
    // This is responsible for sending our responses to challengeContainer
    const mcqResponse = {
      answer,
      type:"mcq",
    };
    sendResponse(mcqResponse);
  };

  if (!options) return null;

  return (
    <div>
      <div>
        <div className="grid md:grid-cols-2 gap-4 sm:grid-cols-1 mb-4 flex-wrap">
          {options.map((option, index) => {
            return (
              <button
                className="option body-medium-1 px-6 py-3 my-2"
                key={index}
                style={{
                  backgroundColor:
                    isUserCorrect !== undefined
                      ? options[index]?.mcq_options?.id === correctAnswer?.id
                        ? "#09B746" //green
                        : index === selectedButton
                          ? "#C82020" //red
                          : "#ffffff" //non selected color
                      : index === selectedButton
                        ? "#00aacc" //selected color
                        : "#ffffff", //non selected color
                  color:
                    isUserCorrect !== undefined
                      ? options[index]?.mcq_options?.id === correctAnswer?.id
                        ? "white" //green
                        : index === selectedButton
                          ? "white" //red
                          : "black" //non selected color
                      : index === selectedButton
                        ? "white" //selected color
                        : "black", //non selected color
                }}
                onClick={() => {
                  if (!(buttonLoading || correctAnswer)) {
                    selectMcqResponse(options[index]?.mcq_options?.id, index);
                  }
                }}
              >
                {option?.mcq_options?.option_text}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MCQ;
