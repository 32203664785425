import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LinearButton from "../../../components/Button";
import Header from "../../../legacy/Header";
import LHS from "../../../components/LHS";
import useScreenSize from "../../../hooks/useScreenSize";

const ConfirmEmail = (props) => {
  const [returnURL, setReturnURL] = useState(props.location.state?.return_url);
  const { isMobile } = useScreenSize();

  const history = useHistory();

  const goToLogin = () => {
    history.push("/user/login", { return_url: returnURL });
  };

  return (
    <div>
      {/* <Header /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "auto",
          height: "100vh",
        }}
      >
        <LHS />
        <div style={{}}>
          <div
            style={
              isMobile
                ? {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100vh",
                    marginLeft: 10,
                    marginRight: 10,
                  }
                : { margin: "17.0625rem 11.625rem" }
            }
          >
            <h2>SignUp Successful !</h2>
            <p className="body-extra-medium-1 margin-top-22">
              An email is sent to your email. Please click the verification link
              sent to your email to verify the account
            </p>
            <div className="margin-top-22">
              <LinearButton
                type="primary"
                onClick={goToLogin}
                width="21.875rem"
                height="3rem"
                text="Go to Login"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
