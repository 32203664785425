import React, { useEffect, useState } from "react";
import { API } from "../../../../api";

const AdminReadingList = () => {
  const [readingList, setReadingList] = useState();

  useEffect(() => {
    async function getReadingList() {
      const { data } = await API.get("reading", "all");
      setReadingList(data);
    }
    getReadingList();
  }, []);
  return (
    <div>
      {readingList?.map((reading, index) => {
        return (
          <div key={index} className="p-4 shadow-xl m-4 ">
            <div className="text-2xl">{reading.reading_title}</div>
            <div>
              <span className="text-xl text-blue-700">Text:</span>{" "}
              {reading.reading_text}
            </div>
            <div>
              <span className="text-xl text-blue-700">Status:</span>{" "}
              {reading.active ? "Active" : "Inactive"}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AdminReadingList;
