import React, { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { API } from "../../../../api";
import LinearButton from "../../../../components/Button";
import LinearTextBox from "../../../../components/TextBox";
import Excel from "react-export-excel";

export default function AdminAddTeamMembers() {
  const ExcelFile = Excel.ExcelFile;
  const ExcelSheet = Excel.ExcelFile.ExcelSheet;
  const ExcelColumn = Excel.ExcelFile.ExcelColumn;

  const { data: organizationList } = useSWR("organization/all");

  const [userList, setUserList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [group, setGroup] = useState("");
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [organization, setOrganization] = useState("");
  const emailRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const [message, setMessage] = useState("");

  const _handleAddEmail = () => {
    setMessage("");
    setUserList([
      ...userList,
      {
        email: emailRef.current.value,
        first_name: firstNameRef.current.value,
        last_name: lastNameRef.current.value,
        invite_link: `${process.env.REACT_APP_CLIENT_URL}/group/join-link/${emailRef.current.value}/${group}`,
      },
    ]);
    emailRef.current.value = "";
    firstNameRef.current.value = "";
    lastNameRef.current.value = "";
  };

  const _handleSubmit = async () => {
    const { status } = await API.post("group/add-user-only", {
      group_id: group,
      user_list: userList,
    });
    if (status === 200) {
      setMessage("Members added successfully");
    } else setMessage("An error occured");
  };

  const getGroups = async () => {
    const { data } = await API.get(
      "group/all",
      `?organization_id=${organization}`
    );
    setGroupList(data);
  };

  useEffect(() => {
    getGroups();
  }, [organization]);

  return (
    <div className="h-screen">
      <h4 className="text-center my-4">Enter email address of team members</h4>
      <div className="flex flex-row justify-center gap-4 items-center mb-4">
        <label className="body-small-1">Filter Organization</label>
        <select
          disabled={userList.length >= 1 ? true : false}
          onChange={(e) => {
            setOrganization(e.target.value);
            setMessage("");
          }}
          name="country"
          className="input-box body-small-1"
        >
          {organizationList &&
            [" ", ...organizationList]?.map((group, index) => {
              return (
                <option value={group.id} key={index}>
                  {group.name}
                </option>
              );
            })}
        </select>
      </div>
      <div className="flex flex-row justify-center gap-4 items-center ">
        <label className="body-small-1">Select Group</label>
        <select
          disabled={userList.length >= 1 ? true : false}
          onChange={(e) => {
            setGroup(JSON.parse(e.target.value).id);
            setSelectedGroupName(JSON.parse(e.target.value).name);
            setMessage("");
          }}
          name="country"
          className="input-box body-small-1"
        >
          {groupList &&
            [" ", ...groupList]?.map((group, index) => {
              return (
                <option value={JSON.stringify(group)} key={index}>
                  {group.name}
                </option>
              );
            })}
        </select>
      </div>
      <div className="my-4 flex flex-col sm:flex-row justify-center items-center gap-2 sm:gap-4 flex-wrap">
        <LinearTextBox
          label=""
          inputRef={firstNameRef}
          placeholder="Enter first name"
        />
        <LinearTextBox
          label=""
          inputRef={lastNameRef}
          placeholder="Enter last name"
        />
        <LinearTextBox
          label=""
          inputRef={emailRef}
          placeholder="Enter team member email address"
        />

        <LinearButton text="Add email" onClick={_handleAddEmail} />
      </div>
      <div
        className="px-4 py-2 mb-4"
        style={{
          backgroundColor: "#E9F3FD",
        }}
      >
        <div className="flex flex-row mx-4 my-2 items-center">
          <h6 className="w-1/5">Team Members</h6>
        </div>
      </div>
      {userList?.map((user, index) => {
        return (
          <div
            key={index}
            className="flex flex-row justify-between px-4 py-2 mx-4 items-center border-b-2"
          >
            <div className="flex flex-col items-start">
              <div className="text-center font-semibold">
                {user.first_name} {user.last_name}
              </div>
              <div>{user.email}</div>
              <div>{user.invite_link}</div>
            </div>
            <div>
              <LinearButton
                text="Remove"
                onClick={() => {
                  const temp = [...userList];
                  temp.splice(index, 1);
                  setUserList(temp);
                }}
              />
            </div>
          </div>
        );
      })}
      <div className="flex flex-row justify-center mt-4 gap-8">
        <LinearButton
          size="extra-large"
          text="Add Members"
          onClick={_handleSubmit}
        />
        <ExcelFile
          element={<LinearButton text="Download Data" size="extra-large" />}
          filename={selectedGroupName}
        >
          <ExcelSheet data={userList} name="Members">
            <ExcelColumn label="First Name" value="first_name" />
            <ExcelColumn label="Last Name" value="last_name" />
            <ExcelColumn label="Email" value="email" />
            <ExcelColumn label="Invite Link" value="invite_link" />
          </ExcelSheet>
        </ExcelFile>
      </div>
      <div className="flex flex-row justify-center mt-4">{message}</div>
    </div>
  );
}
