import { CircularProgress } from "@material-ui/core";
import { useStoreActions } from "easy-peasy";
import React, { useState } from "react";
import { API } from "../../../api";
import { useHistory } from "react-router";
import LHS from "../../../components/LHS";
import LinearButton from "../../../components/Button";
import LinearTextBox from "../../../components/TextBox";
import "./index.css";
import GoogleIcon from "../../../assests/icons/Social/google.svg";
import { validateEmail } from "../../../lib/helpers";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
// import { ReactComponent as Google } from "../../../assests/icons/Social/google.svg";

const UserLogin = (props) => {
  const [inProgress, toggleProgress] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passErrorMsg, setPassErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [returnURL] = useState(props.location.state?.return_url);

  const [severity] = useState("info");
  const [snackBarMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const saveUser = useStoreActions((a) => a.user.update);
  const history = useHistory();
  const googleOAuthURL = process.env.REACT_APP_COGNITO_GOOGLE_ENDPOINT;

  //receiving message from broadcast channel
  // const broadcastChannel = new BroadcastChannel("new-content-channel");
  // broadcastChannel.onmessage = (event) => {
  //   if (event.data && event.data.type === "newContentAvailable") {
  //     alert("New Content is available Kindly Refresh the page to see it");
  //   }
  // };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      _handleSubmit();
    }
  };

  const handleEmail = (e) => {
    if (e.target.value === "") {
      setEmailErrorMsg("Email Id is required");
    } else {
      setEmailErrorMsg("");
    }
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    if (e.target.value === "") {
      setPassErrorMsg("Password is required");
    } else {
      setPassErrorMsg("");
    }
    setPassword(e.target.value);
  };

  const checkEnrolledAndRedirect = async () => {
    const { data } = await API.get("program", "get-enrolled");
    // const data = [];
    if (data.length === 0) {
      history.push({
        pathname: "/programs",
        state: { dataLength: 0 },
      });
    } else {
      history.push("/user/dashboard");
    }
  };

  const _handleSubmit = async () => {
    if (password === "") {
      return setPassErrorMsg("Password is required");
    }
    if (email === "") {
      return setEmailErrorMsg("Email Id is required");
    }
    if (!validateEmail(email)) {
      return setEmailErrorMsg("Invalid Email");
    }
    try {
      toggleProgress(true);
      const { data, status } = await API.post("user/login", {
        email,
        password,
      });
      if (status === 200) {
        // socket.emit("join_email", { email });
        localStorage.setItem("expiry", data.expiryDate);
        localStorage.setItem("first_name", data.first_name);
        localStorage.setItem("last_name", data.last_name);
        localStorage.setItem("photo_url", data.photo_url);
        localStorage.setItem("newPhotoURL", data.newPhotoURL);
        localStorage.setItem("email", data.email);
        localStorage.setItem("role", data.role);
        // localStorage.setItem("role_id", data.role_id);
        localStorage.setItem("id", data.id);
        // localStorage.setItem("org_id", data.organization_id);
        delete data["tokens"];
        saveUser(data);

        if (returnURL) {
          history.push(returnURL);
        } else {
          checkEnrolledAndRedirect();
        }
      } else {
        setAlertMessage(data.error ? data.error.message : "Error Occured");
        toggleProgress(false);
      }
    } catch (err) {
      console.log({err})
      setAlertMessage("An error occured. Please try again later.");
      toggleProgress(false);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "auto",
          height: "100vh",
        }}
      >
        <LHS />
        {/* <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <div style={{ margin: "11.0625rem 11.625rem" }}> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "auto",
            // marginLeft: "100px",
            width: "100%",
          }}
          onKeyDown={handleKeyDown}
        >
          {/* <div style={{ margin: "10.0625rem 11.625rem" }}> */}
          <div className="mx-auto my-8">
            <h2>Sign in</h2>
            <div style={{ marginTop: "2.1875rem" }}>
              <LinearTextBox
                label="Email"
                onChange={handleEmail}
                errorMessage={emailErrorMsg}
                placeholder="Enter Email Here"
              />
            </div>
            <div style={{ marginTop: "1.25rem" }}>
              <LinearTextBox
                label="Password"
                onChange={handlePassword}
                errorMessage={passErrorMsg}
                placeholder="Enter Password Here"
                type="password"
              />
            </div>
            <div style={{ marginTop: "1.25rem" }}>
              <LinearButton
                type="primary"
                width="21.875rem"
                height="3rem"
                onClick={_handleSubmit}
                disabled={inProgress}
                text={inProgress ? <CircularProgress /> : "Login"}
              ></LinearButton>
            </div>
            <p className="text-box-errorText body-extra-medium-1 margin-top-22">
              {alertMessage}
            </p>
            <div
              className="margin-top-22"
              style={{ color: "var(--link-color)" }}
            >
              <a className="body-small-1" href="/user/forgot-password">
                Forgot Password?
              </a>
            </div>

            <p className="body-small-1 flex gap-2 margin-top-22">
              Don't have an account?
              <div
                style={{ color: "var(--link-color)" }}
                className="cursor-pointer"
                onClick={() => {
                  history.push("/user/signup", {
                    return_url: returnURL,
                  });
                }}
              >
                Sign Up
              </div>
            </p>
          </div>
          <div className="">
            <h5 className="text-center mb-4">Sign in with</h5>

            <div className="flex flex-row justify-center items-center">
              <div className="">
                <a href={googleOAuthURL ? googleOAuthURL : ""}>
                  <div
                    className="py-2 px-8 "
                    style={{
                      border: "1px solid #aaaaaa",
                      borderRadius: 10,
                    }}
                  >
                    <img src={GoogleIcon} alt="Google Icon" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={openSnackBar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackBar(false)}
        TransitionComponent={"SlideTransition"}
      >
        <Alert
          severity={severity}
          sx={{
            width: "100%",
            // color: "white",
            // "& .MuiAlert-icon": {
            //   color: "white",
            // },
          }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UserLogin;
