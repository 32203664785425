import React, {useRef, useState, useEffect} from "react";

const Scale = (props) => {

  const {options, id, answer, setAnswer=()=>{}, sendResponse, selected="", reviewMode=false} = props;

  const radioRef = useRef();

  const [selectedButton, setSelectedButton] = useState();

  const _handleChange = (e) => {
    // setAnswer(e.target.value );
  };

  const selectScaleResponse = (option_id, key) => {
    setAnswer(option_id);
    setSelectedButton(key);
  };

  const handleScaleResponse = async () => {
    // This is responsible for sending our responses to challengeContainer
    const scaleResponse = {
      answer,
      type: "scale_or_rating",
    };
    sendResponse(scaleResponse);
  };

  useEffect(() => {
    handleScaleResponse();
  }, [answer]);

  useEffect(() => {
    // If user has responded for particular question through selected prop
    // And then set the answer
    setSelectedButton(null);
    setAnswer(selected);
    if (selected) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].assessment_options.id ===  Number(selected)) {
          setSelectedButton(i);
          break;
        }
      }
    }
  }, [id]);

  if (!options) return null;

  return (
    <div className="navigation-wrapper">
      {options?.map((option, index) => {
        return (
          <div
            className="body-medium-1 py-3 px-8 flex flex-row items-center gap-3"
            style={{
              border: "1px solid #aaa",
              borderRadius: 10,
              margin: 10,
            }}
            key={index}
          >
            <input
              type="radio"
              name={option.component_id}
              ref={radioRef}
              id={index}
              value={option?.assessment_options.id}
              onChange={_handleChange}
              className="cursor-pointer"
              style={{ height: "15px", width: "15px"}}
              checked={selectedButton === index ? "checked" : false}
              onClick={() => {
                selectScaleResponse(options[index].assessment_options.id, index);
              }}
              disabled={reviewMode}
            />
            <label htmlFor={option?.assessment_options.id} className="cursor-pointer">
              {"  "}
              {option?.assessment_options?.option_text}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default Scale;
