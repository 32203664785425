import React from "react";

export default function ProgramCard({ programName, goalName, actions }) {
  return (
    <div className="grid grid-cols-1 gap-4 mx-2 my-4">
      <div className="bg-white rounded-lg p-4 shadow-xl">
        <div className="body-medium font-semibold">{programName}</div>
        <div className="body-medium mb-4">{goalName}</div>
        <div>{actions}</div>
      </div>
    </div>
  );
}
