import React, { useState, useEffect } from "react";
import useSWR from "swr";
import Table from "../../../../components/Table";
import { API } from "../../../../api";
import edit_svg from "../../../../assests/icons/ic/edit_1.svg";
import delete_svg from "../../../../assests/icons/ic/delete.svg";
import NewModal from "../../../../components/NewModal";
import AddOrganizationModal from "../../../../containers/Organization/AddNewOrgModal";
import LinearButton from "../../../../components/Button";
import LinearTextBox from "../../../../components/TextBox";

export default function AdminListOrganization({
  chooseOrganizationName,
  chooseOrganizationId,
}) {
  const [organizationList, setOrganizationList] = useState([]);
  const [showAddNewOrgModal, setShowAddNewOrgModal] = useState(false);
  const [showRemoveOrgModal, setShowRemoveOrgModal] = useState(false);
  const [showUpdateOrgModal, setShowUpdateOrgModal] = useState(false);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [message, setMessage] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgOwnerEmail, setOrgOwnerEmail] = useState("");
  const [orgCountry, setOrgCountry] = useState("");
  const [currentOrg, setCurrentOrg] = useState({});
  const [updateName, setUpdateName] = useState("");
  const [updateCountry, setUpdateCountry] = useState("");

  const [isActive, setIsActive] = useState("blue");
  const [messageColor, setMessageColor] = useState("green");

  const COUNTRY = [
    { value: "", text: "" },
    { value: "india", text: "India" },
    { value: "usa", text: "USA" },
    { value: "uk", text: "UK" },
    { value: "canada", text: "Canada" },
    { value: "australia", text: "Australia" },
    { value: "singapore", text: "Singapore" },
    { value: "germany", text: "Germany" },
    { value: "hong_kong", text: "Hong Kong" },
    { value: "other", text: "Other" },
  ];

  // const { data: organizationList } = useSWR("organization/all");
  const getOrganizations = async () => {
    const { data } = await API.get("organization/all", "");
    setOrganizationList(data);
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  const getEdit = (org) => {
    const { name, country, id } = org;
    return (
      <div>
        <button
          onClick={(e) => {
            setMessage("");
            setShowUpdateOrgModal(true);
            setCurrentOrg({ name, country, id });
            setUpdateName(name);
            setUpdateCountry(country);
          }}
        >
          <img src={edit_svg} alt="edit image" className="h-14" />
        </button>
      </div>
    );
  };

  const getDelete = () => {
    return (
      <div>
        <button
          onClick={(e) => {
            setMessage("");
            setShowRemoveOrgModal(true);
          }}
        >
          <img src={delete_svg} alt="delete image" className="h-7" />
        </button>
      </div>
    );
  };

  const connectTeam = (org) => {
    const { name, country, id } = org;
    let nameOfOrganization = name;
    let idOfOrganization = id;
    return (
      <div>
        <button
          onClick={(e) => {
            setCurrentOrg({ name, country, id });
            chooseOrganizationName(nameOfOrganization);
            chooseOrganizationId(idOfOrganization);
            setIsActive("red");
          }}
          style={{ color: `${isActive}` }}
        >
          {org?.name.toUpperCase()}
        </button>
      </div>
    );
  };

  useEffect(() => {
    const tableData = [];
    organizationList?.map((org, index) => {
      const orgName = connectTeam(org);
      const orgCountry = org?.country.toUpperCase();
      const active = org.active == true ? "Yes" : "No";
      const edit = getEdit(org);
      const dele = getDelete();
      const element = [orgName, orgCountry, active, edit, dele];
      tableData.push(element);
    });
    setTableHeader(["Name", "Country", "Active", "Edit", "Delete"]);
    setTableBody(tableData);
  }, [organizationList]);

  const addOrg = async (orgName, orgCountry, orgOwnerEmail) => {
    setMessageColor("green");
    if (orgName !== "" && orgCountry !== "" && orgOwnerEmail !== "") {
      try {
        const { data, status } = await API.post("organization/add", {
          name: orgName,
          email: orgOwnerEmail,
          country: orgCountry,
        });
        if (status === 200) {
          setMessage("Organization added successfully");
        } else {
          setMessageColor("red");
          setMessage(data.error ? data.error.message : "Error Occured");
        }
      } catch (error) {
        setMessageColor("red");
        setMessage("An error occured. Please try again later.");
      }
    } else {
      setMessageColor("red");
      setMessage("Please fill the details");
    }
  };

  const removeOrg = () => {
    setMessage("This option is not currently available now");
  };

  const updateOrg = async () => {
    setMessageColor("green");
    const { status } = await API.patch(`organization/update/${currentOrg.id}`, {
      name: updateName,
      country: updateCountry,
    });
    if (status === 500 || status === 404 || status === 403) {
      setMessageColor("red");
      setMessage("An error occured");
    } else {
      setMessage("Organization updated successfully");
    }
  };

  const onClose = () => {
    setMessage("");
    setShowAddNewOrgModal(false);
    getOrganizations();
  };

  return (
    <div style={{ backgroundColor: "var(--blue-de-france-10" }} className="m-4">
      <div className={`flex flex-row justify-between items-center m-${4}`}>
        <button
          className="primary-button button-small text-white p-4"
          style={{ backgroundColor: "#003366", borderRadius: "10px" }}
          onClick={() => {
            setMessage("");
            setShowAddNewOrgModal(true);
          }}
        >
          Add New Organization
        </button>
      </div>

      <Table header={tableHeader} body={tableBody} />

      {showAddNewOrgModal && (
        <AddOrganizationModal
          country={COUNTRY}
          msg={message}
          addOrg={addOrg}
          onClose={onClose}
          orgName={orgName}
          orgOwnerEmail={orgOwnerEmail}
          orgCountry={orgCountry}
          msgColor={messageColor}
        />
      )}

      {showUpdateOrgModal && (
        <NewModal
          heading={<div className="mx-10">Update Organization</div>}
          body={
            <div>
              <div className="py-4 px-8 rounded-xl shadow-lg flex flex-col gap-4 bg-white">
                <div>
                  <LinearTextBox
                    name="name"
                    onChange={(e) => {
                      setUpdateName(e.target.value);
                      setMessage("");
                    }}
                    label="Organization name"
                    placeholder="Enter Organization Name Here"
                    defaultValue={currentOrg.name}
                  />
                </div>
                <div className="flex flex-col w-full sm:w-2/5">
                  <label className="body-small-1">Country</label>
                  <select
                    onChange={(e) => {
                      setUpdateCountry(e.target.value);
                      setMessage("");
                    }}
                    name="country"
                    className="input-box body-small-1"
                    defaultValue={currentOrg.country}
                  >
                    {COUNTRY.map((option, index) => {
                      return (
                        <option value={option.value} key={index}>
                          {option.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          }
          footer={
            <div className="flex flex-col justify-center gap-4 items-center mx-16">
              <LinearButton text="Update" onClick={updateOrg} />
              <h6 style={{ color: `${messageColor}` }}>{message}</h6>
            </div>
          }
          onClose={() => {
            setShowUpdateOrgModal(false);
            getOrganizations();
          }}
        />
      )}

      {showRemoveOrgModal && (
        <NewModal
          heading={<div className="mx-10">Are you sure?</div>}
          body={
            <div className="mx-16">
              Removing organization is currently not available!
            </div>
          }
          footer={
            <div className="flex flex-col justify-center gap-4 items-center mx-16">
              <h6 style={{ color: `${messageColor}` }}>{message}</h6>
            </div>
          }
          onClose={() => setShowRemoveOrgModal(false)}
        />
      )}
    </div>
  );
}
