import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../../api";
import "./index.css";
import { CircularProgress } from "@material-ui/core";
import ActivityRowBox from "../ActivityRowBox";

const ActivityList = (props) => {
  const [activityList, setactivityList] = useState([]);
  const [loader, toggleLoader] = useState(false);
  const [isAPIError, toggleAPIError] = useState(false);

  useEffect(() => {
    toggleLoader(true);
    const fetchData = async () => {
      try {
        const { data, status } = await API.get(
          "activity",
          `day-list/${props.dayAdjust}?program_id=${props.selectedProgramID}`
        );
        if (status === 200) {
          setactivityList(data);
        } else toggleAPIError(true);
      } catch (err) {
        console.log(err);
        toggleAPIError(true);
      }
      toggleLoader(false);
    };
    if (!props.waitForUnpause) {
      fetchData();
    }
  }, [props.dayAdjust, props.selectedProgramID, props.waitForUnpause]);

  const history = useHistory();

  const goToActivity = (activity) => {
    history.push(`/activity/id/${activity.activity_id}`, {
      goal_id: activity.goal_id,
      activity_name: activity.activity?.name,
    });
  };

  const goToRevision = (revision) => {
    history.push(`/revision/id/${revision.revision_id}`, {
      revision_id: revision.revision_id,
      goal_id: revision.goal_id,
    });
  };

  return (
    <div>
      {loader && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}
      {/* API Error Msg */}
      {isAPIError && (
        <ActivityRowBox
          missed={true}
          message="We are unable to fetch Activities."
        />
      )}
      {/* Weekend Error Msg */}
      {activityList?.isWeekend && (
        <ActivityRowBox
          missed={true}
          message="Linear is off on Weekends! No Activities for Today!"
        />
      )}
      {/* Empty ActivityList Error Msg */}
      {!activityList?.isWeekend &&
        activityList?.activityList?.length === 0 &&
        activityList?.revisionList?.length === 0 && (
          <ActivityRowBox
            missed={true}
            message="Please enroll into a program to start learning"
          />
        )}

      {/* Activity List */}
      {activityList?.activityList?.map((activity) => {
        return (
          <ActivityRowBox
            missed={activity.missed}
            completed={activity.completed}
            enabled={activity.enabled}
            message={activity.activity?.name}
            id={activity.activity_id}
            key={activity.activity_id}
            onClick={() =>
              (activity.enabled || activity.completed) && goToActivity(activity)
            }
          />
        );
      })}

      {/* Revision List */}
      {activityList?.revisionList?.map((revision) => {
        return (
          <ActivityRowBox
            missed={revision.missed}
            completed={revision.completed}
            enabled={revision.enabled}
            message={revision.revision?.name}
            id={revision.revision_id}
            key={revision.revision_id}
            onClick={() =>
              (revision.enabled || revision.completed) && goToRevision(revision)
            }
          />
        );
      })}
    </div>
  );
};

export default ActivityList;
