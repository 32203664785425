import React, { useEffect, useState } from "react";
import "./index.css";
import DragList from "react-list-drag-and-drop/lib/RLDD";

const ReviewOrder = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    }
    shuffleArray(props.options);
    setOptions(props.options);
  }, [props.options]);

  const handleDragListChange = (newOrder) => {
    setOptions(newOrder);
  };

  if (!options) return null;

  return (
    <div>
      <div>
        <DragList
          items={options}
          itemRenderer={(option) => {
            return (
              <div className="z-10 option body-medium-1 p-6 my-4">
                {option?.mcq_option?.option_text}
              </div>
            );
          }}
          onChange={handleDragListChange}
        />
      </div>
    </div>
  );
};

export default ReviewOrder;
