const LinearTextArea = ({
  placeholder = "Enter Text",
  label = "Label",
  errorMessage = "",
  onChange = () => {},
  disabled = false,
  name = "",
  type = "text",
  columns = 10,
  rows = 3,
  style = {},
  value="",
}) => {
  const className =
    "text-box-primary" +
    (errorMessage !== "" ? " text-box-error" : " text-box-default");
  return (
    <>
      <textarea
        cols={columns}
        rows={rows}
        // style={style}
        type={type}
        //className={className}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        disabled={disabled}
        style={{
          border: "0.0625rem solid var(--form-border)",
          resize: "none",
          padding: "10px",
          width: style.width,
          backgroundColor: "var(--form-background)",
        }}
        value={value}
      ></textarea>
      <p className="text-box-errorText body-extra-small-1">{errorMessage}</p>
    </>
  );
};

export default LinearTextArea;
