import React, { useEffect, useState } from "react";
import { API } from "../../../../api";

const AdminProgramList = () => {
  const [programList, setProgramList] = useState();

  useEffect(() => {
    async function getProgramList() {
      const { data } = await API.get("program", "all");
      setProgramList(data);
    }
    getProgramList();
  }, []);
  return (
    <div>
      {programList?.map((program, index) => {
        return (
          <div key={index} className="p-4 shadow-xl m-4 ">
            <div className="text-2xl">{program.name}</div>
            <div>
              <span className="text-xl text-blue-700">Description:</span>{" "}
              {program.description}
            </div>
            <div>
              <span className="text-xl text-blue-700">Number of weeks:</span>{" "}
              {program.number_of_weeks}
            </div>
            <div>
              <span className="text-xl text-blue-700">Proficiency:</span>{" "}
              {program.proficiency}
            </div>
            <div>
              <span className="text-xl text-blue-700">Program level:</span>{" "}
              {program.program_level}
            </div>
            <div>
              {program.goals?.map((goal, index) => {
                return <div key={goal.id}>{goal.name}</div>;
              })}
            </div>
            <div>
              <span className="text-xl text-blue-700">Status:</span>{" "}
              {program.active ? "Active" : "Inactive"}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AdminProgramList;
