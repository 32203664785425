import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Todos from "./Todos";
import Notes from "./Notes";
import SavedTips from "./Tips";
import SavedReadings from "./Reading";
import { useHistory } from "react-router-dom";
import JournalScreenMobile from "./JournalHome/Mobile";

const UserJournal = () => {
  const history = useHistory();
  return (
    <Switch>
      <Route exact path="/journal" component={JournalScreenMobile} />
      <Route exact path="/journal/todo" component={Todos} />
      <Route exact path="/journal/notes" component={Notes} />
      <Route exact path="/journal/tips" component={SavedTips} />
      <Route exact path="/journal/reading" component={SavedReadings} />
    </Switch>
  );
};

export default UserJournal;
