import React, { useState } from "react";
import { API } from "../../../../api";
import LinearTextBox from "../../../../components/TextBox";
import LinearButton from "../../../../components/Button";
import Alert from "@material-ui/lab/Alert";
import { CircularProgress } from "@material-ui/core";

const CHALLENGE_TYPES = [
  { text: "", value: "" },
  { text: "Drill", value: "drill" },
  { text: "Challenge", value: "challenge" },
  { text: "Day", value: "day" },
];

const AdminAddChallenge = () => {
  const [challengeData, setChallengeData] = useState({
    code: "",
    name: "",
    description: "",
    calender_required: true,
    type: "",
    time_required_in_minutes: "",
    reward_points: "",
  });

  const [inProgress, toggleProgress] = useState(false);
  const [alertVisible, toggleAlertVisibility] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const _handleChange = (e) => {
    setChallengeData({ ...challengeData, [e.target.name]: e.target.value });
    toggleAlertVisibility(false);
  };

  const _handleSubmit = async (e) => {
    e.preventDefault();
    try {
      toggleProgress(true);
      const { data, status } = await API.post("challenge/add", challengeData);
      toggleAlertVisibility(true);
      toggleProgress(false);
      if (status === 200) {
        setAlertMessage("Challenge has been successfully added");
        setAlertSeverity("info");
      } else {
        setAlertMessage(data.error ? data.error.message : "Error Occured");
        setAlertSeverity(data.error ? "error" : "info");
      }
    } catch (err) {
      setAlertMessage("An error occured. Please try again later.");
      setAlertSeverity("error");
      toggleAlertVisibility(true);
      toggleProgress(false);
    }
  };

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="shadow-xl p-36">
        <div className="flex flex-col gap-8 m-4">
          <div>
            <h3>Add a challenge</h3>
          </div>
          <div>
            <LinearTextBox
              placeholder="Enter Code"
              name="code"
              onChange={_handleChange}
              label="Enter Challenge Code"
            />
          </div>
          <div>
            <LinearTextBox
              placeholder="Enter Name"
              name="name"
              onChange={_handleChange}
              label="Enter Challenge Name"
            />
          </div>
          <div>
            <LinearTextBox
              placeholder="Enter Description"
              name="description"
              onChange={_handleChange}
              label="Enter Challenge Description"
            />
          </div>
          <div>
            <div className="body-small-1">Challenge Type</div>
            <select onChange={_handleChange} className="p-4" name="type">
              {CHALLENGE_TYPES?.map((type, index) => {
                return (
                  <option value={type.value} key={index}>
                    {type.text}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <LinearTextBox
              placeholder="Enter Time required"
              name="time_required_in_minutes"
              onChange={_handleChange}
              label="Enter Time required in minutes"
            />
          </div>
          <div>
            <LinearTextBox
              placeholder="Enter Reward Points"
              name="reward_points"
              onChange={_handleChange}
              label="Enter Challenge Reward Points"
            />
          </div>
          <LinearButton
            text={inProgress ? <CircularProgress /> : "Add Challenge"}
            width="10rem"
            onClick={_handleSubmit}
          />

          {alertVisible && (
            <Alert style={{ marginTop: "1.5rem" }} severity={alertSeverity}>
              {alertMessage}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminAddChallenge;
