import React, { useState } from "react";
import { API } from "../../../../api";
import LinearButton from "../../../../components/Button";
import LinearTextBox from "../../../../components/TextBox";

const COUNTRY = [
  { value: "", text: "" },
  { value: "india", text: "India" },
  { value: "usa", text: "USA" },
  { value: "uk", text: "UK" },
  { value: "canada", text: "Canada" },
  { value: "australia", text: "Australia" },
  { value: "singapore", text: "Singapore" },
  { value: "germany", text: "Germany" },
  { value: "hong_kong", text: "Hong Kong" },
];

export default function AdminAddOrganization() {
  const [organizationName, setOrganizationName] = useState("");
  const [organizationCountry, setOrganizationCountry] = useState("");
  const [organizationOwnerEmail, setOrganizationOwnerEmail] = useState("");
  const [message, setMessage] = useState("");

  const _handleSubmit = async () => {
    const { status } = await API.post("organization/add", {
      name: organizationName,
      country: organizationCountry,
      email: organizationOwnerEmail,
    });
    if (status === 200) {
      setMessage("Organization added successfully");
    } else {
      setMessage("An error occured");
    }
  };

  return (
    <div
      className="h-screen flex flex-row justify-center items-center"
      style={{ backgroundColor: "var(--blue-de-france-10)" }}
    >
      <div className="py-4 px-8 rounded-xl shadow-lg flex flex-col gap-4 bg-white">
        <h4>Add new Organization</h4>
        <div>
          <LinearTextBox
            name="name"
            onChange={(e) => {
              setOrganizationName(e.target.value);
              setMessage("");
            }}
            label="Organization name"
            placeholder="Enter Organization Name Here"
          />
        </div>
        <div>
          <LinearTextBox
            name="email"
            onChange={(e) => {
              setOrganizationOwnerEmail(e.target.value);
              setMessage("");
            }}
            label="Organization Owner Email"
            placeholder="Enter Organization Owner Email Here"
          />
        </div>
        <div className="flex flex-col w-full sm:w-2/5">
          <label className="body-small-1">Country</label>
          <select
            onChange={(e) => {
              setOrganizationCountry(e.target.value);
              setMessage("");
            }}
            name="country"
            className="input-box body-small-1"
          >
            {COUNTRY.map((option, index) => {
              return (
                <option value={option.value} key={index}>
                  {option.text}
                </option>
              );
            })}
          </select>
        </div>
        <LinearButton text="Add Org" onClick={_handleSubmit} />
        {message}
      </div>
    </div>
  );
}
