
import { useLocation } from "react-router";
import QuizContainer from "../../Activity/StrapiGetActivity/NewGetActivity/Quiz";

export default function NewRevision (props) {
    const {revision_id} = props.match.params;
    const location = useLocation();
    const goal_id = location.state?.goal_id;
    const fetchUrl = {
        path: `revision/get-questions`,
        params: `${goal_id}/${revision_id}`
    };

    const completionUrl = {
        path: `revision/complete/${revision_id}`,
        body: {
            goal_id
        }
    };

    const reviewUrl =`/revision/review/${revision_id}`;

    const submitUrl = {
        path: `revision/response`,
    };

    const historyUrl = {
        path: "/revision/complete",
    }

    return <QuizContainer 
        fetchUrl={fetchUrl} 
        completionUrl={completionUrl} 
        historyUrl={historyUrl} 
        id={revision_id} 
        type="revision"
        goal_id={goal_id}
        submitUrl={submitUrl}
        reviewUrl={reviewUrl}
    />
} 