import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorPage from "../../404RedirectPage";
import AdminAddReading from "./Add";
import AdminReadingList from "./List";

const AdminReadingRoutes = () => {
  return (
    <Switch>
      <Route exact path="/admin/reading/list" component={AdminReadingList} />
      <Route exact path="/admin/reading/add" component={AdminAddReading} />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default AdminReadingRoutes;
