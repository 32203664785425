import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorPage from "../404RedirectPage";
import ProfileDashboard from "./ProfileDashboard";

const UserProfileRoutes = () => {

  return (
    <Switch>
      <Route exact path="/profile/" component={ProfileDashboard} />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default UserProfileRoutes;
