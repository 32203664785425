// import { useStoreActions } from "easy-peasy";
import React, { useState } from "react";
import { API } from "../../../api";
import { useHistory } from "react-router";
import LHS from "../../../components/LHS";
import EmailContainer from "../../../containers/EmailContainer";
import PasswordContainer from "../../../containers/PasswordContainer";
import ForgotPasswordModal from "../../../containers/ForgotPassword/ForgotPasswordModal";
import useScreenSize from "../../../hooks/useScreenSize";
import { validatePassword } from "../../../lib/helpers";

const ForgotPassword = () => {
  const [loginData, setLoginData] = useState({
    email: "",
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [inProgress, toggleProgress] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [otp, setOTP] = useState("");
  const [otpErrMsg, setOTPErrMsg] = useState("");
  const [resendErrMsg, setResendErrMsg] = useState("");

  const [password, setPassword] = useState("");
  const [passErrorMsg, setPassErrorMsg] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPassErrorMsg, setConfirmPassErrorMsg] = useState("");
  const [passContErrorMsg, setPassContErrorMsg] = useState("");

  // Modes
  const [emailMode, setEmailMode] = useState(true);
  const [passwordMode, setPasswordMode] = useState(false);
  const [isSuccess, toggleSuccess] = useState(false);

  const { isMobile } = useScreenSize();

  // const saveUser = useStoreActions((a) => a.ufser.update);
  const history = useHistory();

  const handleEmail = (e) => {
    if (e.target.value === "") {
      setEmailErrorMsg("Email Id is required");
    } else {
      setEmailErrorMsg("");
    }
    console.log({ email: e.target.value });
    setEmail(e.target.value);
  };

  const handleOTP = (e) => {
    if (e.target.value === "") {
      setOTPErrMsg("OTP is required");
    } else {
      setOTPErrMsg("");
    }
    setOTP(e.target.value);
  };

  const handlePassword = (e) => {
    let password = e.target.value;
    if (password === "") {
      setPassErrorMsg("Password is required");
      return;
    }
    if (!validatePassword(password)) {
      setPassErrorMsg(
        "Password must be at least 8 characters with uppercase, numeric, and special characters."
      );
      return;
    }
    setPassErrorMsg("");
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    let password = e.target.value;
    if (password === "") {
      setConfirmPassErrorMsg("Confirm Password is required");
      return;
    }
    if (!validatePassword(password)) {
      setConfirmPassErrorMsg(
        "Password must be at least 8 characters with uppercase, numeric, and special characters."
      );
      return;
    }
    setConfirmPassErrorMsg("");
    setConfirmPassword(e.target.value);
  };

  const _handleResend = async () => {
    try {
      const { data, status } = await API.post(
        "user/forgot-password",
        loginData
      );
      if (data.error) {
        setResendErrMsg(data.error.message);
        return;
      }

      if (status === 200) {
        setResendErrMsg("A verification code has been sent to your email");
      }
    } catch (error) {
      setResendErrMsg(
        "An error occured while trying to resend code. Please try again later."
      );
      console.log({ error });
    }
  };

  const _handleEmailSubmit = async () => {
    try {
      if (email === "") {
        setEmailErrorMsg("Email is required");
        return;
      }
      toggleProgress(true);
      const { data, status } = await API.post("user/forgot-password", {
        email,
      });

      if (data.error) {
        setAlertMessage(data.error.message);
        toggleProgress(false);
        return;
      }

      if (status === 200) {
        setAlertMessage("A verification code has been sent to your email");
      } else {
        setAlertMessage("An Error Occured. Please try again later");
        toggleProgress(false);
        return;
      }
      setTimeout(() => {
        setAlertMessage("");
        toggleProgress(false);
        setEmailMode(false);
        setPasswordMode(true);
        // history.push("/user/reset-password");
      }, 2000);
    } catch (error) {
      setAlertMessage("An error occured. Please try again later.");
      console.log({ error });
      toggleProgress(false);
    }
  };

  const _handlePasswordSubmit = async () => {
    if (email === "") {
      setPassContErrorMsg("We couldn't fetch email address. Please try again.");
      return;
    }

    if (otp === "") {
      setPassContErrorMsg("We couldn't verify OTP. Please try again.");
      return;
    }

    if (password === "") {
      setPassErrorMsg("Password cannot be empty.");
      return;
    }

    if (confirmPassword === "") {
      setConfirmPassErrorMsg("Confirm Password cannot be empty.");
      return;
    }

    if (password !== confirmPassword) {
      setPassContErrorMsg("Password and Confirm Password doesn't match");
      return;
    }

    try {
      toggleProgress(true);
      const { data, status } = await API.post("user/reset-password", {
        email,
        password,
        code: otp,
      });
      if (status === 200) {
        toggleSuccess(true);
        toggleProgress(false);
      } else {
        setPassContErrorMsg(data.error ? data.error.message : "Error Occured");
        toggleProgress(false);
      }
    } catch (e) {
      setPassContErrorMsg("An error occured. Please try again later.");
      toggleProgress(false);
    }
  };

  return (
    <div>
      {/* <Header /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // margin: "auto",
          // width: "100%",
          height: "100vh",
        }}
      >
        <LHS />
        <div
          style={
            isMobile
              ? {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                width: "100vw",
                marginLeft: 10,
                marginRight: 10,
              }
              : {}
          }
        >
          {emailMode && (
            <EmailContainer
              handleEmail={handleEmail}
              emailErrorMsg={emailErrorMsg}
              _handleEmailSubmit={_handleEmailSubmit}
              inProgress={inProgress}
              alertMessage={alertMessage}
            />
          )}
          {passwordMode && (
            <PasswordContainer
              handlePassword={handlePassword}
              handleConfirmPassword={handleConfirmPassword}
              _handlePasswordSubmit={_handlePasswordSubmit}
              handleOTP={handleOTP}
              otpErrMsg={otpErrMsg}
              inProgress={inProgress}
              passContErrorMsg={passContErrorMsg}
              passErrorMsg={passErrorMsg}
              confirmPassErrorMsg={confirmPassErrorMsg}
            />
          )}
          {isSuccess && <ForgotPasswordModal />}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
