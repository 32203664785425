import React, { useEffect, useState } from "react";

export default function useActivityState() {
  const [activityState, setActivityState] = useState([]);

  function addNewResponse({
    id,
    correctAnswer,
    isUserCorrect,
    userResponse,
    comment,
    type,
  }) {
    activityState.push({
      id,
      correctAnswer,
      isUserCorrect,
      userResponse,
      comment,
      type,
    });
  }

  return { activityState, addNewResponse };
}
