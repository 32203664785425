import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorPage from "../../404RedirectPage";
import AddActivity from "./Add";
import AdminGetActivity from "./Get";
import AdminActivityList from "./List";
import AdminAllActivity from "./ListAll";
import AdminPreviewActivity from "./Preview";

const AdminActivityRoutes = () => {
  return (
    <Switch>
      <Route exact path="/admin/activity/list" component={AdminActivityList} />
      <Route exact path="/admin/activity/add" component={AddActivity} />
      <Route exact path="/admin/activity/all" component={AdminAllActivity} />
      <Route
        exact
        path="/admin/activity/preview/:activity_id"
        component={AdminPreviewActivity}
      />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default AdminActivityRoutes;
