import { Switch, Route } from "react-router-dom";
import GetRevision from "./GetRevision";
import NewRevision from "./NewRevision";
import ActivityComplete from "../Activity/ActivityComplete";
import ReviewRevision from "./ReviewRevision";

export default function RevisionRouter() {
  return (
    <Switch>
      <Route exact path="/revision/oldrevision" component={GetRevision} />
      <Route exact path="/revision/id/:revision_id" component={NewRevision} />
      <Route exact path="/revision/complete" component={ActivityComplete} />
      <Route
        path="/revision/review/:revision"
        component={ReviewRevision}
      />
    </Switch>
  );
}
