import React, { useState, useEffect } from "react";
import { API } from "../../../api";
import tip_bulb from "../../../assests/icons/tip_bulb.svg";
import no_readings from "../../../assests/icons/no_readings.svg";
import ArrowDownward from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpward from "@material-ui/icons/KeyboardArrowUp";

const SavedReadings = () => {
  const [savedReadings, setSavedReadings] = useState();
  const [clicked, setClicked] = useState(false);

  async function getSavedReadings() {
    const { data } = await API.get("user-data", "saved-readings");
    console.log(data);
    setSavedReadings(data);
  }

  useEffect(() => {
    getSavedReadings();
  }, []);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  if (!savedReadings || savedReadings?.length === 0)
    return (
      <div className="flex flex-row justify-center items-center h-screen -my-32">
        <img src={no_readings} />
      </div>
    );

  return (
    <div>
      {savedReadings?.map((reading, index) => {
        return (
          <div
            key={index}
            className="p-4"
            style={{
              borderBottomColor: "#e6e6e6",
              borderBottomStyle: "solid",
              borderBottomWidth: 1,
            }}
            onClick={() => toggle(index)}
          >
            {/* <div className="flex flex-row justify-between items-center"> */}
            <div className="flex flex-row justify-between gap-4">
              <div className="flex flex-row gap-4 items-center">
                <img src={tip_bulb} />
                <div>
                  <h6>{reading.reading?.reading_title}</h6>
                  {clicked === index ? (
                    <div className="mt-4">{reading.reading?.reading_text}</div>
                  ) : null}
                </div>
              </div>

              {clicked === index ? <ArrowUpward /> : <ArrowDownward />}
            </div>
          </div>
          //   </div>
        );
      })}
    </div>
  );
};

export default SavedReadings;
