import { useStoreState } from "easy-peasy";
import React, { useState } from "react";
import { API } from "../../../api";
import LinearTextArea from "../../../components/TextArea";
import LinearTextBox from "../../../components/TextBox";

const Customer = () => {
  const user = useStoreState((s) => s.user.data);

  const [billingDetails, setBillingDetails] = useState({
    first_name: "",
    last_name: "",
    line1: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });

  //   const handleChange = (e) => {
  //     const newList = e.target.value.split("\n");
  //     const result = newList.filter((email) => email != "");
  //     setTeamList(result);
  //   };
  const handleChange = (e) => {
    setBillingDetails({ ...billingDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    // const { data, status } = await API.post(
    //   "chargebee/create-customer",
    //   billingDetails
    // );
    // console.log(data);
    console.log(billingDetails);
    console.log(
      Object.values(billingDetails).some((details) => details === "")
    );
  };
  return (
    <div>
      You are logged in as {user?.email} <br />
      Customer Billing Details
      {/* <div>Add a list of email address(Each line counts as new address)</div>
      <LinearTextArea
        rows={10}
        columns={20}
        name="team_list"
        onChange={handleChange}
      /> */}
      <div>
        <LinearTextBox
          name="first_name"
          label="Enter first name"
          placeholder="John"
          onChange={handleChange}
        />
        <LinearTextBox
          name="last_name"
          label="Enter last name"
          placeholder="Doe"
          onChange={handleChange}
        />
        <LinearTextBox
          name="line1"
          label="Enter Address line 1"
          placeholder="Dwarka"
          onChange={handleChange}
        />
        <LinearTextBox
          name="city"
          label="Enter city"
          placeholder="New Delhi"
          onChange={handleChange}
        />
        <LinearTextBox
          name="state"
          label="Enter state"
          placeholder="Delhi"
          onChange={handleChange}
        />
        <LinearTextBox
          name="country"
          label="Enter Country"
          placeholder="IN"
          onChange={handleChange}
        />
        <LinearTextBox
          name="zip"
          label="Enter zip code"
          placeholder="112233"
          onChange={handleChange}
        />
      </div>
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default Customer;
