import React, { useState, useEffect } from "react";
import "./index.css";

const MCQ = (props) => {
  const { options, isUserCorrect, correctAnswer, selected, answer, setAnswer = () => { }, buttonLoading = false } = props;
  const [selectedButton, setSelectedButton] = useState();
  //const [alignVertical, setAlignVertical] = useState(false);

  // useEffect(() => {
  //   for (const option of options) {
  //     if (option.option_text.length > 50) setAlignVertical(true);
  //   }
  //   return () => {
  //     setAlignVertical(false);
  //   };
  // }, [options]);

  const selectMcqResponse = (option_id, key) => {
    setAnswer(option_id);
    setSelectedButton(key);
  };

  useEffect(() => {
    handleMcqResponse();
  }, [answer]);

  useEffect(() => {
    setSelectedButton(null);
    setAnswer(null);
    if (selected) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].mcq_option.id === selected) {
          setSelectedButton(i);
          break;
        }
      }
    }
  }, [props.id]);

  const handleMcqResponse = async () => {
    const mcqResponse = {
      answer,
      type: "mcq",
    };
    props.sendResponse(mcqResponse);
  };

  if (!options) return null;

  return (
    <div>
      <div>
        {/* <div className="flex flex-col"> */}
        <div className="grid md:grid-cols-2 gap-4 sm:grid-cols-1 mb-4 flex-wrap">
          {options.map((option, index) => {
            return (
              <button
                className="option body-medium-1 px-6 py-3 my-2"
                key={index}
                style={{
                  // backgroundColor:
                  //   index === selectedButton ? "#00aacc" : "#bbeeff",
                  backgroundColor:
                    isUserCorrect !== undefined
                      ? options[index]?.mcq_option?.id === correctAnswer?.id
                        ? "#09B746" //green
                        : index === selectedButton
                          ? "#C82020" //red
                          : "#ffffff" //non selected color
                      : index === selectedButton
                        ? "#00aacc" //selected color
                        : "#ffffff", //non selected color
                  color:
                    isUserCorrect !== undefined
                      ? options[index]?.mcq_option?.id === correctAnswer?.id
                        ? "white" //green
                        : index === selectedButton
                          ? "white" //red
                          : "black" //non selected color
                      : index === selectedButton
                        ? "white" //selected color
                        : "black", //non selected color
                }}
                onClick={() => {
                  if (!(buttonLoading || correctAnswer)) {
                    selectMcqResponse(options[index]?.mcq_option?.id, index)
                  }
                }}
              >
                {option?.mcq_option?.option_text}
              </button>
            );
          })}
        </div>

        {/* <button
          className="option"
          id="a"
          style={{
            backgroundColor: id === selectedButton ? "white" : "blue",
          }}
          onClick={() => selectMcqResponse(options[0]?.id, "a")}
        >
          {options[0]?.option_text}
        </button>
        <button
          className="option"
          id="b"
          style={{
            backgroundColor: id === selectedButton ? "white" : "blue",
          }}
          onClick={() => selectMcqResponse(options[1]?.id, "b")}
        >
          {options[1]?.option_text}
        </button>
        <button
          className="option"
          id="c"
          style={{
            backgroundColor: id === selectedButton ? "white" : "blue",
          }}
          onClick={() => selectMcqResponse(options[2]?.id, "c")}
        >
          {options[2]?.option_text}
        </button>
        <button
          className="option"
          id="d"
          style={{
            backgroundColor: id === selectedButton ? "white" : "blue",
          }}
          onClick={() => selectMcqResponse(options[3]?.id, "d")}
        >
          {options[3]?.option_text}
        </button> */}
      </div>
    </div>
  );
};
export default MCQ;
