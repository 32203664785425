import React, {useState, useEffect} from "react";
import { Redirect, Route } from "react-router-dom";
import { getAdminAuth } from "../../lib/auth";
import { useStoreActions } from "easy-peasy";

export default function AdminPrivateRoute({ ...props }) {
  const [isAuthenticated, setAuthenticated] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const saveUser = useStoreActions((a) => a.user.update);

  useEffect(() => {
    async function checkAuth() {
      try {
        const response = await getAdminAuth(saveUser);
        setAuthenticated(response);
      } catch (err) {
        console.log(err);
        setAuthenticated(false);
      } finally {
        setLoading(false);
      }
    }
    checkAuth();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Show loading spinner or screen until the API call is done
  }

  return (
    <Route {...props}>
      {!isAuthenticated && <Redirect to="/user/login" />}
    </Route>
  );
}
