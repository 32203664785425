import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AdministratorDashboard from "./AdministratorDashboard";
import { useStoreState } from "easy-peasy";
import ErrorPage from "../404RedirectPage";
import UserDetailsDashBoard from "./User/UserDetails/UserDetails";

const AdminHubRoutes = () => {
  const history = useHistory();
  const userData = useStoreState((state) => state.user.data);
  useEffect(() => {
    const userRoleId = userData?.role_id;
    if (![2, 3, 4, 5, 6].includes(userRoleId)) {
      history.push("/user/dashboard");
    }
  }, [userData]);

  return (
    <Switch>
      <Route exact path="/administrator/" component={AdministratorDashboard} />
      <Route
        exact
        path="/administrator/user-details"
        component={UserDetailsDashBoard}
      />
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default AdminHubRoutes;
