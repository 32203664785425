import React, { useEffect, useState } from "react";

export default function useChallengeState() {
  const [challengeState, setChallengeState] = useState([]);

  // All user responses are stored here

  function addNewResponse({
    id,
    challenge_step_id,
    correctAnswer,
    isUserCorrect,
    userResponse,
    type,
  }) {
    challengeState.push({
      id,
      challenge_step_id,
      correctAnswer,
      isUserCorrect,
      userResponse,
      type,
    });
  }
  return { challengeState, addNewResponse };
}