import React, { useState, useEffect } from "react";
import { useStoreActions } from "easy-peasy";
import { useHistory, useLocation } from "react-router";
import { API } from "../../../api";
import LHS from "../../../components/LHS";
// import axios from "axios";

const PostLogin = () => {
  const [inProgress, toggleProgress] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const saveUser = useStoreActions((a) => a.user.update);
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const checkEnrolledAndRedirect = async () => {
    try {
      const { data } = await API.get("program", "get-enrolled");
      if (data.length === 0) {
        history.push("/programs");
      } else {
        history.push("/user/dashboard");
      }
    } catch (err) {
      setAlertMessage(
        "An error occured while redirecting. Please try again later."
      );
      toggleProgress(false);
    }
  };

  const getUserData = async () => {
    try {
      const { data, status } = await API.post(`user/add-user-by-provider`, {});
      if (status === 200) {
        localStorage.setItem("email", data.email);
        localStorage.setItem("id", data.id);
        localStorage.setItem("org_id", data.organization_id);
        localStorage.setItem("expiry", data.expiryDate);
        localStorage.setItem("first_name", data.first_name);
        localStorage.setItem("last_name", data.last_name);
        localStorage.setItem("photo_url", data.photo_url);
        localStorage.setItem("newPhotoURL", data.newPhotoURL);
        delete data["tokens"];
        saveUser(data);
        checkEnrolledAndRedirect();
      }
    } catch (err) {
      setAlertMessage(
        "An error occured while fetching your data. Please try again later."
      );
      toggleProgress(false);
    }
  };

  const verifyCode = async (code) => {
    try {
      toggleProgress(true);
      const { data, status } = await API.post("user/idToken", { code });
      if (status === 200) {
        const idToken = data.tokens.id_token;
        localStorage.setItem("token", idToken);
        getUserData();
      } else {
        setAlertMessage(data.error ? data.error.message : "Error Occured");
        toggleProgress(false);
      }
    } catch (err) {
      setAlertMessage("An error occured. Please try again later.");
      toggleProgress(false);
    }
  };

  /** POST LOGIN CODE for Implicit Grant Method */
  /*
	const { data, status } = await axios.post(
		`${process.env.REACT_APP_API_ENDPOINT}/user/add-user-by-provider`,
		{},
		{
		  headers: {
			Authorization: `Bearer ${id_token}`,
			"Content-Type": "application/json",
		  },
		}
	);
	*/
  useEffect(() => {
    const code = query.get("code");
    verifyCode(code);
  }, []);

  return (
    <div>
      {/* <Header /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100vh",
          margin: "auto",
        }}
      >
        <LHS />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "100px",
            width: "100%",
          }}
        >
          <div style={{ margin: "15.0625rem 11.625rem" }}>
            {inProgress ? (
              <h4>Please wait while we are verifying your login.</h4>
            ) : (
              <h4>{alertMessage}</h4>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostLogin;
