import React, { useEffect, useState } from "react";
import { API } from "../../../api";

const GroupLeaderboard = () => {
  const [leaderboard, setLeaderboard] = useState();

  useEffect(() => {
    async function fetchData() {
      const response = await API.get("group/get-user-points", 1);
      console.log(response);
      setLeaderboard(response);
    }
    fetchData();
  }, []);

  if (!leaderboard) return null;

  return (
    <div>
      <div className="w-3/4 mx-auto text-2xl my-8">Group Leaderboard</div>
      <div>
        {leaderboard.map((user, index) => {
          return (
            <div
              className="w-3/4 p-4 bg-blue-300 mx-auto text-xl flex flex-row justify-between border-b-2"
              style={{
                //#ffd700 gold
                //#b3cee5 silver
                //#d2691e bronze
                // backgroundColor:
                //   index === 0
                //     ? "#ffd700"
                //     : index === 1
                //     ? "#b3cee5"
                //     : index === 2
                //     ? "#d2691e"
                //     : "#bbeeff",
                backgroundColor: index % 2 === 0 ? "#ffffff" : "#dddddd",
              }}
            >
              <div className="flex flex-row flex-start gap-4">
                <div style={{ fontFamily: "ClanOT-NarrowMedium" }}>
                  {index + 1}
                </div>
                <div className="text-2xl">
                  {user.user?.first_name} {user.user?.last_name}
                </div>
              </div>
              <div style={{ fontFamily: "ClanOT-NarrowMedium" }}>
                {user.total_points}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroupLeaderboard;
