// add n days to a date
const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

// shuffle elements in an array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};

// sort JSON elements in an array by a key ascending order
const sortJSONArrayAsc = (array, key) => {
  let sortedList;
  sortedList = array?.sort((a, b) => {
    return a[key] - b[key];
  });
  return sortedList;
};

// sort JSON elements in an array by a key descending order
const sortJSONArrayDesc = (array, key) => {
  let sortedList;
  sortedList = array?.sort((a, b) => {
    return b[key] - a[key];
  });
  return sortedList;
};

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

/**
 * Validates a password string based on the following rules:
 * 1. Length must be greater than 8.
 * 2. At least one capital letter.
 * 3. At least one numeric letter.
 * 4. At least one special character.
 *
 * @param {string} password - The password to validate.
 * @returns {boolean} - `true` if the password is valid, `false` otherwise.
 */
const validatePassword = (password) => {
  const regex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~`|}{[\]:;?/.,<=>-])[A-Za-z\d!@#$%^&*()_+~`|}{[\]:;?/.,<=>-]{8,}$/;
  return regex.test(password);
};

export {
  addDays,
  shuffleArray,
  sortJSONArrayAsc,
  sortJSONArrayDesc,
  validateEmail,
  validatePassword,
};
